/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Rows from '../layout/Rows';
import { CsmText } from '../mui/CsmText';
import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import StringHelper from '../../helpers/StringHelper';
import { ColorCalculationHelper } from '../../helpers/ColorCalculationHelper';

const stackStrategy = {
    stack: 'total',
    area: true,
    stackOffset: 'none', // To stack 0 on top of others    
} as const;

export interface ICsmLineSeriesData<T> {
    seriesLabel: string,
    data: {
        key: T,
        value: number,
    }[]
}

export interface ICsmLineChartProps<T> {
    chartLabel?: string,
    xAxisValueFormatter: (key: T, c: AxisValueFormatterContext) => string,
    xAxisValueOrderrer?: (keys: T[]) => T[],
    yAxisValueFormatter?: (key: T, c: AxisValueFormatterContext) => string,
    valueFormatter?: (value: number | null) => string,
    data: ICsmLineSeriesData<T>[],
    height?: number,
    marginForLegend?: number,
    useSeriesAsPercent?: boolean,
    showZeroValuesOnTooltip?: boolean,
    disableXAxisTicks?: boolean,
    disableYAxisTicks?: boolean,
    isStack?: boolean | ((seriesLabel: string) => boolean),
    isScaleTypeBand?: boolean,
}

export default function CsmLineChart<T>(props: ICsmLineChartProps<T>) {

    const { yAxisValueFormatter, isScaleTypeBand, disableXAxisTicks, disableYAxisTicks, isStack, showZeroValuesOnTooltip, useSeriesAsPercent, chartLabel, xAxisValueFormatter, valueFormatter, xAxisValueOrderrer, data, ...rest } = props;

    const { marginForLegend, height } = rest;

    const hasData = data.where(e => e.data.length > 0).length > 0;
    const xAxisValuesUnordered = !hasData ? [] : data.selectMany(e => e.data.select(t => t.key)).distinct();
    const xAxisValues = xAxisValueOrderrer ? xAxisValueOrderrer(xAxisValuesUnordered) : xAxisValuesUnordered.orderBy(e => e);

    let dataToUse = data;

    if (useSeriesAsPercent) {
        const keyTotalArray = data.selectMany(e => e.data).groupBy(e => e.key).select(e => ({ key: e.key, sum: e.list.sum(t => t.value) }));

        const findSumOfValuesForKey = (key: T) => {
            const result = keyTotalArray.single(e => e.key === key).sum;
            return result === 0 ? 1 : result;
        }

        dataToUse = !useSeriesAsPercent ? data : data.select(series => ({
            seriesLabel: series.seriesLabel,
            data: series.data.select(e => ({ key: e.key, value: (e.value * 100) / findSumOfValuesForKey(e.key) }))
        }));
    }

    const tooltipValueFormatter = (useSeriesAsPercent ? percentFormatter : valueFormatter) ?? (e => e);

    // tooltiplerde 0'ları gizlemek için
    const finalTooltipValueFormatter = showZeroValuesOnTooltip ?
        tooltipValueFormatter :
        ((e: number | null) => e === 0 ? undefined : tooltipValueFormatter(e))

    const finalYAxisValueFormatter = yAxisValueFormatter ?? (!useSeriesAsPercent ? undefined : ((e: number, _) => `${e.toFixed()}%`))
    const stackStrategyToUse = (seriesLabel: string) => {
        if (isStack === undefined)
            return {};
        else if (typeof isStack === "boolean")
            return (isStack === true ? stackStrategy : {});

        return isStack(seriesLabel) === true ? stackStrategy : {};
    }

    return (
        <Rows>
            {StringHelper.isNotEmpty(chartLabel) && <CsmText header style={{ alignSelf: "center", marginBottom: "5px" }}>{chartLabel}</CsmText>}
            <LineChart
                grid={{ horizontal: true, vertical: true }}
                colors={data.length > 6 ? ColorCalculationHelper.ChartPalettes.colors_LessBright : undefined}
                skipAnimation
                xAxis={[
                    {
                        tickPlacement: isScaleTypeBand ? "start" : undefined,
                        scaleType: isScaleTypeBand ? "band" : undefined,
                        disableTicks: disableXAxisTicks,
                        disableLine: true,
                        tickNumber: dataToUse.length,
                        tickMaxStep: 1,
                        tickMinStep: 1,
                        data: Array.from(Array(xAxisValues.length).keys()),
                        valueFormatter: (e, c) => xAxisValueFormatter(xAxisValues[e], c),
                    },
                ]}
                yAxis={[{
                    disableTicks: disableYAxisTicks,
                    tickMinStep: useSeriesAsPercent ? undefined : 1, // percent değil sayı ise buçuklar görünmesin, min 1 olsun
                    valueFormatter: finalYAxisValueFormatter,
                    max: useSeriesAsPercent ? 100 : undefined,
                    //tickMaxStep: !useSeriesAsPercent ? undefined : 1, // perent ise 

                }]}
                series={dataToUse.map((key) => {
                    const dataWithoutMissingValues = xAxisValues.select(xAxisValue => key.data.singleOrDefault(e => e.key === xAxisValue)?.value ?? 0);

                    return {
                        label: key.seriesLabel,
                        showMark: false,
                        data: dataWithoutMissingValues, //.select(e=> e === 0 ? null : e),
                        valueFormatter: finalTooltipValueFormatter,
                        ...stackStrategyToUse(key.seriesLabel),
                    }
                })}
                slotProps={{
                    legend: {
                        direction: "column",
                        hidden: false,
                        position: { horizontal: "right", vertical: "middle" }
                    }
                }}
                height={height ?? 300}
                margin={{ top: 5, right: marginForLegend ?? 250 }}
            />
        </Rows>
    );
}

function percentFormatter(value: number | null): any {
    return `${((value ?? 0)).toFixed()}%`;
}