/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { RolModel } from "../../../models/businessModels/RolModel";
import KisiAvatarListControl from "../../../components/business/KisiAvatarListControl";
import { CsmAccordion, CsmAccordionGroup } from "../../../library/components/mui/CsmAccordion";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmCstListField from "../../../library/components/mui/CsmCstListField";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import CsmGenericTwoBoxListMultiSelectField from "../../../library/components/mui/CsmGenericTwoBoxListMultiSelectField";
import EnumHelper from "../../../library/helpers/EnumHelper";

interface IRolPopupEditorProps {
    rolModel: RolModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: RolModel | null) => void,
}

function RolPopupEditor(props: IRolPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [rolModel, setRolModel] = useState<RolModel>(JsonHelper.deepCopy(props.rolModel));

    const tumRoller = genelDurumHelper.SeciliKoroModel.tumRoller;

    const updateRolModel = (action: (model: RolModel) => void) => {
        const result = JsonHelper.deepCopy(rolModel);
        action(result);
        setRolModel(result);
    }

    const hata = tumRoller.any(e => e.id !== rolModel.id && e.isim === rolModel.isim) ? "Bu isimde başka bir rol bulunmaktadır" : "";
    const hataVar = !!hata || !rolModel.isim;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Rol.RolGuncelle(rolModel, "Rol güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Rol.RolEkle(rolModel, "Rol ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(rolModel);
    }

    const kullananHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.rolIdList.contains(props.rolModel.id));

    function modifyList(e: EnmYetki[]): EnmYetki[] {
        const result = e.slice();
        if (result.contains(EnmYetki["Anket Yönetim"]) && !result.contains(EnmYetki["Anket İzleme"]))
            result.push(EnmYetki["Anket İzleme"]);
        if (result.contains(EnmYetki["Calışma Yönetim"]) && !result.contains(EnmYetki["Calışma İzleme"]))
            result.push(EnmYetki["Calışma İzleme"]);
        if (result.contains(EnmYetki["Duyuru Yönetim"]) && !result.contains(EnmYetki["Duyuru İzleme"]))
            result.push(EnmYetki["Duyuru İzleme"]);
        if (result.contains(EnmYetki["Şarkı Yönetim"]) && !result.contains(EnmYetki["Şarkı İzleme"]))
            result.push(EnmYetki["Şarkı İzleme"]);
        if (result.contains(EnmYetki["Kayıt Onaylama"]) && !result.contains(EnmYetki["Kayıt Kuartet İzleme"]))
            result.push(EnmYetki["Kayıt Kuartet İzleme"]);
        return result;
    }

    function disabledGetter(e: EnmYetki): boolean {
        if (e === EnmYetki["Anket İzleme"] && rolModel.yetkiList.contains(EnmYetki["Anket Yönetim"]))
            return true;
        if (e === EnmYetki["Calışma İzleme"] && rolModel.yetkiList.contains(EnmYetki["Calışma Yönetim"]))
            return true;
        if (e === EnmYetki["Duyuru İzleme"] && rolModel.yetkiList.contains(EnmYetki["Duyuru Yönetim"]))
            return true;
        if (e === EnmYetki["Şarkı İzleme"] && rolModel.yetkiList.contains(EnmYetki["Şarkı Yönetim"]))
            return true;
        if (e === EnmYetki["Kayıt Kuartet İzleme"] && rolModel.yetkiList.contains(EnmYetki["Kayıt Onaylama"]))
            return true;
        return false;
    }

    return (
        <Rows height={guncellemeMi ? 550 : 250} pt={20} style={{ minWidth: 850 }}>
            <CsmTextField
                disabled={!guncellenebilir}
                label="Rol İsmi"
                error={hataVar}
                helperText={hata}
                value={rolModel.isim}
                onChange={(e) => updateRolModel(t => t.isim = e)}
                eventOnKeyPress />
            <CsmGenericTwoBoxListMultiSelectField
                value={rolModel.yetkiList.orderBy(e => EnmYetki[e])}
                label={"Yetkiler"}
                itemLabel={"Yetki"}
                list={EnumHelper.getNamesAndValues(EnmYetki).orderBy(e => e.name)}
                labelGetter={e => e.name}
                valueGetter={e => e.value}
                onChange={e => updateRolModel(t => t.yetkiList = modifyList(e))} labels={{
                    secilmeyenler: genelDurumHelper.translate("Seçilmeyenler"),
                    secilenler: genelDurumHelper.translate("Seçilenler"),
                    listeyeEklemekIcinTıklayabilirsiniz: genelDurumHelper.translate("Listeye eklemek için tıklayabilirsiniz"),
                    siralarınıDegistirmekIcinTasiyabilirsinizListedenCikartmakIcinTiklayabilirsiniz: genelDurumHelper.translate("Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz"),
                }} />           

            <Rows flex mt={10} style={{ overflowY: "auto" }}>
                {guncellemeMi &&
                    <CsmAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                        <>
                            {kullananHesaplar.length > 0 ?
                                <CsmAccordion expandedKey="hesap" title={`Grubu İçeren Hesaplar (${kullananHesaplar.length} hesap)`} expanded={expandedKey === "hesap"} onExpandedChanged={onChange} >
                                    <>
                                        <CsmText bold>Grubu İçeren Hesaplar</CsmText>
                                        <KisiAvatarListControl hesaplar={kullananHesaplar} size="normal" genelDurumHelper={genelDurumHelper} hideNames />
                                    </>
                                </CsmAccordion>
                                :
                                <CsmText note italic>Grubu içeren hesap bulunmamaktadır</CsmText>
                            }
                        </>
                    )} />
                }
            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default RolPopupEditor;