/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';
import { CSSProperties } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

interface ICsmTimePickerProps {
    label: string,
    value: Date | null,
    onChange: (value: Date) => void,
    error?: boolean,
    helperText?: string,
    style?: CSSProperties,
    disabled?: boolean,
    fullWidth?: boolean,
    errorText?: string | false | undefined,
}

function CsmTimePicker(props: ICsmTimePickerProps) {
    const { label, value, onChange, error, helperText, style, disabled, errorText, fullWidth } = props;

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <div style={style}>
                <TimePicker
                    disabled={disabled}
                    label={label}
                    value={value}
                    onChange={(e) => onChange(new Date(e as any))}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={errorToUse}
                            helperText={helperTextToUse} />}
                />
            </div>
        </LocalizationProvider>
    )
}

export default CsmTimePicker;