import Rows from '../../library/components/layout/Rows';
import Cell from '../../library/components/layout/Cell';
import Columns from '../../library/components/layout/Columns';
import DateTimeHelper from '../../library/helpers/DateTimeHelper';
import { CsmText } from '../../library/components/mui/CsmText';
import { CSSProperties } from 'react';
import appColors from '../../app/appColors';
import { Box, Tooltip, alpha } from '@mui/material';
import { useObserveElementWidthGroup } from '../../library/components/hooks/useObserveElementWidthGroup';
import { BusinessHelper } from '../../helpers/BusinessHelper';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
import DateTimeToStringHelper from '../../library/helpers/DateTimeToStringHelper';

export type IVatanEtkinlikCalendarProps = {
    renderDate: (day: Date, size: VatanCalendarSizes) => (React.ReactNode | undefined);
    isEtkinlikDay: (day: Date) => boolean;
    getDayLegendCircles?: (day: Date) => { renk: string, tooltip: string }[] | undefined,
    onSelectedDataChanged?: (day: Date) => void;
    seciliAy: Date,
    selectedDate: Date | undefined,
    isSecondCalendar?: boolean,
    style?: CSSProperties,
    bosGunSecilebilir: boolean,
    tatilleriGoster: boolean,
    geneldurumHelper: GenelDurumHelper,
}

export type VatanCalendarSizes = "none" | "small" | "medium" | "large";

function VatanEtkinlikCalendar(props: IVatanEtkinlikCalendarProps) {

    const borderBottom: CSSProperties = { borderBottom: "1px solid lightgray" };
    const borderRight: CSSProperties = { borderRight: "1px solid lightgray" };
    const startDateInit = DateTimeHelper.getMondayOfFirstWeekOfMonth(props.seciliAy);

    const { sizeName, ref } = useObserveElementWidthGroup<HTMLDivElement, VatanCalendarSizes>({ 1: "none", 500: "small", 650: "medium", 900: "large" });
    const siraliCalismalar = props.geneldurumHelper.SeciliKoroModel.tumCalismalar.orderBy(e => e.tarihBaslangic);
    const ilkCalismaTarih = siraliCalismalar.firstOrDefault()?.tarihBaslangic ?? DateTimeHelper.today();
    const sonCalismaTarih = siraliCalismalar.lastOrDefault()?.tarihBaslangic ?? DateTimeHelper.addDays(DateTimeHelper.today(), 1);


    const specifics = {
        monthWidth: 150,
        dayNamesHeight: 50,
        cellHeight: 275,
        startDate: DateTimeHelper.getDateWithoutTime(ilkCalismaTarih),
        gunCount: DateTimeHelper.substractForDays(ilkCalismaTarih, sonCalismaTarih) + 1
    };

    const { monthWidth, dayNamesHeight, cellHeight, gunCount, startDate } = specifics;

    function determineSize<T>(v1: T, v2: T, v3: T): T {
        if (sizeName === "small")
            return v1;
        else if (sizeName === "medium")
            return v2;
        else
            return v3;
    }

    const days: Date[] = []

    for (let i = 0; i < gunCount; i++)
        days.push(DateTimeHelper.addDays(startDate, i));

    function onSelectedDataChanged(day: Date): void {
        if (props.onSelectedDataChanged)
            props.onSelectedDataChanged(day);
    }

    function getTodaySpan(day: Date): React.ReactNode {
        const tatilIsim = BusinessHelper.Genel.TatilIsimGetir(props.geneldurumHelper.SeciliKoroModel.tumTatiller, day);

        if (DateTimeHelper.isSameDate(day, DateTimeHelper.today()))
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.PRIMARY_DARK }}>{props.geneldurumHelper.translate("Bugün")}</span>;
        else if (!!tatilIsim && day.getMonth() !== props.seciliAy.getMonth()) {
            return <>
                <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.LIGHTGRAY }}>{DateTimeToStringHelper.toMonthString(day, props.geneldurumHelper.translate)}</span>
                <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 8, color: appColors.LIGHTGRAY }}> - {tatilIsim}</span>
            </>;
        }
        else if (tatilIsim) {
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 8, color: appColors.LIGHTGRAY }}>{tatilIsim}</span>;
        }
        else if (day.getMonth() !== props.seciliAy.getMonth())
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.LIGHTGRAY }}>{DateTimeToStringHelper.toMonthString(day, props.geneldurumHelper.translate)}</span>;
        else
            return null;
    }

    function GenerateDays(days: Date[]): React.ReactNode {
        return days.map((day, i) => {
            const addStyle: CSSProperties = {};
            const innerStyle: CSSProperties = {};
            const dateString = day.getDate().toString();

            const tatilIsim = BusinessHelper.Genel.TatilIsimGetir(props.geneldurumHelper.SeciliKoroModel.tumTatiller, day);

            const etkinlikGunuMu = props.isEtkinlikDay(day);
            if (tatilIsim) {
                addStyle.background = alpha(appColors.SECONDARY, 0.3);
            }
            else if (etkinlikGunuMu) {
                addStyle.background = alpha(appColors.GREEN_MAIN, 0.3)
            }
            else if (day.getMonth() !== props.seciliAy.getMonth())
                addStyle.background = alpha(appColors.GRAY_LIGHT4, 0.5);
            else if (DateTimeHelper.isSameDate(day, DateTimeHelper.today())) {
                addStyle.background = alpha(appColors.PRIMARY, 0.3);
            }

            if (DateTimeHelper.isSameDate(props.selectedDate ?? null, day)) {
                innerStyle.borderWidth = "1px";
                innerStyle.borderStyle = "solid";
                innerStyle.borderColor = appColors.PRIMARY_DARK;// "1px solid blue";
            }
            else
                innerStyle.borderColor = "transparent"; // innerStyle.border = "1px solid transparent";

            const content = props.renderDate(day, sizeName);
            const circleColors = props.getDayLegendCircles ? props.getDayLegendCircles!(day) : undefined;

            const circleSpanList = !circleColors ? null : circleColors.map((circleColor, i) =>
                <Tooltip key={circleColor.renk} title={circleColor.tooltip}>
                    <span style={{
                        height: "15px",
                        width: "15px",
                        backgroundColor: circleColor.renk,
                        borderRadius: "50%",
                        display: "inline-block",
                        position: "absolute",
                        top: "3px",
                        right: `${(i * 17 + 3).toFixed()}px`,
                    }}></span>
                </Tooltip>
            )

            const innerContent = content ?
                <Cell
                    dontGrowForChild
                    key={"gundolu" + i}
                    flex
                    style={{ display: "flex", ...borderBottom, ...borderRight, ...addStyle }}
                    hoverStyle={{ background: props.onSelectedDataChanged ? appColors.VERYLIGHTYELLOW : undefined }}
                    onClick={props.onSelectedDataChanged ? (() => onSelectedDataChanged(day)) : undefined}>
                    <div style={{ minWidth: "0px", flex: 1, ...innerStyle }}>
                        <Rows>
                            <Columns style={{ overflow: "hidden", position: "relative" }}>
                                <CsmText key="initgundolu" style={{ whiteSpace: "nowrap", fontSize: determineSize("12px", "14px", "14px"), paddingLeft: "4px", background: alpha(appColors.GRAY_LIGHT4, 0.5), width: "100%" }}>{dateString} {getTodaySpan(day)}</CsmText>
                                {circleSpanList}
                            </Columns>
                            {content}
                        </Rows>
                    </div>
                </Cell >
                :
                <Cell
                    dontGrowForChild
                    key={"gunbos" + i}
                    flex
                    style={{ ...borderBottom, ...borderRight, ...addStyle }}
                    hoverStyle={props.bosGunSecilebilir ? { background: appColors.VERYLIGHTYELLOW } : undefined}
                    onClick={props.bosGunSecilebilir ? (() => onSelectedDataChanged(day)) : undefined}>
                    <Rows>
                        <Columns style={{ overflow: "hidden", position: "relative" }}>
                            <CsmText key="initgunbos" style={{ whiteSpace: "nowrap", fontSize: "14px", paddingLeft: "4px", background: alpha(appColors.GRAY_LIGHT4, 0.5), width: "100%" }}>{dateString} {getTodaySpan(day)}</CsmText>
                            {circleSpanList}
                        </Columns>
                    </Rows>
                </Cell>

            return <Cell flex key={"gunisimler" + i} style={{ ...borderBottom, ...borderRight }}>
                <Rows>
                    <CsmText note style={{ textAlign: "center", fontSize: determineSize("9px", "12px", "16px") }}>{DateTimeToStringHelper.toMonthDayStringOptionalYear(day)}</CsmText>
                    <CsmText note style={{ textAlign: "center", fontSize: determineSize("12px", "16px", "24px") }}>{DateTimeToStringHelper.toNameOfDayString(day)}</CsmText>
                </Rows>
                {innerContent}
            </Cell>
        });
    }

    return (
        <Box ref={ref} visibility={sizeName === "none" ? "hidden" : undefined}>
            <Rows style={props.style}>
                <Columns>
                    {GenerateDays(days)}
                </Columns>
            </Rows>
        </Box>
    )
}



export default VatanEtkinlikCalendar;