/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows, { Rows2 } from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { Button, Switch } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import KorocanThumbnailForHesap from "../../../components/business/KorocanThumbnailForHesap";
import appColors from "../../../app/appColors";
import Cell from "../../../library/components/layout/Cell";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import CsmFormSegmentedRadioButtonList from "../../../library/components/mui/CsmFormSegmentedRadioButtonList";
import JsonHelper from "../../../library/helpers/JsonHelper";
import EnumHelper from "../../../library/helpers/EnumHelper";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { HesapCalismaModel } from "../../../models/businessModels/HesapCalismaModel";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import { EnmKatilimDurum } from "../../../models/enums/EnmKatilimDurum";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import { HesapCalismaYoklamaBildirRequestModel } from "../../../models/requestModels/HesapCalismaYoklamaBildirRequestModel";
import { HesapCalismaYoklamaListBildirRequestModel } from "../../../models/requestModels/HesapCalismaYoklamaListBildirRequestModel";
import StringHelper from "../../../library/helpers/StringHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

interface IYoklamaCalismaPopupEditorProps {
    calisma: CalismaModel,
    kullanicilar: HesapBilgiVeKullaniciModel[],
    hesapCalismaModelList: HesapCalismaModel[],
    genelDurumHelper: GenelDurumHelper,
    closer: (response: HesapCalismaModel[] | null) => void,
}

function YoklamaCalismaPopupEditor(props: IYoklamaCalismaPopupEditorProps) {
    const { calisma, kullanicilar, hesapCalismaModelList, closer, genelDurumHelper } = props;

    const ilkKadroId = calisma.kadroIdList.first();

    const [eskiVeAraVerenleriGoster, setEskiVeAraVerenleriGoster] = useState<boolean>(DateTimeHelper.getDateWithoutTime(calisma.tarihBaslangic) < DateTimeHelper.today());

    const [yeniGelenler, setYeniGelenler] = useState<string[]>([]);
    const [yeniGecGelenler, setYeniGecGelenler] = useState<string[]>([]);
    const [yeniGelmeyenler, setYeniGelmeyenler] = useState<string[]>([]);
    const [yeniMuaflar, setYeniMuaflar] = useState<string[]>([]);

    const calismaYoklamaBildir = async (hesapId: string, katilim: EnmKatilimDurum | null) => {
        const _yeniGelenler = yeniGelenler.slice();
        const _yeniGecGelenler = yeniGecGelenler.slice();
        const _yeniGelmeyenler = yeniGelmeyenler.slice();
        const _yeniMuaflar = yeniMuaflar.slice();

        if (_yeniGelenler.contains(hesapId))
            _yeniGelenler.remove(hesapId);
        if (_yeniGecGelenler.contains(hesapId))
            _yeniGecGelenler.remove(hesapId);
        if (_yeniGelmeyenler.contains(hesapId))
            _yeniGelmeyenler.remove(hesapId);
        if (_yeniMuaflar.contains(hesapId))
            _yeniMuaflar.remove(hesapId);

        if (katilim === null)
            _yeniMuaflar.push(hesapId);
        else if (katilim === EnmKatilimDurum.Katıldı)
            _yeniGelenler.push(hesapId);
        else if (katilim === EnmKatilimDurum["Geç Katıldı"])
            _yeniGecGelenler.push(hesapId);
        else if (katilim === EnmKatilimDurum.Katılmadı)
            _yeniGelmeyenler.push(hesapId)

        setYeniGelenler(_yeniGelenler);
        setYeniGecGelenler(_yeniGecGelenler);
        setYeniGelmeyenler(_yeniGelmeyenler);
        setYeniMuaflar(_yeniMuaflar);
    }

    const calismaYoklamaDurumSecVeBildir = async (hesapId: string) => {
        const response = await AlertHelper.ShowClosableContent2<EnmKatilimDurum | -1>("Katılım Durumunu Seçin", (closer) =>

            <CsmFormSegmentedRadioButtonList
                list={EnumHelper.getNamesAndValuesWithNull<EnmKatilimDurum>(EnmKatilimDurum, "Belirsiz")}
                labelGetter={(e) => e.name}
                valueGetter={(e) => e.value ?? -1}
                value={null}
                onChange={(value) => closer(value === -1 ? -1 : (value as any))} />
        );

        if (response == null)
            return;

        calismaYoklamaBildir(hesapId, response === -1 ? null : response);
    }

    const kaydet = async () => {
        const model = new HesapCalismaYoklamaListBildirRequestModel([]);

        for (const yoklama of yeniGelenler)
            model.yoklamalar.push(new HesapCalismaYoklamaBildirRequestModel(calisma.id, yoklama, EnmKatilimDurum.Katıldı));
        for (const yoklama of yeniGecGelenler)
            model.yoklamalar.push(new HesapCalismaYoklamaBildirRequestModel(calisma.id, yoklama, EnmKatilimDurum["Geç Katıldı"]));
        for (const yoklama of yeniGelmeyenler)
            model.yoklamalar.push(new HesapCalismaYoklamaBildirRequestModel(calisma.id, yoklama, EnmKatilimDurum.Katılmadı));
        for (const yoklama of yeniMuaflar)
            model.yoklamalar.push(new HesapCalismaYoklamaBildirRequestModel(calisma.id, yoklama, null));

        const webResponse = await genelDurumHelper.requestHelper.Calisma.HesapCalismaYoklamaListBildir(model, "Yoklamalar bildiriliyor");
        if (!webResponse.successfull)
            return;

        const result = JsonHelper.deepCopy(hesapCalismaModelList);

        for (const gelen of yeniGelenler) {
            const found = result.singleOrDefault(e => e.hesapModelId === gelen && e.calismaModelId === calisma.id);
            if (found == null) {
                const modelToAdd = new HesapCalismaModel();
                modelToAdd.calismaModelId = calisma.id;
                modelToAdd.derseGercekKatilimDurum = EnmKatilimDurum.Katıldı;
                modelToAdd.hesapModelId = gelen;
                modelToAdd.katilimDurum = null;
                modelToAdd.katilmamaGerekce = "";
                result.push(modelToAdd);
            }
            else
                found.derseGercekKatilimDurum = EnmKatilimDurum.Katıldı;
        }

        for (const gecGelen of yeniGecGelenler) {
            const found = result.singleOrDefault(e => e.hesapModelId === gecGelen && e.calismaModelId === calisma.id);
            if (found == null) {
                const modelToAdd = new HesapCalismaModel();
                modelToAdd.calismaModelId = calisma.id;
                modelToAdd.derseGercekKatilimDurum = EnmKatilimDurum["Geç Katıldı"];
                modelToAdd.hesapModelId = gecGelen;
                modelToAdd.katilimDurum = null;
                modelToAdd.katilmamaGerekce = "";
                result.push(modelToAdd);
            }
            else
                found.derseGercekKatilimDurum = EnmKatilimDurum["Geç Katıldı"];
        }

        for (const gelmeyen of yeniGelmeyenler) {
            const found = result.singleOrDefault(e => e.hesapModelId === gelmeyen && e.calismaModelId === calisma.id);
            if (found == null) {
                const modelToAdd = new HesapCalismaModel();
                modelToAdd.calismaModelId = calisma.id;
                modelToAdd.derseGercekKatilimDurum = EnmKatilimDurum.Katılmadı
                modelToAdd.hesapModelId = gelmeyen;
                modelToAdd.katilimDurum = null;
                modelToAdd.katilmamaGerekce = "";
                result.push(modelToAdd);
            }
            else
                found.derseGercekKatilimDurum = EnmKatilimDurum.Katılmadı
        }


        for (const muaf of yeniMuaflar) {
            const found = result.singleOrDefault(e => e.hesapModelId === muaf && e.calismaModelId === calisma.id);
            if (found != null) {
                result.remove(found);
            }
        }

        closer(result);
    }

    const yoklamaVerilmeyenSesGrupListGetir = (hesaplar: HesapBilgiVeKullaniciModel[], alakaliBildirimler: HesapCalismaModel[], kadroIdList: string[]) => {
        const anaSesGruplari = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]).orderBy(e => e.sira);

        return anaSesGruplari.map(sesGrup => {
            const sesGrupHesaplar = hesaplar.where(e => e.sesGrupIdList.contains(sesGrup.id));
            if (sesGrupHesaplar.length === 0)
                return null;

            return <Rows key={sesGrup.id}>
                <CsmText hasLightBottomBorder>{sesGrup.isim}</CsmText>
                <Columns wrap>
                    {yoklamaVerilmeyenKullaniciListGetir(sesGrupHesaplar, alakaliBildirimler, kadroIdList)}
                </Columns>
            </Rows>
        });
    }

    const yoklamaVerilmeyenKullaniciListGetir = (hesaplar: HesapBilgiVeKullaniciModel[], alakaliBildirimler: HesapCalismaModel[], kadroIdList: string[]) => {
        return hesaplar.map((hesap) => {
            const baslik = `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`;

            const hesapCalisma = alakaliBildirimler.singleOrDefault(e => e.hesapModelId === hesap.id);
            let kullaniciBildirim = "";
            let gerekce = "";
            const kullaniciDurum = !hesap.aktif ? "Eski Korist" : hesap.araVerdi ? "Ara Veren Korist" : kadroIdList.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(hesap, e)).length === 0 ? "Kadro Dışı" : "";

            if (hesapCalisma != null && hesapCalisma.katilimDurum != null) {
                kullaniciBildirim = EnmKatilimBildirmeDurum[hesapCalisma.katilimDurum];
                gerekce = StringHelper.nullToEmpty(hesapCalisma.katilmamaGerekce);
            }

            let backColor = "transparent";

            if (hesapCalisma) {
                if (hesapCalisma.derseGercekKatilimDurum != null) {
                    if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
                        backColor = appColors.LIGHTGREEN;
                    else if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"])
                        backColor = appColors.LIGHTYELLOW;
                    else
                        backColor = appColors.LIGHTRED;
                }
                else {
                    if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Katılacağım)
                        backColor = appColors.VERYLIGHTGREEN;
                    else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum)
                        backColor = appColors.VERYLIGHTRED;
                    else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Belirsiz)
                        backColor = appColors.VERYLIGHTYELLOW;
                }
            }

            const katilimValueForRadio =
                (hesapCalisma && hesapCalisma.derseGercekKatilimDurum != null) ?
                    hesapCalisma.derseGercekKatilimDurum :
                    null;

            return (
                <Columns width={260} key={hesap.id} pl={5} mr={10} pr={15} mb={10} style={{ borderRadius: 5, backgroundColor: backColor }}>
                    <Cell width={70} mr={5} mt={5}>
                        <KorocanThumbnailForHesap hesap={hesap} genelDurumHelper={genelDurumHelper} size="normal" />
                    </Cell>
                    <Rows>
                        <CsmText style={{ alignSelf: "flex-start", fontSize: 14, color: appColors.TEXT_PRIMARY }}>{baslik}</CsmText>
                        <Cell width={200} style={{ alignSelf: "center" }}>
                            <CsmFormSegmentedRadioButtonList
                                list={EnumHelper.getNamesAndValues<EnmKatilimDurum>(EnmKatilimDurum)}
                                labelGetter={(e) => e.value === EnmKatilimDurum["Geç Katıldı"] ? "GEÇ" : e.name}
                                valueGetter={(e) => e.value}
                                value={katilimValueForRadio}
                                onChange={(value) => calismaYoklamaBildir(hesap.id, value)} />
                            <CsmText style={{ alignSelf: "flex-start" }} italic note>{kullaniciDurum}</CsmText>
                            <CsmText style={{ alignSelf: "flex-start", fontSize: 12 }} italic >{kullaniciBildirim}</CsmText>
                            <CsmText style={{ alignSelf: "flex-start" }} italic note>{gerekce}</CsmText>
                        </Cell>
                    </Rows>
                </Columns>
            );
        });
    }

    const guncelHesaplar = BusinessHelper.Calisma.CalismayaUygunKisileriGetir(calisma, kullanicilar, eskiVeAraVerenleriGoster);
    const eskiYeniTumHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList;

    const alakaliCalismaHesapModeller = hesapCalismaModelList.where(e => e.calismaModelId === calisma.id)

    const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(calisma.kadroIdList, calisma.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumKadrolar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

    // ekrandaki istatistikler
    const bildirimYapmayanlar = guncelHesaplar.where(hesap => alakaliCalismaHesapModeller.all(e => e.hesapModelId !== hesap.id) || alakaliCalismaHesapModeller.single(e => e.hesapModelId === hesap.id).katilimDurum === null).select(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const gelecegimDiyenler = guncelHesaplar.where(hesap => alakaliCalismaHesapModeller.any(e => e.hesapModelId === hesap.id) && alakaliCalismaHesapModeller.single(e => e.hesapModelId === hesap.id).katilimDurum === EnmKatilimBildirmeDurum.Katılacağım).select(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const gelemeyecegimDiyenler = guncelHesaplar.where(hesap => alakaliCalismaHesapModeller.any(e => e.hesapModelId === hesap.id) && alakaliCalismaHesapModeller.single(e => e.hesapModelId === hesap.id).katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum).select(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const belirsizDiyenler = guncelHesaplar.where(hesap => alakaliCalismaHesapModeller.any(e => e.hesapModelId === hesap.id) && alakaliCalismaHesapModeller.single(e => e.hesapModelId === hesap.id).katilimDurum === EnmKatilimBildirmeDurum.Belirsiz).select(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);

    // ekran bilgileri
    const tarih = DateTimeToStringHelper.toMonthDayAndDayOfWeekString(calisma.tarihBaslangic, genelDurumHelper.translate);
    const saat = DateTimeToStringHelper.toTimeString(calisma.tarihBaslangic) + " - " + DateTimeToStringHelper.toTimeString(calisma.tarihBitis);
    const cagirilanSayi = guncelHesaplar.length;

    // gelen ve gelmeyen listeleri
    const gercektenYoklamaVerilenBildirimler = alakaliCalismaHesapModeller.where(e => e.derseGercekKatilimDurum != null)
    const ekrensalYoklamaVerilenBildirimler = gercektenYoklamaVerilenBildirimler.where(e => yeniMuaflar.contains(e.hesapModelId) === false);

    const ekransalYoklamaVerilenlerinIdleri = ekrensalYoklamaVerilenBildirimler.select(e => e.hesapModelId).union(yeniGelenler).union(yeniGecGelenler).union(yeniGelmeyenler).except(yeniMuaflar);

    const ekransalGelenlerinIdleri = ekrensalYoklamaVerilenBildirimler
        .where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
        .where(e => yeniGelmeyenler.contains(e.hesapModelId) === false && yeniGecGelenler.contains(e.hesapModelId) === false)
        .select(e => e.hesapModelId)
        .union(yeniGelenler);

    const ekransalGecGelenlerinIdleri = ekrensalYoklamaVerilenBildirimler
        .where(e => e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"])
        .where(e => yeniGelmeyenler.contains(e.hesapModelId) === false && yeniGelenler.contains(e.hesapModelId) === false)
        .select(e => e.hesapModelId)
        .union(yeniGecGelenler);

    const ekransalGelmeyenlerinIdleri = ekrensalYoklamaVerilenBildirimler
        .where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı)
        .where(e => yeniGelenler.contains(e.hesapModelId) === false && yeniGecGelenler.contains(e.hesapModelId) === false)
        .select(e => e.hesapModelId)
        .union(yeniGelmeyenler);

    const ekransalYoklamaVerilenmeyenler = guncelHesaplar.where(e => ekransalYoklamaVerilenlerinIdleri.contains(e.id) === false).orderByTurkish(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const ekransalGelenHesaplar = eskiYeniTumHesaplar.where(e => ekransalGelenlerinIdleri.contains(e.id)).orderByTurkish(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const ekransalGecGelenHesaplar = eskiYeniTumHesaplar.where(e => ekransalGecGelenlerinIdleri.contains(e.id)).orderByTurkish(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    const ekransalGelmeyenHesaplar = eskiYeniTumHesaplar.where(e => ekransalGelmeyenlerinIdleri.contains(e.id)).orderByTurkish(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);

    const yoklamaVerilenmeyenlerContent = yoklamaVerilmeyenSesGrupListGetir(ekransalYoklamaVerilenmeyenler, alakaliCalismaHesapModeller, calisma.kadroIdList);

    const calismaIleAlakaliHesaplar = [...ekransalGelenHesaplar, ...ekransalGecGelenHesaplar, ...ekransalGelmeyenHesaplar, ...ekransalYoklamaVerilenmeyenler];
    const gelenlerContent = ekransalGelenHesaplar.length === 0 ? (<CsmSwipableListItem><CsmText note>Çalışmaya katılan korist bulunamamıştır</CsmText></CsmSwipableListItem>) :
        (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={calismaIleAlakaliHesaplar}
                gosterilecekHesaplar={ekransalGelenHesaplar}
                onClick={(hesap) => calismaYoklamaDurumSecVeBildir(hesap.id)}//, EnmKatilimDurum.Katıldı)}
                hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(ilkKadroId, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />
        );

    const gecGelenlerContent = ekransalGecGelenHesaplar.length === 0 ? (<CsmSwipableListItem><CsmText note>Çalışmaya Geç gelen korist bulunamamıştır</CsmText></CsmSwipableListItem>) :
        (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={calismaIleAlakaliHesaplar}
                gosterilecekHesaplar={ekransalGecGelenHesaplar}
                onClick={(hesap) => calismaYoklamaDurumSecVeBildir(hesap.id)}//, EnmKatilimDurum["Geç Katıldı"])}
                hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(ilkKadroId, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />
        );

    const gelmeyenlerContent = ekransalGelmeyenHesaplar.length === 0 ? (<CsmSwipableListItem><CsmText note>Çalışmaya katılamayan korist bulunamamıştır</CsmText></CsmSwipableListItem>) :
        (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={calismaIleAlakaliHesaplar}
                gosterilecekHesaplar={ekransalGelmeyenHesaplar}
                onClick={(hesap) => calismaYoklamaDurumSecVeBildir(hesap.id)}//, EnmKatilimDurum.Katılmadı)}
                hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(ilkKadroId, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />
        );

    return (
        <Rows2 style={{ minWidth: 270 }}>
            <Rows flex>
                <Rows>
                    <Columns>
                        <Cell flex>
                            <CsmText style={{ flex: 1, textAlign: "center", fontSize: 22, fontWeight: "bold" }}>{tarih} - {saat}</CsmText>
                            <CsmText style={{ flex: 1, textAlign: "center" }} note>{hedefKitle} ({cagirilanSayi} korist)</CsmText>
                            <CsmText style={{ flex: 1, textAlign: "center", fontSize: 16 }} note>{calisma.icerik}</CsmText>
                        </Cell>
                    </Columns>
                    <Columns>
                        <Switch checked={eskiVeAraVerenleriGoster} onChange={e => setEskiVeAraVerenleriGoster(e.target.checked)} />
                        <CsmText style={{ marginTop: "9px" }} note>{eskiVeAraVerenleriGoster ? "Eski ve Ara Veren Koristleri Göster" : "Eski ve Ara Veren Koristleri Gösterme"}</CsmText>
                    </Columns>
                    <Columns>
                        <Cell>
                            <CsmText>Bildirim yapmayanlar: {bildirimYapmayanlar.length} korist </CsmText>
                            {bildirimYapmayanlar.length > 0 &&
                                <CsmText note>{bildirimYapmayanlar.join(", ")}</CsmText>
                            }
                            <CsmText>Geleceğim Diyenler: {gelecegimDiyenler.length} korist </CsmText>
                            {gelecegimDiyenler.length > 0 &&
                                <CsmText note>{gelecegimDiyenler.join(", ")}</CsmText>
                            }
                            <CsmText>Gelemeyeceğim Diyenler: {gelemeyecegimDiyenler.length} korist </CsmText>
                            {gelemeyecegimDiyenler.length > 0 &&
                                <CsmText note>{gelemeyecegimDiyenler.join(", ")}</CsmText>
                            }
                            <CsmText>Belirsiz Diyenler: {belirsizDiyenler.length} korist</CsmText>
                            {belirsizDiyenler.length > 0 &&
                                <CsmText note>{belirsizDiyenler.join(", ")}</CsmText>
                            }
                        </Cell>
                    </Columns>
                </Rows>
                <div>
                    <CsmSwipableListItem itemDividerText="Yoklaması Verilmeyenler" />
                    <Rows>
                        {yoklamaVerilenmeyenlerContent}
                    </Rows>
                    <CsmSwipableListItem itemDividerText="Çalışmaya katılanlar" />
                    {gelenlerContent}
                    <CsmSwipableListItem itemDividerText="Çalışmaya geç katılanlar" />
                    {gecGelenlerContent}
                    <CsmSwipableListItem itemDividerText="Çalışmaya katılmayanlar" />
                    {gelmeyenlerContent}
                </div>
            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={yeniGelenler.length === 0 && yeniGecGelenler.length === 0 && yeniGelmeyenler.length === 0 && yeniMuaflar.length === 0} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows2>
    );
}

export default YoklamaCalismaPopupEditor;