import React, { useState } from "react";
import { IconButton, useTheme } from "@mui/material";
import Columns from "../layout/Columns";
import { CSSProperties } from "@mui/material/styles/createMixins";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import StringHelper from "../../helpers/StringHelper";
import Cell from "../layout/Cell";
import Rows, { RowsProps } from "../layout/Rows";

export interface ICsmLightCollapsableContainerProps extends Omit<RowsProps, "children"> {
    collapsedTitle?: string,
    expandedTitle?: string,
    children: React.ReactNode,
    initialOpen?: boolean,
    innerContainerStyle?: CSSProperties,
}

export default function CsmLightCollapsableContainer(props: ICsmLightCollapsableContainerProps) {
    const { collapsedTitle, expandedTitle, children, initialOpen, innerContainerStyle, ...others } = props;

    const [expanded, setExpanded] = useState<boolean>(initialOpen === true ? true : false);

    const theme = useTheme();

    return <Rows {...others}>
        <Columns height={24} ml={-5} mr={-5} mt={-5} style={{ overflow: "hidden", fontStyle: "italic", fontSize: "12px", color: theme.palette.text.secondary, fontWeight: "200" }}>
            <Columns flex style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, background: theme.palette.grey.A200, borderBottom: !expanded ? "1px solid gray" : undefined, overflow: "hidden" }} dontGrowForChild>
                <Columns ml={5} mt={4}>
                    <div style={{ whiteSpace: "nowrap" }}>{expanded ? StringHelper.nullToEmpty(expandedTitle) : StringHelper.nullToEmpty(collapsedTitle)}</div>
                </Columns>
                <Cell flex style={innerContainerStyle}></Cell>
                <IconButton onClick={() => setExpanded(!expanded)}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
            </Columns>
        </Columns>
        <div style={{ display: !expanded ? "none" : undefined, ...innerContainerStyle }}>
            {children}
        </div>
    </Rows>
}
