import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { KadroModel } from "../../../models/businessModels/KadroModel";
import KadroPopupEditor from "./KadroPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { KadroEkleGuncelleRequestModel } from "../../../models/requestModels/KadroEkleGuncelleRequestModel";
import { KullaniciKoroViewModel } from "../../../models/viewModels/KullaniciKoroViewModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { KadroVeSesGrubuBilgiModel } from "../../../models/businessModels/KadroVeSesGrubuBilgiModel";
import { HesapBilgiModel } from "../../../models/businessModels/HesapBilgiModel";
import { HesapVeSesGrubuBilgiModel } from "../../../models/businessModels/HesapVeSesGrubuBilgiModel";

function KadroYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KadroYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function KadroYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const kadroRowGetir = (kadroList: KadroModel[]): JSX.Element[] | null => {
    if (kadroList.length === 0)
      return null;

    return kadroList.map((item) => {
      const koristCount = aktifKoro.hesapBilgiList.count(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, item.id));

      let altBilgi = `${koristCount} korist`;
      
      const calismaCount = aktifKoro.tumCalismalar.count(e => e.kadroIdList.contains(item.id));
      const duyuruCount = !aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin ? 0 : aktifKoro.tumDuyurular.count(e => e.kadroIdList.contains(item.id));
      const anketCount = !aktifKoro.koroAyarlari.anketArabirimiKullanilsin ? 0 : aktifKoro.tumAnketler.count(e => e.kadroIdList.contains(item.id));

      if (calismaCount > 0)
        altBilgi = `${altBilgi}, ${calismaCount} çalışma`;

      if (duyuruCount > 0)
        altBilgi = `${altBilgi}, ${duyuruCount} duyuru`;

      if (anketCount > 0)
        altBilgi = `${altBilgi}, ${anketCount} anket`;

      const silinebilir = true;
      const guncellenebilir = true;
      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => kadroSil(item, silinebilir) });
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => kadroGuncelle(item, guncellenebilir) });
      rightButtons.push({ icon: appIcons.CrudIcons.kopyala, color: "primary", onClick: () => kadroKopyala(item) });

      return (
        <CsmSwipableListItem leftButtons={rightButtons} key={item.id} style={{ width: "400px" }}>
          <Rows>
            <CsmText style={{ alignSelf: "flex-start" }}>{item.isim}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi}</CsmText>
          </Rows>
        </CsmSwipableListItem>
      );
    });
  }

  const kadroKopyala = async (original: KadroModel) => {
    const kadroModel = new KadroModel();
    kadroModel.id = GuidHelper.NewGuid();
    kadroModel.isim = original.isim + " Kopyası";
    kadroModel.koroModelId = aktifKoro.koroModel.id;
    kadroModel.aktif = false;
    kadroModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    kadroModel.olusturmaTarih = new Date();
    kadroModel.sezonModelId = original.sezonModelId;

    const response = await AlertHelper.ShowClosableContent<KadroEkleGuncelleRequestModel>("Kadro Ekle", (closer) => (
      <KadroPopupEditor
        kadroModel={kadroModel}
        isimGuncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        initialEklenecekHesapList={
          getInitialEklenecekHesapListFromProps(original.id, aktifKoro.hesapBilgiList.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, original.id)))
        }
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const kadrolar = seciliKoro.tumKadrolar;
        kadrolar.push(response!.model);

        genelDurumdakiKadroBaglariniGuncelle(response, seciliKoro);
      });
  }

  const kadroEkle = async () => {
    const kadroModel = new KadroModel();
    kadroModel.id = GuidHelper.NewGuid();
    kadroModel.isim = "";
    kadroModel.koroModelId = aktifKoro.koroModel.id;
    kadroModel.aktif = false;
    kadroModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    kadroModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<KadroEkleGuncelleRequestModel>("Kadro Ekle", (closer) => (
      <KadroPopupEditor
        kadroModel={kadroModel}
        isimGuncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const kadrolar = seciliKoro.tumKadrolar;
        kadrolar.push(response!.model);

        genelDurumdakiKadroBaglariniGuncelle(response, seciliKoro);
      });
  }

  const kadroSil = async (kadroModel: KadroModel, silinebilir: boolean): Promise<void> => {
    if (!silinebilir) {
      AlertHelper.ShowMessage("Dikkat", "Ön tanımlı kadrolar silinemez");
      return;
    }

    if (await AlertHelper.Confirm("Dikkat", `${kadroModel.isim} isimli kadroyu silme istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Kadro.KadroSil(kadroModel.id, "Kadro siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const kadrolar = seciliKoro.tumKadrolar;
        kadrolar.remove(kadrolar.single(e => e.id === kadroModel.id));
      });
  }

  const kadroGuncelle = async (grupModel: KadroModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<KadroEkleGuncelleRequestModel>("Kadro Güncelle", (closer) => (
      <KadroPopupEditor
        isimGuncellenebilir={guncellenebilir}
        kadroModel={grupModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const kadrolar = seciliKoro.tumKadrolar;
        kadrolar.remove(kadrolar.single(e => e.id === response!.model.id));
        kadrolar.push(response!.model);

        genelDurumdakiKadroBaglariniGuncelle(response, seciliKoro);
      });
  }

  const tumKadrolar = aktifKoro.tumKadrolar.orderByTurkish(e => e.isim);

  const aktifKadrolar = tumKadrolar.where(e => e.aktif).orderByTurkish(BusinessHelper.Kadro.SortFunc);
  const pasifKadrolar = tumKadrolar.where(e => !e.aktif).orderByTurkish(BusinessHelper.Kadro.SortFunc);
  const pasifKadroSezonIdler = pasifKadrolar.select(e => e.sezonModelId);
  const pasifKadroSezonlar = aktifKoro.tumSezonlar.where(e => pasifKadroSezonIdler.contains(e.id)).orderByDesc(e => e.isim);

  return (
    <MainLayout
      location={mainScreenLocations.kadroYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "500px",
      }}>
        <ListItem>
          <Button onClick={() => kadroEkle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Yeni Kadro Ekle</Typography>
          </Button>
        </ListItem>
        <CsmSwipableListItem itemDividerText="Aktif Kadrolar" />
        {kadroRowGetir(aktifKadrolar) ?? <ListItem><CsmText note>Aktif kadro bulunmamaktadır</CsmText></ListItem>}
        {pasifKadroSezonlar.map(sezon =>
          <Rows key={sezon.id}>
            <CsmSwipableListItem itemDividerText={`${sezon.isim} Kadroları`} />
            {kadroRowGetir(pasifKadrolar.where(e => e.sezonModelId === sezon.id))}
          </Rows>
        )}
      </List>
    </MainLayout>
  );
}

export default KadroYonetimScreen;

function getInitialEklenecekHesapListFromProps(kadroId: string, orijinalHesapList: HesapBilgiModel[]): HesapVeSesGrubuBilgiModel[] {
  return orijinalHesapList.map(hesap => {

    const orijinalHesapKadro = hesap.kadroVeSesGrubuBilgiModelList.single(e => e.kadroModelId === kadroId);
    const result = new HesapVeSesGrubuBilgiModel();

    result.id = GuidHelper.NewGuid(),
      result.altSesGrupModelId = orijinalHesapKadro.altSesGrupModelId;
    result.hesapModelId = hesap.id;
    result.olusturan = orijinalHesapKadro.olusturan;
    result.olusturmaTarih = orijinalHesapKadro.olusturmaTarih;
    result.guncelleyen = orijinalHesapKadro.guncelleyen;
    result.guncellemeTarih = orijinalHesapKadro.guncellemeTarih;

    return result;
  })
}

function genelDurumdakiKadroBaglariniGuncelle(response: KadroEkleGuncelleRequestModel | null, seciliKoro: KullaniciKoroViewModel) {
  const tumHesaplar = seciliKoro.hesapBilgiList;
  const tumCalismalar = seciliKoro.tumCalismalar;
  const kadroId = response!.model.id;

  if (tumHesaplar) {
    for (const hesap of tumHesaplar) {
      const eskiKadroBilgi = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === kadroId);
      const yeniKadroBilgi = response!.hesapVeSesGrubuBilgiModelList.singleOrDefault(e => e.hesapModelId === hesap.id);

      // eskiden kadrodaydı
      if (eskiKadroBilgi !== null) {
        // artık kadroda değil
        if (yeniKadroBilgi === null)
          hesap.kadroVeSesGrubuBilgiModelList.remove(eskiKadroBilgi);
        // hala kadroda (update olabilir)
        else {
          // update ses grup, vs..
          eskiKadroBilgi.altSesGrupModelId = yeniKadroBilgi.altSesGrupModelId;
        }
      }
      // eskiden kadroda değildi
      else {
        // artık kadroda
        if (yeniKadroBilgi !== null) {
          const newItem = new KadroVeSesGrubuBilgiModel();
          newItem.id = yeniKadroBilgi.id;
          newItem.olusturan = yeniKadroBilgi.olusturan;
          newItem.olusturmaTarih = yeniKadroBilgi.olusturmaTarih;
          newItem.guncelleyen = yeniKadroBilgi.guncelleyen;
          newItem.guncellemeTarih = yeniKadroBilgi.guncellemeTarih;
          newItem.altSesGrupModelId = yeniKadroBilgi.altSesGrupModelId;
          newItem.kadroModelId = kadroId;

          hesap.kadroVeSesGrubuBilgiModelList.push(newItem);
        }
      }
    }
  }

  if (tumCalismalar) {
    for (const calisma of tumCalismalar) {
      if (calisma.kadroIdList.contains(kadroId) && response!.calismaIdList.contains(calisma.id) === false)
        calisma.kadroIdList.remove(kadroId);
      if (calisma.kadroIdList.contains(kadroId) === false && response!.calismaIdList.contains(calisma.id) === true)
        calisma.kadroIdList.push(kadroId);
    }
  }
}
