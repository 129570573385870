/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import Rows from "../../library/components/layout/Rows";
import Columns from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { KadroModel } from "../../models/businessModels/KadroModel";
import { KadroVeSesGrubuBilgiModel } from "../../models/businessModels/KadroVeSesGrubuBilgiModel";
import { GuidHelper } from "../../library/helpers/GuidHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import DateTimeHelper from "../../library/helpers/DateTimeHelper";
import { EnmSesGrupTip } from "../../models/enums/EnmSesGrupTip";

export interface IVatanKadroWithSesGrupMultiSelectFieldProps {
    id?: string,
    value: KadroVeSesGrubuBilgiModel[] | null,
    label: string,
    hesapSesGrupIdList: string[],
    onChange: (e: KadroVeSesGrubuBilgiModel[]) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    genelDurumHelper: GenelDurumHelper,
}

export default function VatanKadroWithSesGrupMultiSelectField(props: IVatanKadroWithSesGrupMultiSelectFieldProps) {
    const { id, label, genelDurumHelper, hesapSesGrupIdList, onChange, error, fontSize, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;

    const { tumSesGruplari, tumKadrolar, tumSezonlar } = genelDurumHelper.SeciliKoroModel;

    const hesapSesGruplari = tumSesGruplari.where(e => hesapSesGrupIdList.contains(e.id));
    const orijinalAltSesGrupId = hesapSesGruplari.firstOrDefault(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"])?.id ?? null;

    const value = props.value ?? [];
    const sezonList = tumSezonlar.orderBy(e => e.isim);

    const sortSezonIdListWithSezon = (kadroId: string) => {
        return kadroId;
    }

    const chipClicked = (isAdded: boolean, kadroId: string) => {
        if (!orijinalAltSesGrupId)
            return;

        const newList = value.slice();
        if (isAdded) {
            const result = new KadroVeSesGrubuBilgiModel();
            result.id = GuidHelper.NewGuid();
            result.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
            result.olusturmaTarih = DateTimeHelper.now();
            result.kadroModelId = kadroId;
            result.altSesGrupModelId = orijinalAltSesGrupId;

            newList.push(result)
        }
        else {
            newList.remove(newList.single(e => e.kadroModelId === kadroId));
        }

        onChange(newList.orderBy(e => sortSezonIdListWithSezon(e.kadroModelId)));
    }

    const getSelectableChipMenuItem = (kadro: KadroModel, isSelected: boolean): React.ReactNode => {
        const ekSesGrup = isSelected ? FindSesGrupString(kadro.id) : null;
        const label = ekSesGrup ? `${kadro?.isim} (${ekSesGrup})` : kadro?.isim;
        return <Chip
            key={kadro.id}
            label={label}
            onClick={() => { chipClicked(!isSelected, kadro.id) }}
            color={isSelected ? "primary" : undefined}
            style={{ margin: "5px", cursor: "pointer", display: "flex" }}
        />;
    }

    function FindSesGrupString(kadroId: string) {
        const kisininKadrodakiAltSesGrubuId = value.single(e => e.kadroModelId === kadroId).altSesGrupModelId!;

        if (kisininKadrodakiAltSesGrubuId === orijinalAltSesGrupId)
            return null;
        else
            return tumSesGruplari.single(e => e.id === kisininKadrodakiAltSesGrubuId).isim;

        // if (kisininKadrodakiAnaSesGrubuId === orijinalAnaSesGrupId)
        //     return tumSesGruplari.single(e => e.id === kisininKadrodakiAltSesGrubuId).isim;
        // else
        //     return `${tumSesGruplari.single(e => e.id === kisininKadrodakiAnaSesGrubuId).isim}, ${tumSesGruplari.single(e => e.id === kisininKadrodakiAltSesGrubuId).isim}`
    }

    function getSelectedChipMenuItem(_value: string): any {
        const kadro = tumKadrolar.singleOrDefault(e => e.id === _value);
        const ekSesGrup = FindSesGrupString(_value);
        const label = ekSesGrup ? `${kadro?.isim} (${ekSesGrup})` : kadro?.isim;
        return <Chip
            key={_value}
            label={label} />
    }

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                multiple
                disabled={disabled}
                sx={{ overflow: "hidden", ...sx }}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                labelId={id}
                id={id}
                value={value.select(e => e.kadroModelId)}
                label={label}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((_value) => getSelectedChipMenuItem(_value)

                        )}
                    </Box>
                )}
            // onChange={(e) => onChange(e.target.value)}
            >
                <Rows flex>
                    {sezonList.map(sezon => (
                        <Rows key={sezon.id.toString()} mb={10}>
                            <CsmText style={{ marginLeft: "10px" }} note>{sezon.isim}</CsmText>
                            <MenuItem style={{ padding: "0px" }} divider></MenuItem>
                            <Columns wrap>
                                {tumKadrolar.where(e => e.sezonModelId === sezon.id).map(e => getSelectableChipMenuItem(e, value.any(t => t.kadroModelId === e.id)))}
                            </Columns>
                        </Rows>
                    ))}
                </Rows>
            </Select>
        </FormControl>
    );
}
