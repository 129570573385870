/* eslint-disable @typescript-eslint/no-empty-function */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { mainScreenLocations } from "../../../app/mainScreens";
import Cell from "../../../library/components/layout/Cell";
import { HataAraRequestModel } from "../../../models/requestModels/HataAraRequestModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmGenericListSelectField from "../../../library/components/mui/CsmGenericListSelectField";
import { HataModel } from "../../../models/businessModels/HataModel";
import AlertHelper from "../../../library/helpers/AlertHelper";
import Rows from "../../../library/components/layout/Rows";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

const sureValues = [{ text: "", value: 0 }, { text: "5 dakika", value: 5, }, { text: "20 dakika", value: 20, }, { text: "Son 1 gün", value: 60 * 24, }, { text: "Son 1 hafta", value: 60 * 24 * 7, },];

function HataYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <HataYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function HataYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [model, setModel] = useState<HataAraRequestModel>(new HataAraRequestModel(null, "", "", ""));
  const [hatalar, setHatalar] = useState<HataModel[]>([]);

  const updateModel = (action: (model: HataAraRequestModel) => void) => {
    const result = JsonHelper.deepCopy(model);
    action(result);
    setModel(result);
  }

  function detayGoster(hata: HataModel): void {
    const message = `İçerik: ${hata.hataIcerik}\n\nRequest: ${hata.request}Source: ${hata.hataSource}\n\nStack: ${hata.hataStackTrace}\n\nFull Hata: ${hata.hataFullHata}`;

    AlertHelper.ShowMessage(hata.id, message);
  }

  const hataTableGetir = (hataList: HataModel[]): JSX.Element => {
    return (
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Tarih</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Kullanıcı</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Guid</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">İstek</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">İçerik</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Detay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hataList.map((hata) => (
              <TableRow
                key={hata.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                }}>
                <TableCell component="th" scope="row">{DateTimeToStringHelper.toDatetimeWithSecondsString(hata.olusturmaTarih)}</TableCell>
                <TableCell align="right">{hata.kullaniciAdi}</TableCell>
                <TableCell align="right">{hata.id}</TableCell>
                <TableCell align="right">{hata.request}</TableCell>
                <TableCell align="right">{hata.hataIcerik}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => detayGoster(hata)}>
                    {appIcons.Genel.search}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const search = async () => {
    const webResponse = await genelDurumHelper.requestHelper.Genel.HataGetir(model, "Hata aranıyor");
    if (!webResponse.successfull)
      return;

    setHatalar(webResponse.data?.orderByDesc(e => e.olusturmaTarih) ?? []);
  }

  return (
    <MainLayout
      location={mainScreenLocations.hataYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows>
        <CsmText header style={{ marginBottom: "10px" }}>Kriterler</CsmText>
        <Columns flex mb={10}>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmGenericListSelectField
              value={model.sonDakika}
              label="Süre"
              list={sureValues}
              labelGetter={e => e.text}
              valueGetter={e => e.value}
              onChange={e => updateModel(t => t.sonDakika = e)} />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmTextField value={model.isimIceren} onChange={(e) => updateModel(t => t.isimIceren = e)} label="E-Posta Adresi" />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmTextField value={model.hataIceren} onChange={(e) => updateModel(t => t.hataIceren = e)} label="Hata Mesajı" />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmTextField value={model.guidIceren} onChange={(e) => updateModel(t => t.guidIceren = e)} label="GUID" />
          </Cell>
          <Cell ml={10} mt={10}>
            <Button variant="contained" onClick={() => search()}>
              {appIcons.Genel.search}
              <Typography>Ara</Typography>
            </Button>
          </Cell>
          <Cell ml={10} mt={10}>
            <Button variant="contained" onClick={() => { }}>
              {appIcons.CrudIcons.sil}
              <Typography>Sil</Typography>
            </Button>
          </Cell>
        </Columns>
        <CsmText style={{ marginBottom: "10px" }} header>Sonuçlar</CsmText>
        {hataTableGetir(hatalar)}
      </Rows>
    </MainLayout>
  );
}

export default HataYonetimScreen;

