/* eslint-disable @typescript-eslint/no-explicit-any */
const monthIndexBase = 1;

const DateTimeHelper = {
    oneDayMiliseconds: 1000 * 60 * 60 * 24,
    oneWeekMiliseconds: 1000 * 60 * 60 * 24 * 7,
    now: () => new Date(),
    today: () => {
        const _now = DateTimeHelper.now();
        return DateTimeHelper.createDate(_now.getFullYear(), _now.getMonth() + monthIndexBase, _now.getDate());
    },
    createDate: (year: number | string, month: number | string, date: number | string) => {
        let yearToUse: number = (typeof year == "string") ? Number.parseInt(year) : year;
        if (yearToUse < 100) yearToUse += 2000;
        const monthToUse: number = (typeof month == "string") ? Number.parseInt(month) : month;
        const dateToUse: number = (typeof date == "string") ? Number.parseInt(date) : date;

        const dateSeconds = Date.UTC(yearToUse, monthToUse - monthIndexBase, dateToUse);

        return new Date(dateSeconds + new Date().getTimezoneOffset() * 60000);
    },
    createDateFromDateAndTime: (date: Date | null | undefined, time: Date | null | undefined) => {
        if (!date || !time)
            return null;

        const rawDate = DateTimeHelper.getDateWithoutTime(date);
        return DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(time));
    },
    isValidDate: (dateString: string): boolean => {
        if (String(new Date(dateString)) === 'Invalid Date')
            return false;
        return DateTimeHelper.validateDateStr(dateString);
    },
    isLeapYear: (year: number) => {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    },
    validateDateStr: (dateStr: string) => {

        if (/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(dateStr) === false) {
            return false;
        }

        const m = parseInt(dateStr.substr(5, 2));
        const d = parseInt(dateStr.substr(8, 2));
        const y = parseInt(dateStr.substr(0, 4));

        // you can add a year check for a value greater than let's say 5000 :-D

        if (m > 12 || d > 31) {
            return false;
        } else if (m === 2 && d > 28) {
            if (d === 29) {
                if (!DateTimeHelper.isLeapYear(y)) {
                    return false;
                }
            } else {
                return false;
            }
        } else if (d > 30 && (m === 4 || m === 6 || m === 9 || m === 11)) {
            return false;
        }

        return true;
    },
    isEmpty: (date: Date | null): boolean => {
        return date == null;
    },
    isSameDate: (date1: Date | null, date2: Date | null): boolean => {
        if (date1 == null && date2 == null) return true;
        if (date1 == null || date2 == null) return false;
        if (date1.getDate() === date2.getDate())
            if (date1.getMonth() === date2.getMonth())
                if (date1.getFullYear() === date2.getFullYear())
                    return true;

        return false;
    },
    isDateInBetween: (controlDate: Date, start: Date | null, end: Date | null): boolean => {
        controlDate = DateTimeHelper.getDateWithoutTime(controlDate);
        if (start != null) start = DateTimeHelper.getDateWithoutTime(start);
        if (end != null) end = DateTimeHelper.getDateWithoutTime(end);

        if (start != null) {
            if (DateTimeHelper.isSameDate(controlDate, start) === false) {
                if (controlDate < start)
                    return false;
            }
        }

        if (end != null) {
            if (DateTimeHelper.isSameDate(controlDate, end) === false) {
                if (controlDate > end)
                    return false;
            }
        }

        return true;
    },
    isToday(date?: Date | null) {
        if (!date) return false;
        return this.isSameDate(DateTimeHelper.today(), date);
    },
    isTodayInBetween: (start: Date | null, end: Date | null): boolean => {
        return DateTimeHelper.isDateInBetween(DateTimeHelper.today(), start, end);
    },
    doesRangesIntersect: (start1: Date | null, end1: Date | null, start2: Date | null, end2: Date | null): boolean => {
        if (!start1 || !end1 || !start2 || !end2)
            return false;
        if (start1 >= end1 || start2 >= end2)
            return false;
        if (start1 > start2)
            return DateTimeHelper.doesRangesIntersect(start2, end2, start1, end1);
        else {
            const result = start2 <= end1;
            if (result === true) {
                return true;
            }
            else
                return false;
        }
    },

    // GET METHODS
    getFirstOfMonth: (date: Date): Date => {
        return DateTimeHelper.addDays(DateTimeHelper.getDateWithoutTime(date), 1 - date.getDate());
    },
    getMondayOfWeek: (date: Date): Date => {
        let dayIndex = date.getDay();
        if (dayIndex === 0)
            dayIndex = 7;
        return DateTimeHelper.addDays(date, 1 - dayIndex);
    },
    getMondayOfFirstWeekOfMonth: (date: Date): Date => {
        const firstDayOfMonth = DateTimeHelper.createDate(date.getFullYear(), date.getMonth() + monthIndexBase, 1);
        const dayofMon = firstDayOfMonth.getDay()
        return DateTimeHelper.addDays(firstDayOfMonth, 1 - (dayofMon === 0 ? 7 : dayofMon));
    },
    getDateWithoutTime: (dateTime: Date): Date => {
        return DateTimeHelper.createDate(dateTime.getFullYear(), dateTime.getMonth() + monthIndexBase, dateTime.getDate());
    },
    getTotalMinutesOfTime: (dateTime: Date): number => {
        return dateTime.getHours() * 60 + dateTime.getMinutes();
    },
    getWeekCountOfMonth: (date: Date): number => {
        let myDate = new Date(date.valueOf());
        myDate.setDate(1);
        const first = DateTimeHelper.getMondayOfWeek(myDate);

        myDate.setMonth(myDate.getMonth() + 1);
        myDate = DateTimeHelper.addDays(myDate, -1);
        const last = DateTimeHelper.getMondayOfWeek(myDate);

        const diff: number = (last as any) - (first as any);
        return (diff / DateTimeHelper.oneWeekMiliseconds) + 1;
    },
    getWeekNumberOfDate: (date: Date): number => {
        const firstMonday = DateTimeHelper.getMondayOfWeek(DateTimeHelper.createDate(date.getFullYear(), 1, 1));
        return (DateTimeHelper.substractForDays(firstMonday, date) / 7) + 1;
    },

    // ADD METHODS

    addMinutes: (date: Date, minutes: number): Date => {
        const result = new Date(date.valueOf());
        result.setMinutes(result.getMinutes() + minutes);
        return result;
    },

    addDays: (date: Date, days: number): Date => {
        const result = new Date(date.valueOf());
        result.setDate(date.getDate() + days);
        return new Date(result);
    },

    addMonth: (date: Date, months: number): Date => {
        let resMon = date.getMonth() + months;
        let resYear = date.getFullYear();

        if (resMon > 12) {
            resYear += Math.floor(resMon / 12);
            resMon = resMon % 12;
        } else if (resMon < 0) {
            resYear -= Math.floor(-resMon / 12);
            resMon = 12 - (-resMon % 12);
        }

        return DateTimeHelper.createDate(resYear, resMon + monthIndexBase, 1);
    },
    addYear: (date: Date, years: number): Date => {
        const result = new Date(date.valueOf());
        result.setFullYear(result.getFullYear() + years);
        return result;
    },
    substractForDays(start: Date, end: Date): number {
        start = this.getDateWithoutTime(start);
        end = this.getDateWithoutTime(end);

        const miliseconds = end.valueOf() - start.valueOf();

        const seconds = miliseconds / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;

        return days;
    },

    substractForMinutes(start: Date, end: Date): number {
        const miliseconds = end.valueOf() - start.valueOf();

        const seconds = miliseconds / 1000;
        const minutes = seconds / 60;

        return minutes;
    },
}

export default DateTimeHelper;