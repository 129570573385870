/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
export default class JsonHelper {
    private static dateTimeReviver = function (_key: string, value: any) {

        if (typeof value === 'string') {
            //"2019-09-14T21:00:00Z","
            //"2019-09-10T13:50:59.2591815+03:00"
            //a = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d\d\d(Z|\d\d\d\d?))?(\+\d\d:\d\d)?)$/.exec(value);
            const withZ = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*Z)$/.exec(value);
            if (withZ) {
                const result = new Date(withZ[0]);
                if (isNaN(result.getTime())) {
                    return value;
                }
                else
                    return result;
            }
            const withoutZ = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}).*$/.exec(value);
            if (withoutZ) {
                const result = new Date(withoutZ[0] + '+00:00');
                if (isNaN(result.getTime())) {
                    return value;
                }
                else
                    return result;
            }
        }
        return value;
    }

    public static parse<T>(data: string): T | null {
        if (data === "")
            return null;
        else
            return JSON.parse(data, JsonHelper.dateTimeReviver);
    }

    public static stringify<T>(data: T): string {
        if (data == null)
            return "";
        else
            return JSON.stringify(data);
    }

    public static deepCopy<T>(target: T): T {
        return JsonHelper.parse(JSON.stringify(target))!;
    }

    /**
     * Simple object check.
     * @param item
     * @returns {boolean}
     */
    private static isObject(item: any) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     */
    public static mergeDeep<A, B, C>(target: A, source1: B, source2?: C): A & B & C {
        if (source2)
            return JsonHelper.mergeDeepInternal(target, source1, source2);
        else
            return JsonHelper.mergeDeepInternal(target, source1);
    }

    private static mergeDeepInternal(target: any, ...sources: any): any {
        if (!sources.length) return target;
        const source = sources.shift();

        if (JsonHelper.isObject(target) && JsonHelper.isObject(source)) {
            for (const key in source) {
                if (JsonHelper.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    JsonHelper.mergeDeepInternal(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return target;
    }
}