import { Dialog, DialogContent, DialogTitle, } from "@mui/material";

export interface IClosableCustomContentDialog2Props {
  titleMessage: string;
  content: JSX.Element | null;
  open: boolean;
  onClose: (() => void) | null;
  extraFullHeight: boolean;
  enableCloseOnEscOrBackClick: boolean;
}

export default function ClosableCustomContentDialog2(props: IClosableCustomContentDialog2Props) {
  return props.open === true ? (
    <Dialog
      PaperProps={{ sx: props.extraFullHeight ? { height: "calc(100% - 10px) !important", maxHeight: "calc(100% - 10px) !important" } : undefined }}
      maxWidth="xl"
      open={props.open}
      onClose={(props.enableCloseOnEscOrBackClick == true && props.onClose != null) ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title2"
      aria-describedby="alert-dialog-description2"
    >
      <DialogTitle id="alert-dialog-title2">{props.titleMessage}</DialogTitle>
      <DialogContent id="alert-dialog-description2">
        {props.content}
      </DialogContent>
    </Dialog>
  ) : null;
}
