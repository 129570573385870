/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormControlLabel, Radio, RadioGroup, SxProps, Theme } from "@mui/material";
import { StringOrNumber } from "../../helpers/StringOrNumber";
import CsmGenericGroupBox from "./CsmGenericGroupBox";
import { CSSProperties } from "react";
import StyleHelper, { IExtraStyleBorderProps, IExtraStyleSpacingProps } from "../../helpers/StyleHelper";
import Cell from "../layout/Cell";
import appColors from "../../../app/appColors";


export interface ICsmGenericRadioFieldProps<T, U extends StringOrNumber> extends IExtraStyleSpacingProps, IExtraStyleBorderProps {
    value: U | null,
    valueIsNumber?: boolean,
    label: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    disabledGetter?: (e: T) => boolean,
    onChange: (e: U) => void,
    error?: boolean,
    formControlSx?: SxProps<Theme>,
    style?: CSSProperties,
    typographyStyle?: CSSProperties,
    radioButtonStyle?: CSSProperties,
    itemsDirection?: "Horizontal" | "Vertical",
    disabled?: boolean,
    hideGenericGroupBox?: boolean,
    hasLightItemSeperator?: boolean,
}

export default function CsmGenericRadioButtonGroup<T, U extends StringOrNumber>(props: ICsmGenericRadioFieldProps<T, U>) {
    const { hasLightItemSeperator, hideGenericGroupBox, valueIsNumber, disabledGetter, value, label, list, labelGetter, valueGetter, onChange, error, formControlSx, disabled, itemsDirection, style, radioButtonStyle, typographyStyle } = props;

    const bottomBorderStyle: CSSProperties = !hasLightItemSeperator ? {} : { borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: appColors.GRAY_LIGHT3 };

    const content = <FormControl size="small" disabled={disabled} fullWidth sx={formControlSx} style={{ marginTop: 0 }}>
        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            sx={{ marginBottom: 0, flexDirection: itemsDirection === "Horizontal" ? "row" : "column" }}
            onChange={e => onChange((valueIsNumber && !!e) ? (parseInt(e.target.value) as U) : (e.target.value as U))}
        >
            {list.map((item, i) =>
                <FormControlLabel key={i.toFixed()} componentsProps={{ typography: { style: typographyStyle } }} value={valueGetter(item)} control={<Radio disabled={!!disabledGetter && disabledGetter(item)} style={radioButtonStyle} />}
                    style={{ marginTop: "0px", ...bottomBorderStyle }}
                    label={labelGetter(item)} />
            )}
        </RadioGroup>
    </FormControl>

    const customDivStyle = StyleHelper.CreateStyle(props);

    return <div style={{ ...customDivStyle, ...style }}>
        {hideGenericGroupBox ? content :
            <CsmGenericGroupBox label={label} error={error} fieldsetStyle={{ paddingTop: "3px", paddingBottom: "3px" }}>
                {content}
            </CsmGenericGroupBox>}
    </div>

}
