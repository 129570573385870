import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, ListItem, useTheme } from "@mui/material";
import Columns from "../layout/Columns";
import { CsmText } from "./CsmText";
import { ICustomSwipeButton, CsmSwipableListItem } from "./CsmSwipableListItem";
import { CSSProperties } from "@mui/material/styles/createMixins";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useState } from "react";
import Rows from "../layout/Rows";

export interface ICsmAccordionSimpleProps extends Omit<AccordionProps, "children"> {
    title: string,
    subtitle?: string,
    alwaysOpen?: boolean,
    bigCenteredTitle?: boolean,
    emptyNote?: string,
    visible?: boolean,
    children: React.ReactNode | (() => React.ReactNode),
    buttons?: { text: string, action: () => void }[],
    initialOpen?: boolean,
    innerContainerStyle?: CSSProperties,
}

export default function CsmAccordionSimple(props: ICsmAccordionSimpleProps) {
    const { initialOpen, innerContainerStyle, buttons, visible, emptyNote, bigCenteredTitle, alwaysOpen, subtitle, title, ...others } = props;
    const [expanded, setExpanded] = useState<boolean>(initialOpen ?? false);

    const theme = useTheme();
    const color_BackgroundPaper = theme.palette.background.paper;

    if (visible === false)
        return null;

    const titleStyle: CSSProperties = bigCenteredTitle ? { fontSize: "20px", fontWeight: "bold", justifyContent: "center" } : {};

    const rightButtons: ICustomSwipeButton[] | undefined = buttons?.map(e => {
        return {
            icon: <ContentCopyIcon />,
            text: e.text,
            color: "secondary",
            onClick: e.action,
            disabled: false,
        };
    });

    const calculateChildren = () => {
        if (!expanded)
            return null;
        const actualChildren = typeof props.children === "function" ? props.children() : props.children;

        return actualChildren ?? <CsmText style={{ marginLeft: "15px", marginTop: "-7px" }} italic note>{props.emptyNote}</CsmText>
    }

    return alwaysOpen ?
        (
            <>
                {subtitle ?
                    <CsmSwipableListItem style={{ background: color_BackgroundPaper, ...titleStyle }} rightButtons={rightButtons}>
                        <Rows>
                            <CsmText bold>{title}</CsmText>
                            <CsmText italic small>{subtitle}</CsmText>
                        </Rows>
                    </CsmSwipableListItem>
                    :
                    <CsmSwipableListItem style={{ background: color_BackgroundPaper, ...titleStyle }} itemDividerText={title} rightButtons={rightButtons} />
                }
                <Columns wrap style={innerContainerStyle}>
                    {(typeof props.children === "function" ? props.children() : props.children) ?? (!emptyNote ? null : <CsmText style={{ marginLeft: "15px" }} italic note>{emptyNote}</CsmText>)}
                </Columns>
            </>
        ) : (
            <Accordion sx={{ background: "transparent" }} TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={(e, t) => setExpanded(t)} {...others}>
                <AccordionSummary sx={{ background: color_BackgroundPaper, minHeight: "42px" }} expandIcon={<ExpandMoreIcon />}>
                    <ListItem dense style={{ marginTop: "0px", padding: "0px", ...titleStyle }}>
                        <Rows>
                            <CsmText bold style={titleStyle}>{title}</CsmText>
                            {!subtitle ? null : <CsmText italic small>{subtitle}</CsmText>}
                        </Rows>
                    </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                    <Columns wrap style={innerContainerStyle}>
                        {calculateChildren()}
                    </Columns>
                </AccordionDetails>
            </Accordion>
        );
}
