import { CSSProperties, ReactNode } from "react";
import { Typography, useTheme } from "@mui/material";
import StyleHelper, { IExtraStyleBorderProps, IExtraStyleSpacingProps, IExtraStyleTextProps } from "../../helpers/StyleHelper";
import Columns from "../layout/Columns";
import Cell from "../layout/Cell";

interface ICsmHeaderProps extends IExtraStyleBorderProps, IExtraStyleTextProps, IExtraStyleSpacingProps {
    children?: ReactNode,
    style?: CSSProperties,
    bigCentered?: boolean,
    hasLeftRightLines?: boolean,
}

export function CsmHeader(props: ICsmHeaderProps) {
    const { hasLeftRightLines, bigCentered, style, children, ...others } = props;

    const addTextStyle: CSSProperties = { ...style };

    addTextStyle.fontSize = 16;
    addTextStyle.fontWeight = "bold";

    if (bigCentered) {
        addTextStyle.fontSize = 20;
        addTextStyle.fontWeight = "bold";
        addTextStyle.justifyContent = "center";
        addTextStyle.textAlign = "center";
    }

    StyleHelper.UpdateStyle(others, addTextStyle);


    return hasLeftRightLines ? <Columns style={{ placeItems: "center", ...style }}>
        <Cell hasGrayBottomBorder flex height={1} mr={20}></Cell>
        <Typography sx={{
            fontSize: "inherit",
            ...addTextStyle,
        }}>
            {children}
        </Typography>
        <Cell hasGrayBottomBorder flex height={1} ml={20}></Cell>
    </Columns> :
        <Typography sx={{
            fontSize: "inherit",
            ...addTextStyle,
            ...style
        }}>
            {children}
        </Typography>
}