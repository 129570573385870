/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button, ListItem, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { KoroAyarlariModel } from "../../../models/businessModels/KoroAyarlariModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import SezonPopupEditor from "./SezonPopupEditor";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import Cell from "../../../library/components/layout/Cell";
import CsmNumericField from "../../../library/components/mui/CsmNumericField";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";

function KoroAyarlariScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <KoroAyarlariScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoroAyarlariScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [koroAyarlariModel, setKoroAyarlariModel] = useState<KoroAyarlariModel | undefined>(BusinessHelper.Genel.SeciliKoroGetir(genelDurumHelper.genelDurum, genelDurumHelper.user)?.koroAyarlari);

    const updateKoroAyarlariModel = (action: (model: KoroAyarlariModel) => void) => {
        const result = JsonHelper.deepCopy(koroAyarlariModel!);
        action(result);
        setKoroAyarlariModel(result);
    }

    const koroAyarlariniKaydet = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Koro.KoroAyarlariniGuncele(koroAyarlariModel!, "Koro ayarları güncelleniyor");
        if (!webResponse.successfull)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                seciliKoro.koroAyarlari = koroAyarlariModel!;
            });
    }

    const sezonSil = async (sezonModel: SezonModel, silinebilir: boolean) => {
        if (!silinebilir) {
            AlertHelper.ShowMessage("Dikkat", "Koronun Aktif sezonu silinemez");
            return;
        }

        if (await AlertHelper.Confirm("Dikkat", `Sezonu silmek istediğinizden emin misiniz?`) === false)
            return;

        const webResponse = await genelDurumHelper.requestHelper.Sezon.SezonSil(sezonModel.id, "Sezon siliniyor");
        if (!webResponse.successfull)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const sezonlar = seciliKoro.tumSezonlar;
                sezonlar.remove(sezonlar.single(e => e.id === sezonModel.id));
            });
    }

    const sezonGuncelle = async (sezon: SezonModel, guncellenebilir: boolean) => {
        const response = await AlertHelper.ShowClosableContent<SezonModel>("Sezon Güncelle", (closer) => (
            <SezonPopupEditor
                guncellenebilir={guncellenebilir}
                sezonModel={sezon}
                guncellemeMi={true}
                genelDurumHelper={genelDurumHelper}
                closer={closer} />
        ));

        if (response == null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const sezonlar = seciliKoro.tumSezonlar;
                sezonlar.remove(sezonlar.single(e => e.id === response!.id));
                sezonlar.push(response!);
            });
    }

    const sezonEkle = async () => {
        const sezonModel = new SezonModel();
        sezonModel.id = GuidHelper.NewGuid();
        sezonModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
        sezonModel.isim = "";
        sezonModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
        sezonModel.olusturmaTarih = new Date();
        sezonModel.altSezonlar = [];

        const response = await AlertHelper.ShowClosableContent<SezonModel>("Sezon Ekle", (closer) => (
            <SezonPopupEditor
                sezonModel={sezonModel}
                guncellenebilir={true}
                guncellemeMi={false}
                genelDurumHelper={genelDurumHelper}
                closer={closer} />
        ));

        if (response == null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const sezonlar = seciliKoro.tumSezonlar;
                sezonlar.push(response!);
            });
    }

    function KoroAyarCheckBox(props: { title: string, trueText: string, falseText: string, checkedValue: boolean, action: (model: KoroAyarlariModel, value: boolean,) => void }) {
        return <Rows>
            <CsmText bold>{props.title}</CsmText>
            <Columns mb={10}>
                <Switch
                    checked={props.checkedValue}
                    onChange={(value) => updateKoroAyarlariModel(e => props.action(e, value.target.checked))} />
                <CsmText style={{ marginTop: "7px" }}>{props.checkedValue ? props.trueText : props.falseText}</CsmText>
            </Columns>
        </Rows>
    }

    function sezonRowGetir(sezon: SezonModel): any {
        const calismaCount = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.sezonModelId === sezon.id).length;
        const sarkiCount = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id)).length;
        const altBilgi = `${calismaCount} Çalışma, ${sarkiCount} Şarkı`;

        const silinebilir = koroAyarlariModel?.sezonModelId !== sezon.id;
        const guncellenebilir = true;
        const rightButtons: ICustomSwipeButton[] = [];

        rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => sezonSil(sezon, silinebilir) });
        rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => sezonGuncelle(sezon, guncellenebilir) });

        return (
            <CsmSwipableListItem leftButtons={rightButtons} key={sezon.id} style={{ width: "400px" }}>
                <Rows>
                    <CsmText style={{ alignSelf: "flex-start" }}>{sezon.isim}</CsmText>
                    <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi}</CsmText>
                </Rows>
            </CsmSwipableListItem>
        );
    }

    return (
        <MainLayout
            location={mainScreenLocations.koroayarlari}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <Rows>
                <Columns wrap>
                    <Cell ml={0}>
                        <CsmAccordionSimple
                            alwaysOpen
                            title="Gizlilik Ayarları">
                            <Rows ml={10} mr={10} mt={10} flex>
                                <KoroAyarCheckBox
                                    title="Eski şarkıları sadece şarkı kaydı olanlar mı görsün?"
                                    trueText="Evet, eski şarkıları sadece kaydı olanlar görsün"
                                    falseText="Hayır, tüm eski şarkıları herkes görsün"
                                    checkedValue={koroAyarlariModel?.eskiSarkiSadeceKayitGorunebilir ?? false}
                                    action={(e, v) => e.eskiSarkiSadeceKayitGorunebilir = v} />
                                <KoroAyarCheckBox
                                    title="Koristler sadece sorumlu oldukları çalışmaları mı görsün?"
                                    trueText="Evet, sorumlu olmadıkları çalışmaları görmesinler."
                                    falseText="Hayır, tüm çalışmaları herkes görsün."
                                    checkedValue={koroAyarlariModel?.koristlerSadeceSorumluCalismalariGorebilir ?? false}
                                    action={(e, v) => e.koristlerSadeceSorumluCalismalariGorebilir = v} />
                                <KoroAyarCheckBox
                                    title="Koristler tüm devamsızlık tablosunu görsün mü?"
                                    trueText="Evet, Koristler tüm devamsızlık tablosunu görsünler."
                                    falseText="Hayır, Koristler sadece kendi devamsızlık bilgilerini görsünler."
                                    checkedValue={koroAyarlariModel?.koristlerTumDevamTablousunuGorebilir ?? false}
                                    action={(e, v) => e.koristlerTumDevamTablousunuGorebilir = v} />
                                <KoroAyarCheckBox
                                    title="Koristler tüm kuartet tablosunu görsün mü?"
                                    trueText="Evet, Koristler tüm kuartet tablosunu görsünler."
                                    falseText="Hayır, Koristler sadece kendi kuartet bilgilerini görsünler."
                                    checkedValue={koroAyarlariModel?.koristlerTumKuartetTablousunuGorebilir ?? false}
                                    action={(e, v) => e.koristlerTumKuartetTablousunuGorebilir = v} />
                                <KoroAyarCheckBox
                                    title="Koristler tüm kayıt gönderme tablosunu görsün mü?"
                                    trueText="Evet, Koristler tüm kayıt gönderme tablosunu görsünler."
                                    falseText="Hayır, Koristler sadece kendi kayıt gönderme bilgilerini görsünler."
                                    checkedValue={koroAyarlariModel?.koristlerTumKayitTablousunuGorebilir ?? false}
                                    action={(e, v) => e.koristlerTumKayitTablousunuGorebilir = v} />
                                <KoroAyarCheckBox
                                    title="Koristler rapor ekranılarına erişebilsin mi?"
                                    trueText="Evet, Koristler rapor ekranılarına erişebilsin."
                                    falseText="Hayır, Koristler rapor ekranılarına erişemesin."
                                    checkedValue={koroAyarlariModel?.koristlerRaporEkraniniGorebilir ?? false}
                                    action={(e, v) => e.koristlerRaporEkraniniGorebilir = v} />                                    
                            </Rows>
                        </CsmAccordionSimple>
                    </Cell>
                    <Cell ml={0}>
                        <CsmAccordionSimple
                            alwaysOpen
                            title="Devamsızlık Ayarları">
                            <Rows ml={10} mr={10} mt={10} flex>
                                <CsmText bold>Devamsızlık üst sınırı (%)</CsmText>
                                <Columns mt={10} mb={10} style={{ "alignContent": "flex-start" }}>
                                    <CsmNumericField
                                        label="0-100 arası değer giriniz"
                                        style={{ width: "170px" }}
                                        size="small"
                                        onChange={e => updateKoroAyarlariModel(t => t.devamsizlikUstSinir = minMax1_0((e ?? 0) / 100))}
                                        value={(koroAyarlariModel?.devamsizlikUstSinir ?? 0) * 100}
                                    />
                                </Columns>
                                <CsmText bold>Devamsızlık Alt sınırı (%)</CsmText>
                                <Columns mt={10} mb={10} style={{ "alignItems": "center" }}>
                                    <CsmNumericField
                                        label="0-100 arası değer giriniz"
                                        style={{ width: "170px" }}
                                        size="small"
                                        onChange={e => updateKoroAyarlariModel(t => t.devamsizlikAltSinir = minMax1_0((e ?? 0) / 100))}
                                        value={(koroAyarlariModel?.devamsizlikAltSinir ?? 0) * 100}
                                    />
                                </Columns>
                                <CsmText bold>Gecikme devamsızlık etkisi (%)</CsmText>
                                <Columns mt={10} mb={10} style={{ "alignItems": "center" }}>
                                    <CsmNumericField
                                        label="0-100 arası değer giriniz"
                                        style={{ width: "170px" }}
                                        size="small"
                                        onChange={e => updateKoroAyarlariModel(t => t.gecikmeEtki = minMax1_0((e ?? 0) / 100))}
                                        value={(koroAyarlariModel?.gecikmeEtki ?? 0) * 100}
                                    />
                                </Columns>
                            </Rows>
                        </CsmAccordionSimple>
                    </Cell>
                    <Cell ml={0}>
                        <CsmAccordionSimple
                            alwaysOpen
                            title="Koro Ayarları">
                            <Rows ml={10} mr={10} mt={10} flex>
                                <CsmText bold>Yoklama Bildirimi</CsmText>
                                <Columns style={{ "alignItems": "center" }}>
                                    <CsmText>Koristler kaç gün kala çalışmaya katılım bildirimi yapmalılar?</CsmText>
                                    <CsmNumericField
                                        style={{ width: "50px", marginLeft: "10px" }}
                                        size="small"
                                        onChange={e => updateKoroAyarlariModel(t => t.calismaYoklamaBildirimOnSuresi = e ?? 0)}
                                        value={koroAyarlariModel?.calismaYoklamaBildirimOnSuresi ?? 0}
                                    />
                                </Columns>
                                <CsmText bold>Aktif Sezon</CsmText>
                                <Columns width={500} mt={10} mb={10}>
                                    <VatanSezonSelectField
                                        value={StringHelper.nullToEmpty(koroAyarlariModel?.sezonModelId)}
                                        label="Aktif Sezon"
                                        tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                                        onChange={value => updateKoroAyarlariModel(e => e.sezonModelId = value)}
                                    />
                                </Columns>
                                <Columns width={500} mt={10} mb={10}>
                                    <KoroAyarCheckBox
                                        title="Sadece aktif şarkılara mı kayıt yüklenebilsin?"
                                        trueText="Evet, sadece aktif şarkılara kayıt yüklenebilsin"
                                        falseText="Hayır, her şarkıya kayıt yüklenebilsin."
                                        checkedValue={koroAyarlariModel?.sadeceKayitDonemiYukleme ?? false}
                                        action={(e, v) => e.sadeceKayitDonemiYukleme = v} />
                                </Columns>
                                {!koroAyarlariModel?.sadeceKayitDonemiYukleme ? null :
                                    <Columns width={500} mb={10} mt={-10}>
                                        <CsmTextField
                                            label="Sadece aktif şarkılara kayıt yükleme hata mesajı"
                                            value={koroAyarlariModel?.sadeceKayitDonemiYuklemeMesaj}
                                            onChange={e => updateKoroAyarlariModel(t => t.sadeceKayitDonemiYuklemeMesaj = e)}
                                            multiline
                                            rows={3}
                                        />
                                    </Columns>
                                }
                                {!koroAyarlariModel?.secmeBasvuruKullanilsin ? null :
                                    <Rows width={500} mb={10} mt={-10}>
                                        <CsmTextField
                                            label="Seçme Davet Mesaj taslağı"
                                            value={StringHelper.nullToEmpty(koroAyarlariModel?.whatsupMesajTemplate)}
                                            onChange={e => updateKoroAyarlariModel(t => t.whatsupMesajTemplate = e)}
                                            showStats
                                            multiline
                                            rows={6}
                                        />
                                    </Rows>
                                }
                            </Rows>
                        </CsmAccordionSimple>
                    </Cell>
                    <Cell ml={0}>
                        <CsmAccordionSimple
                            alwaysOpen
                            title="Arabirim Ayarları">
                            <Columns wrap ml={10} mr={10} mt={10} style={{ maxWidth: "750px" }} flex>
                                <Cell style={{ minWidth: "350px" }}>

                                    <KoroAyarCheckBox
                                        title="Kayıt arabirimi kullanılsın mı?"
                                        trueText="Evet, Kayıt arabirimi kullanılsın."
                                        falseText="Hayır, Kayıt arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.kayitArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.kayitArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Yoklama arabirimi kullanılsın mı?"
                                        trueText="Evet, Yoklama arabirimi kullanılsın."
                                        falseText="Hayır, Yoklama arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.yoklamaArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.yoklamaArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Kuartet arabirimi kullanılsın mı?"
                                        trueText="Evet, Kuartet arabirimi kullanılsın."
                                        falseText="Hayır, Kuartet arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.kuartetArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.kuartetArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Duyuru arabirimi kullanılsın mı?"
                                        trueText="Evet, Duyuru arabirimi kullanılsın."
                                        falseText="Hayır, Duyuru arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.duyuruArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.duyuruArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Anket arabirimi kullanılsın mı?"
                                        trueText="Evet, Anket arabirimi kullanılsın."
                                        falseText="Hayır, Anket arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.anketArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.anketArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Korist yorum arabirimi kullanılsın mı?"
                                        trueText="Evet, Korist yorum arabirimi kullanılsın."
                                        falseText="Hayır, Korist yorum arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.koristYorumArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.koristYorumArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Şarkı yorum arabirimi kullanılsın mı?"
                                        trueText="Evet, Şarkı yorum arabirimi kullanılsın."
                                        falseText="Hayır, Şarkı yorum arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.sarkiYorumArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.sarkiYorumArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Şarkı zorluk arabirimi kullanılsın mı?"
                                        trueText="Evet, Şarkı zorluk arabirimi kullanılsın."
                                        falseText="Hayır, Şarkı zorluk arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.sarkiZorlukArabirimiKullanilsin ?? false}
                                        action={(e, v) => e.sarkiZorlukArabirimiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Aday Şarkı arabirimi kullanılsın mı?"
                                        trueText="Evet, Aday Şarkı arabirimi kullanılsın."
                                        falseText="Hayır, Aday Şarkı arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.adaySarkiKullanilsin ?? false}
                                        action={(e, v) => e.adaySarkiKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Alt Sezonlar kullanılsın mı?"
                                        trueText="Evet, alt sezonlar kullanılsın."
                                        falseText="Hayır, alt sezonlar kullanılmasın."
                                        checkedValue={koroAyarlariModel?.altSezonlarKullanilsin ?? false}
                                        action={(e, v) => e.altSezonlarKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Konser arabirimi kullanılsın mı?"
                                        trueText="Evet, konser arabirimi kullanılsın."
                                        falseText="Hayır, konser arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.konserlerKullanilsin ?? false}
                                        action={(e, v) => e.konserlerKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Özgeçmiş arabirimi kullanılsın mı?"
                                        trueText="Evet, özgeçmiş arabirimi kullanılsın."
                                        falseText="Hayır, özgeçmiş arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.ozgecmisKullanilsin ?? false}
                                        action={(e, v) => e.ozgecmisKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Seçme arabirimi kullanılsın mı?"
                                        trueText="Evet, seçme arabirimi kullanılsın."
                                        falseText="Hayır, seçme arabirimi kullanılmasın."
                                        checkedValue={koroAyarlariModel?.secmeBasvuruKullanilsin ?? false}
                                        action={(e, v) => e.secmeBasvuruKullanilsin = v} />
                                </Cell>
                                <Cell style={{ minWidth: "350px" }}>
                                    <KoroAyarCheckBox
                                        title="Takvim, etkinlik takvimi görünümünde mi kullanılsın?"
                                        trueText="Evet, etkinlik takvimi görünümünde kullanılsın."
                                        falseText="Hayır, normal takvim görünümünde kullanılsın."
                                        checkedValue={koroAyarlariModel?.etkinlikTakivimiKullanilsin ?? false}
                                        action={(e, v) => e.etkinlikTakivimiKullanilsin = v} />
                                </Cell>
                            </Columns>
                        </CsmAccordionSimple>
                    </Cell>
                </Columns>
                <Columns mt={10}>
                    <Button color="success" variant="contained" onClick={() => koroAyarlariniKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                        {appIcons.Popup.tamam}
                        Değişiklikleri {genelDurumHelper.translate("Kaydet")}</Button>
                </Columns>
                <Columns>
                    <Cell width={500}>
                        <CsmAccordionSimple title="Sezon Listesi" alwaysOpen>
                            <Rows ml={10} mr={10} mt={10} flex>
                                <ListItem>
                                    <Button onClick={() => sezonEkle()}>
                                        {appIcons.CrudIcons.ekle}
                                        <Typography>Yeni Sezon Ekle</Typography>
                                    </Button>
                                </ListItem>
                                {genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByTurkish(e => e.isim).map(e => sezonRowGetir(e))}
                            </Rows>
                        </CsmAccordionSimple>
                    </Cell>
                </Columns>
            </Rows>
        </MainLayout >
    );
}

function minMax1_0(value: number): number {
    if (value < 0)
        return 0;
    else if (value > 1)
        return 1;
    else return value;
}

export default KoroAyarlariScreen;