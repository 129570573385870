/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useState } from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser, seciliKoroDegistir } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { CsmText } from "../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { mainScreenLocations } from "../../app/mainScreens";
import KorocanImage from "../../components/business/KorocanImage";
import { Button } from "@mui/material";
import AlertHelper from "../../library/helpers/AlertHelper";
import SifreGuncellemePopupEditor from "./profil/SifreGuncellemePopupEditor";
import VatanKoroSelectField from "../../components/vatan/VatanKoroSelectField";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import Cell from "../../library/components/layout/Cell";
import appColors from "../../app/appColors";
import { EnmKatilimDurum } from "../../models/enums/EnmKatilimDurum";
import { useScreenWidth } from "../../library/components/hooks/useScreenWidth";
import { EnmYetki } from "../../models/enums/EnmYetki";
import { EnmKayitDurum } from "../../models/enums/EnmKayitDurum";
import KorocanThumbnailIcerikId from "../../components/business/KorocanThumbnailIcerikId";
import { appIcons } from "../../app/appIcons";
import { HesapCalismaModel } from '../../models/businessModels/HesapCalismaModel';
import { CalismaModel } from '../../models/businessModels/CalismaModel';
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';
import OzgecmisGuncellemePopupEditor from './profil/OzgecmisGuncellemePopupEditor';
import StringHelper from '../../library/helpers/StringHelper';

function ProfilScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <ProfilScreenInner genelDurumHelper={genelDurumHelper} />
}

function ProfilScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [konserleriGoster, setKonserleriGoster] = useState<boolean>(false);

    const isSmallScreen = useScreenWidth() === "Max600"

    const koro = genelDurumHelper.SeciliKoroModel;

    const seciliHesapBilgi = genelDurumHelper.SeciliHesapBilgiModel;
    const kullanici = genelDurumHelper.SeciliKullanici;
    const hesapSesGrupIsimler = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(koro.tumSesGruplari, seciliHesapBilgi.sesGrupIdList);
    const hesapKadroAktifList = BusinessHelper.Kadro.IdListtenItemListGetir(koro.tumKadrolar.where(e => e.aktif), seciliHesapBilgi.kadroVeSesGrubuBilgiModelList.select(e => e.id)).orderByDesc(BusinessHelper.Kadro.SortFunc)
    // const hesapKadroPasifList = BusinessHelper.Kadro.IdListtenItemListGetir(koro.tumKadrolar.where(e => !e.aktif), seciliHesapBilgi.kadroVeSesGrubuBilgiModelList.select(e => e.id)).groupBy(e => e.sezonModelId)
    //     .select(e => {
    //         return {
    //             sezon: koro.tumSezonlar.single(t => t.id === e.key),
    //             kadrolar: e.list.orderByDesc(BusinessHelper.Kadro.SortFunc)
    //         }
    //     });

    const hesapRolIsimler = genelDurumHelper.SeciliHesapBilgiModel.superAdmin ? "Sistem Admin" : BusinessHelper.Rol.IdListtenIsimBirlestir(koro.tumRoller, seciliHesapBilgi.rolIdList).emptyToDash();

    const hesabinHesapCalismalari = koro.hesapCalismaModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

    const hesapYoklamaStatGenel = yoklamaStatGetir(hesabinHesapCalismalari, genelDurumHelper.SeciliKoroModel.tumCalismalar, "", false);
    const hesapYoklamaStatAktifSezon = yoklamaStatGetir(hesabinHesapCalismalari, genelDurumHelper.SeciliKoroModel.tumCalismalar, genelDurumHelper.AktifSezon.id, false);
    const hesapYoklamaStatAktifAltSezonlar = genelDurumHelper.AktifSezon.altSezonlar.select(e => { return { altSezon: e, stat: yoklamaStatGetir(hesabinHesapCalismalari, genelDurumHelper.SeciliKoroModel.tumCalismalar, e.id, true) } });

    const yapilanKuartet = !koro.koroAyarlari.kuartetArabirimiKullanilsin ? 0 : koro.hesapKuartetModelList.count(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.kuartetBasarili === true);

    const sarkiKayitSayi = !koro.koroAyarlari.kayitArabirimiKullanilsin ? 0 : koro.hesapSarkiSesKayitModelList.select(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.sarkiModelId).distinct().length;

    const katilinanKonserler = !koro.koroAyarlari.konserlerKullanilsin ? [] : koro.tumKonserler.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(seciliHesapBilgi, e.kadroModelId)).groupBy(e => e.sezonModelId)
        .select(e => {
            return {
                sezon: koro.tumSezonlar.single(t => t.id === e.key),
                konserler: e.list.orderByDesc(t => t.tarih).select(konser => {
                    return {
                        konser: konser,
                        sarkiIdler: konser.konserSarkiModelList.select(e => e.sarkiModelId).distinct(),
                    }
                })
            }
        });

    async function ozgecmisGuncelle() {
        const response = await AlertHelper.ShowClosableContent<string>(genelDurumHelper.translate("Özgeçmiş Güncelle"), (closer) => (
            <OzgecmisGuncellemePopupEditor
                hesapId={genelDurumHelper.SeciliHesapBilgiModel.id}
                ozgecmis={StringHelper.nullToEmpty(seciliHesapBilgi.ozgecmis)}
                genelDurumHelper={genelDurumHelper}
                closer={closer}
            />
        ));

        if (response === null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                seciliKoro.hesapBilgiModel.ozgecmis = response!;
                const foundHesapOnList = seciliKoro.hesapBilgiList.singleOrDefault(e => e.id === seciliKoro.hesapBilgiModel.id);
                if (foundHesapOnList) foundHesapOnList.ozgecmis = response!
            });
    }

    async function sifreGuncelle() {
        const response = await AlertHelper.ShowClosableContent<boolean>(genelDurumHelper.translate("Şifre Güncelle"), (closer) => (
            <SifreGuncellemePopupEditor
                genelDurumHelper={genelDurumHelper}
                closer={closer}
            />
        ));

        if (response == null)
            return;
    }

    async function koroDegistir(koroId: string) {
        const yeniKoro = genelDurumHelper.genelDurum.korolar.single(e => e.koroModel.id === koroId);
        if (!(await AlertHelper.Confirm("Dikkat", `"${yeniKoro.koroModel.isim}" korosunun ekranlarına geçiş yapmak istediğinizden emin misiniz?`)))
            return;

        LocalStorageHelper.setSeciliKoroId(koroId);
        genelDurumHelper.dispatch(seciliKoroDegistir(koroId));

        GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(genelDurumHelper.requestHelper, genelDurumHelper.navigate, genelDurumHelper.dispatch, genelDurumHelper.user, genelDurumHelper.genelDurum);
    }

    const thumbnail = <KorocanThumbnailIcerikId size="xlarge" icerikId={seciliHesapBilgi.resimIcerikId} title={`${kullanici.ad} ${kullanici.soyad}`} genelDurumHelper={genelDurumHelper} saveToStorage="FullSize" />

    const kullaniciBilgList = [
        <CsmText key={"adsoyad"} style={{ fontSize: 24 }} bold>{kullanici.ad} {kullanici.soyad}</CsmText>,
        <CsmText key={"kullaniciad"} style={{ marginLeft: "5px" }}>{kullanici.kullaniciAdi}</CsmText>,
        <Columns key={"dugme"} pt={20}>
            <Button color="secondary" variant="contained" onClick={() => sifreGuncelle()}>{genelDurumHelper.translate("Şifre Güncelle")}</Button>
        </Columns>
    ];

    const labelStyle: CSSProperties = { width: "120px", whiteSpace: "nowrap" };
    const bilgiStyle: CSSProperties = { flex: 1, marginLeft: "5px", maxWidth: isSmallScreen ? "240px" : "450px" };

    const koroBilgiList =
        <Rows mb={10} mt={10}>
            <CsmText style={{ fontSize: 24 }} bold>{genelDurumHelper.translate("Koro Bilgileri")}</CsmText>
            <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Koro")}: </CsmText>
                <CsmText style={bilgiStyle}>{koro.koroModel.isim}</CsmText>
            </Columns>
            <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Roller")}: </CsmText>
                <CsmText style={bilgiStyle}>{hesapRolIsimler}</CsmText>
            </Columns>
            {!hesapSesGrupIsimler ? null : <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Ses Grupları")}: </CsmText>
                <CsmText style={bilgiStyle}>{hesapSesGrupIsimler}</CsmText>
            </Columns>
            }
            {hesapKadroAktifList.length === 0 ? null : <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Kadrolar")}: </CsmText>
                <CsmText style={bilgiStyle}>{hesapKadroAktifList.select(e => e.isim).join(", ")}</CsmText>
                {/* <Rows>
                    {hesapKadroAktifList.map(e => <li style={{ marginLeft: "10px", marginTop: "5px" }} key={e.id}>{e.isim}</li>)}
                </Rows> */}

            </Columns>
            }
            {katilinanKonserler.length === 0 ? null : <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Konser")}: </CsmText>
                <Columns style={bilgiStyle}><CsmText>{katilinanKonserler.length} Sezonda {katilinanKonserler.sum(e => e.konserler.length).toFixed()} Konser</CsmText>
                    <Button style={{ marginLeft: "5px" }} variant='outlined' size="small" onClick={() => setKonserleriGoster(!konserleriGoster)}>{konserleriGoster ? "Gizle" : "Göster"}</Button>
                </Columns>
            </Columns>}
            {genelDurumHelper.SeciliKoroModel.koroAyarlari.ozgecmisKullanilsin &&
                <Rows>
                    <CsmText style={{ fontSize: 24 }} bold>{genelDurumHelper.translate("Özgeçmişi")}</CsmText>
                    <Columns>
                        {StringHelper.isEmpty(seciliHesapBilgi.ozgecmis) ?
                            <CsmText note italic>Özgeçmiş yazmamışsınız :(</CsmText>
                            :
                            <CsmText style={{ whiteSpace: "pre-line" }} note>{StringHelper.nullToEmpty(seciliHesapBilgi.ozgecmis)}</CsmText>
                        }
                    </Columns>
                    <Button color="secondary" variant="contained" onClick={() => ozgecmisGuncelle()}>{genelDurumHelper.translate("Özgeçmiş Güncelle")}</Button>                </Rows>
            }
            {/* {hesapKadroPasifList.length === 0 ? null : <Columns>
                <MyText style={labelStyle} bold>{genelDurumHelper.translate("Eski Kadrolar")}: </MyText>
                <Rows style={{ marginLeft: "10px" }}>
                    {hesapKadroPasifList.map(e => <Rows key={e.sezon.id}>
                        <MyText bold>{e.sezon.isim}</MyText>
                        {e.kadrolar.map(kadro => <MyText style={{ marginLeft: "10px", marginTop: "5px" }} key={kadro.id} listItem note>{kadro.isim}</MyText>)}
                    </Rows>)}
                </Rows>
            </Columns>} */}
            {!konserleriGoster ? null : <Columns>
                <CsmText style={labelStyle} bold>{genelDurumHelper.translate("Konserler")}: </CsmText>
                <Rows ml={10}>
                    {katilinanKonserler.map(e => <Rows key={e.sezon.id}>
                        <CsmText bold>{e.sezon.isim}</CsmText>
                        {e.konserler.map(konser => <CsmText style={{ marginLeft: "10px", marginTop: "5px" }} key={konser.konser.id} listItem note>{konser.konser.isim}</CsmText>)}
                    </Rows>)}
                </Rows>
            </Columns>}
        </Rows>


    const genelIstatistikList: { name: string, value: string }[] = [];
    const aktifSezonIstatistikList: { name: string, value: string }[] = [];
    const aktifAltSezonIstatistikList: { name: string, value: string }[] = [];
    const kayitDinlemeIstatistikList: { name: string, value: string }[] = [];

    if (genelDurumHelper.SeciliKoroModel.hesapBilgiModel.sesGrupIdList.length > 0) {
        if (hesapYoklamaStatGenel) {
            if (koro.koroAyarlari.yoklamaArabirimiKullanilsin) {
                genelIstatistikList.push({ name: "Toplam gelinen çalışma sayısı", value: hesapYoklamaStatGenel.gelinenCalisma.toFixed() });
                genelIstatistikList.push({ name: "Çalışmalarda bulunma oranı", value: hesapYoklamaStatGenel.calismaKatilimOranFormatted });
            }
        }

        if (koro.koroAyarlari.kayitArabirimiKullanilsin)
            genelIstatistikList.push({ name: genelDurumHelper.translate("Kayıt yapılan şarkı sayısı"), value: sarkiKayitSayi?.toFixed() });
        if (koro.koroAyarlari.kuartetArabirimiKullanilsin)
            genelIstatistikList.push({ name: genelDurumHelper.translate("Kuartet yapılan şarkı sayısı"), value: yapilanKuartet?.toFixed() });
        if (koro.koroAyarlari.konserlerKullanilsin) {

            genelIstatistikList.push({ name: genelDurumHelper.translate("Katılınan Konser sayısı"), value: katilinanKonserler.sum(e => e.konserler.length).toFixed() });
            genelIstatistikList.push({ name: genelDurumHelper.translate("Konserlerde şarkı söyleme sayısı"), value: katilinanKonserler.sum(e => e.konserler.sum(t => t.sarkiIdler.length)).toFixed() });
            genelIstatistikList.push({ name: genelDurumHelper.translate("Konserlerde söylenen farklı şarkı sayısı"), value: katilinanKonserler.selectMany(e => e.konserler.selectMany(t => t.konser.konserSarkiModelList.select(k => k.sarkiModelId))).distinct().length.toFixed() });
        }

        if (hesapYoklamaStatAktifSezon) {
            aktifSezonIstatistikList.push({ name: genelDurumHelper.translate("Sezonda gelinen çalışma sayısı"), value: hesapYoklamaStatAktifSezon.gelinenCalisma.toFixed() });
            aktifSezonIstatistikList.push({ name: genelDurumHelper.translate("Sezonda geç gelinen çalışma sayısı"), value: hesapYoklamaStatAktifSezon.gecGelinenCalisma.toFixed() });
            aktifSezonIstatistikList.push({ name: genelDurumHelper.translate("Sezonda gelinmeyen çalışma sayısı"), value: hesapYoklamaStatAktifSezon.gelinmeyenCalisma.toFixed() });

            aktifSezonIstatistikList.push({ name: genelDurumHelper.translate("Sezonda çalışmalara katılım oranı (ağırlıklı)"), value: "%" + (hesapYoklamaStatAktifSezon.calismaKatilimOranGecKalmali * 100).toFixed().toString() });

            for (const item of hesapYoklamaStatAktifAltSezonlar) {
                aktifAltSezonIstatistikList.push({ name: `${item.altSezon.isim} Alt Sezonu\n${item.stat?.calismaKatilimOranGecKalmaliFormattedWithDetails}`, value: item.stat?.calismaKatilimOranFormatted ?? "-" });
            }
        }
    }

    if (genelDurumHelper.Yetkiler.contains(EnmYetki["Kayıt Onaylama"])) {
        const yorumlananKayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList
            .where(e => e.kayitDurum === EnmKayitDurum.Hatalı || e.kayitDurum === EnmKayitDurum.Onaylandı)
            .where(e => e.guncelleyen === genelDurumHelper.SeciliKullanici.kullaniciAdi || (!e.guncelleyen && e.olusturan === genelDurumHelper.SeciliKullanici.kullaniciAdi));

        if (koro.koroAyarlari.kayitArabirimiKullanilsin) {
            kayitDinlemeIstatistikList.push({ name: genelDurumHelper.translate("Yorumlanan Kayıt Sayısı"), value: yorumlananKayitlar.length?.toFixed() });
            kayitDinlemeIstatistikList.push({ name: genelDurumHelper.translate("Yorumlanan Şarkı Sayısı"), value: yorumlananKayitlar.select(e => e.sarkiModelId).distinct().length.toFixed() });
            kayitDinlemeIstatistikList.push({ name: genelDurumHelper.translate("Yorumlanan Korist Sayısı"), value: yorumlananKayitlar.select(e => e.hesapModelId).distinct().length?.toFixed() });
        }

        if (koro.koroAyarlari.kuartetArabirimiKullanilsin) {
            const kuartetler = genelDurumHelper.SeciliKoroModel.hesapKuartetModelList
                .where(e => e.guncelleyen === genelDurumHelper.SeciliKullanici.kullaniciAdi ||
                    (!e.guncelleyen && e.olusturan === genelDurumHelper.SeciliKullanici.kullaniciAdi));

            kayitDinlemeIstatistikList.push({ name: genelDurumHelper.translate("Dinlenilen Kuartet Sayısı"), value: kuartetler.length?.toFixed() });
        }
    }

    if (genelDurumHelper.KadrolariniGetir(genelDurumHelper.SeciliHesapBilgiModel.kadroVeSesGrubuBilgiModelList).any(e => e.sezonModelId === genelDurumHelper.AktifSezon.id)) {
        aktifSezonIstatistikList
    }

    const koroSecim = genelDurumHelper.genelDurum.korolar.length <= 1 ? <></> : (
        <Columns mt={15}>
            <VatanKoroSelectField
                onChange={(e) => koroDegistir(e as string)}
                label={genelDurumHelper.translate("Seçili Koro")}
                value={koro.koroModel.id}
                tumKorolar={genelDurumHelper.genelDurum.korolar.select(e => e.koroModel)}
                genelDurumHelper={genelDurumHelper}
            />
        </Columns>
    );

    const logout = () => {
        props.genelDurumHelper.navigate(mainScreenLocations.logout);
    }

    function renderStatGrup(statIsim: string, statList: { name: string; value: string; }[]): React.ReactNode {
        if (statList.length === 0)
            return null;

        // return <>
        //     <MyText style={{ fontSize: 24, marginTop: "20px" }}>{statIsim}</MyText>
        //     {statList.map(e => <Columns key={e.name}>
        //         <MyText style={{ fontWeight: "normal"}} >{e.name}: </MyText>
        //         <MyText style={{ marginLeft: "5px" }}>{e.value}</MyText>
        //     </Columns>)}
        // </>

        return <>
            <CsmText style={{ fontSize: 24, marginTop: "20px", marginBottom: "10px" }}>{statIsim}</CsmText>
            <Columns wrap>
                {statList.map(e => <Rows width={220} key={e.name} mr={10} mb={20} style={{ alignItems: "center" }}>
                    <Cell width={75} height={75} style={{ borderRadius: 10, background: appColors.PRIMARY_LIGHT, placeContent: "center", alignContent: "center" }}>
                        <Rows>
                            <CsmText style={{ textAlign: "center", color: appColors.WHITE, fontWeight: "800", fontSize: 20 }}>{e.value}</CsmText>
                        </Rows>
                    </Cell>

                    <Rows>
                        {e.name.split('\n').map((item, i) => (
                            <Cell key={i.toFixed()}><CsmText small={i > 0} note italic style={{ textAlign: "center" }}>{item}</CsmText></Cell>
                        ))}
                    </Rows>
                </Rows>)}
            </Columns>
        </>

    }

    return (
        <MainLayout
            location={mainScreenLocations.profil}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <Cell flex style={{ display: "flex", justifyContent: "center" }}>
                <Rows ml={10} mr={10} style={{ maxWidth: isSmallScreen ? 380 : 500 }}>
                    <KorocanImage style={{ alignSelf: "center", maxWidth: isSmallScreen ? 300 : 500, height: 200, objectFit: "scale-down" }} icerikId={koro.koroModel.resimIcerikId} title={koro.koroModel.isim} genelDurumHelper={genelDurumHelper} />
                    <Columns wrap>
                        <Columns wrap mr={20}>
                            {thumbnail}
                            <Rows ml={10} mb={10}>
                                {kullaniciBilgList}
                            </Rows>
                        </Columns>
                        {koroBilgiList}
                    </Columns>
                    {renderStatGrup(genelDurumHelper.translate("Aktif Alt Sezon İstatistikleri"), aktifAltSezonIstatistikList)}
                    {renderStatGrup(genelDurumHelper.translate("Aktif Sezon İstatistikleri"), aktifSezonIstatistikList)}
                    {renderStatGrup(genelDurumHelper.translate("Genel İstatistikler"), genelIstatistikList)}
                    {renderStatGrup(genelDurumHelper.translate("Kayıt Dinleme İstatistikleri"), kayitDinlemeIstatistikList)}
                    {koroSecim}
                    <Button style={{ marginTop: "10px" }} variant="contained" onClick={() => logout()}>
                        {appIcons.ScreenIcons.logout} {genelDurumHelper.translate("Çıkış")}
                    </Button>
                    <span style={{ marginTop: "50px", marginBottom: "40px", fontSize: "8px", fontStyle: "italic", alignSelf: "center", color: appColors.LIGHTGRAY }}>Versiyon: {(window as any).KRCNVERSION}</span>
                    <span>&nbsp;</span>
                </Rows>
            </Cell>
        </MainLayout>
    );
}

export default ProfilScreen;

function yoklamaStatGetir(hesabinHesapCalismalari: HesapCalismaModel[], tumCalismalar: CalismaModel[], sezonId: string, altSezon: boolean) {
    let list = hesabinHesapCalismalari;

    if (sezonId !== "") {
        const sezonCalismalariIdList = tumCalismalar.where(e => altSezon ? e.altSezonModelId === sezonId : e.sezonModelId === sezonId).select(e => e.id);
        list = hesabinHesapCalismalari.where(e => sezonCalismalariIdList.contains(e.calismaModelId));
    }

    const gelinenCalisma = list.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı);
    const gecGelinenCalisma = list.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]);
    const gelinmeyenCalisma = list.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı);
    const toplamIsaretlenenCalisma = gelinenCalisma + gecGelinenCalisma + gelinmeyenCalisma;

    const calismaKatilimOran = toplamIsaretlenenCalisma === 0 ? 0 : ((gelinenCalisma + gecGelinenCalisma) / toplamIsaretlenenCalisma);
    const calismaKatilimOranGecKalmali = toplamIsaretlenenCalisma === 0 ? 0 : ((gelinenCalisma + 0.77 * gecGelinenCalisma) / toplamIsaretlenenCalisma);

    const calismaKatilimOranFormatted = `%${((Number.isNaN(calismaKatilimOran) ? 0 : calismaKatilimOran) * 100).toFixed()}`
    const calismaKatilimOranGecKalmaliFormatted = `%${((Number.isNaN(calismaKatilimOranGecKalmali) ? 0 : calismaKatilimOranGecKalmali) * 100).toFixed()}`;

    const calismaKatilimOranGecKalmaliFormattedWithDetails =
        toplamIsaretlenenCalisma === 0 ? "Henüz çalışma yapılmadı" :
            gecGelinenCalisma === 0 ?
                `(${toplamIsaretlenenCalisma} çalışmada ${gelinenCalisma} katılım)` :
                `(${toplamIsaretlenenCalisma} çalışmada ${gelinenCalisma} katılım, ${gecGelinenCalisma} geç kalma)`;
    return {
        gelinenCalisma: gelinenCalisma,
        gecGelinenCalisma: gecGelinenCalisma,
        gelinmeyenCalisma: gelinmeyenCalisma,
        calismaKatilimOran: calismaKatilimOran,
        calismaKatilimOranGecKalmali: calismaKatilimOranGecKalmali,
        calismaKatilimOranFormatted: calismaKatilimOranGecKalmaliFormatted,
        calismaKatilimOranGecKalmaliFormattedWithDetails: calismaKatilimOranGecKalmaliFormattedWithDetails
    }
}
