// AutoGenerated
import { AnketSoruModel } from "../businessModels/AnketSoruModel"

export class AnketModel {
    tarihBaslangic!: Date;
    tarihBitis!: Date;
    aktif!: boolean;
    baslik!: string;
    kadroIdList!: string[];
    sesGrupIdList!: string[];
    ekHesapIdList!: string[];
    soruModelList!: AnketSoruModel[];
    sezonModelId!: string;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}