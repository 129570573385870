import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { CsmText } from "../../../library/components/mui/CsmText";
import Columns from "../../../library/components/layout/Columns";
import Cell from "../../../library/components/layout/Cell";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { SarkiZorlukModel } from "../../../models/businessModels/SarkiZorlukModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import SarkiZorlukSarkiPopupEditor from "./SarkiZorlukSarkiPopupEditor";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";

function SarkiZorlukYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoristZorlukScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoristZorlukScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  /* -- END OF STANDARD -- */

  let sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  const tumSarkiZorluklar = genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList;

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  if (searchFilter.length > 2) {
    sarkilar = sarkilar.where(e => includesSearchText(e.isim));
  }


  const zorlukOlanSarkiIdler = tumSarkiZorluklar.select(e => e.sarkiModelId).distinct();
  const zorlukBilgisiOlanSarkilar = sarkilar.where(e => zorlukOlanSarkiIdler.contains(e.id));
  const zorlukBilgisiOlmayanSarkilar = sarkilar.where(e => zorlukOlanSarkiIdler.contains(e.id) == false);

  const zorlukBilgisiOlanKoroSarkilari = zorlukBilgisiOlanSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const zorlukBilgisiOlanAdaySarkilar = zorlukBilgisiOlanSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);
  const zorlukBilgisiOlmayanKoroSarkilari = zorlukBilgisiOlmayanSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const zorlukBilgisiOlmayanAdaySarkilar = zorlukBilgisiOlmayanSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);


  const zorlukEkleGuncelle = async (sarki: SarkiModel, sarkiZorluk: SarkiZorlukModel | null) => {
    const response = await AlertHelper.ShowClosableContent<SarkiZorlukModel>(`${sarki.isim} şarkısının zorluklarını güncelle`, (closer) => (
      <SarkiZorlukSarkiPopupEditor
        guncellenebilir
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        sarkiModel={sarki}
        sarkiZorlukModel={sarkiZorluk}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newSarkiZorlukList = seciliKoro.sarkiZorlukModelList.slice();
        const oldHesapZorluk = newSarkiZorlukList.singleOrDefault(e => e.id === response?.id);
        if (oldHesapZorluk != null)
          newSarkiZorlukList.remove(oldHesapZorluk);

        newSarkiZorlukList.push(response!);

        seciliKoro.sarkiZorlukModelList = newSarkiZorlukList;
      });
  }

  const sarkiRowsGetir = (sarkiList: SarkiModel[], sarkiZorlukList: SarkiZorlukModel[]) => {
    if (sarkiList.length === 0)
      return null;

    const innerContent = sarkiList.map(sarki => {

      const sarkiZorluk = sarkiZorlukList.singleOrDefault(e => e.sarkiModelId === sarki.id);

      const leftButtons: ICustomSwipeButton[] = [
        { icon: sarkiZorluk ? appIcons.CrudIcons.guncelle : appIcons.CrudIcons.ekle, color: "primary", onClick: () => zorlukEkleGuncelle(sarki, sarkiZorluk) },
      ];

      const stats = sarkiZorluk ? BusinessHelper.SarkiZorluk.StatOlustur(sarki, sarkiZorluk) : null;

      const innerContent = sarkiZorluk === null ?
        <CsmText note style={{ marginLeft: "0px" }}>Zorluk bilgisi girilmemiştir</CsmText>
        :
        <Columns>
          <CsmText note style={{ marginLeft: "0px", marginRight: "5px" }}>Öğrenme: {stats!.ortalamaOgrenme?.toFixed(1) ?? "-"}, Söyleme: {stats!.ortalamaSoyleme?.toFixed(1) ?? "-"}</CsmText>
          {sarkiZorluk.koroyaUygunluk === true ? appIcons.Zorluk.uygun_mini : sarkiZorluk.koroyaUygunluk === false ? appIcons.Zorluk.uygunDegil_mini : appIcons.Zorluk.bilinmiyor_mini}
        </Columns>

      return <Rows key={sarki.id} mb={20} width={400}>
        <CsmSwipableListItem hasLightBottomBorder leftButtons={leftButtons}>
          <Rows key={sarki.id} ml={5}>
            <CsmText header style={{ minWidth: "0px", whiteSpace: "pre-line" }}>{sarki.isim}</CsmText>
            <Cell>{innerContent}</Cell>
          </Rows>
        </CsmSwipableListItem>
      </Rows>
    });

    return <Columns wrap>
      {innerContent}
    </Columns>
  }

  return (
    <MainLayout
      location={mainScreenLocations.sarkiZorlukYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex mt={10}>
        <Columns mb={10}>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>Filtreleme: </CsmText>
          <Cell ml={10} mt={5}>
            <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <CsmAccordionSimple
          emptyNote="Zorluk bilgisi olan şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olan Şarkılar (${zorlukBilgisiOlanKoroSarkilari.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlanKoroSarkilari, tumSarkiZorluklar)}
        </CsmAccordionSimple>
        <CsmAccordionSimple
          emptyNote="Zorluk bilgisi olan diğer şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olan Diğer Şarkılar (${zorlukBilgisiOlanAdaySarkilar.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlanAdaySarkilar, tumSarkiZorluklar)}
        </CsmAccordionSimple>
        <CsmAccordionSimple
          emptyNote="Zorluk bilgisi olmayan eski şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olmayan Eski Şarkılar (${zorlukBilgisiOlmayanKoroSarkilari.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlmayanKoroSarkilari, tumSarkiZorluklar)}
        </CsmAccordionSimple>
        <CsmAccordionSimple
          emptyNote="Zorluk bilgisi olmayan diğer şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olmayan Diğer Şarkılar (${zorlukBilgisiOlmayanAdaySarkilar.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlmayanAdaySarkilar, tumSarkiZorluklar)}
        </CsmAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default SarkiZorlukYonetimScreen;