/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { OzgemicGuncelleRequestModel } from "../../../models/requestModels/OzgemicGuncelleRequestModel";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import StringHelper from "../../../library/helpers/StringHelper";

interface IOzgecmisGuncellemePopupEditorProps {
    hesapId: string,
    ozgecmis: string,
    genelDurumHelper: GenelDurumHelper,
    closer: (yeniOzgecmis: string | null) => void,
}

function OzgecmisGuncellemePopupEditor(props: IOzgecmisGuncellemePopupEditorProps) {
    const { hesapId, genelDurumHelper, closer } = props;

    const [ozgecmis, setOzgecmis] = useState<string>(props.ozgecmis);

    const kaydet = async () => {

        const model = new OzgemicGuncelleRequestModel(hesapId, ozgecmis);
        const webResponse = await genelDurumHelper.requestHelper.Korist.OzgecmicGuncelle(model, "Özgeçmiş güncelleniyor");
        if (!webResponse.successfull)
            return;

        await AlertHelper.ShowMessage("Başarılı", "Özgeçmişiniz başarıyla güncellenmiştir");

        closer(ozgecmis);
    }

    return (
        <Rows width={800} pt={20} height={600}>
            <CsmTextField
                multiline
                eventOnKeyPress
                rows={21}
                showStats
                label={genelDurumHelper.translate("Özgeçmişi")}
                value={ozgecmis}
                onChange={e => setOzgecmis(StringHelper.nullToEmpty(e))}
                style={{ marginBottom: 20 }} />

            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={ozgecmis === props.ozgecmis} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default OzgecmisGuncellemePopupEditor;