/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { mainScreenLocations } from "../../app/mainScreens";
import { useTranslation } from 'react-i18next';
import TranslationHelper from "../../translations/TranslationHelper";
import Rows from "../../library/components/layout/Rows";
import GuncelAnketDisplayer from "./guncel/GuncelAnketDisplayer";
import GuncelCalismaDisplayer from "./guncel/GuncelCalismaDisplayer";
import GuncelDuyuruDisplayer from "./guncel/GuncelDuyuruDisplayer";
import GuncelOzgecmisDisplayer from "./guncel/GuncelOzgecmisDisplayer";

function GuncelScreen() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);

    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null || !user.seciliKoroId) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <GuncelScreenInner genelDurumHelper={genelDurumHelper} />
}

function GuncelScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    return (
        <MainLayout
            location={mainScreenLocations.guncel}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <Rows style={{ gap: "10px"}}>
                <GuncelOzgecmisDisplayer genelDurumHelper={genelDurumHelper} />
                <GuncelAnketDisplayer genelDurumHelper={genelDurumHelper} />
                <GuncelDuyuruDisplayer genelDurumHelper={genelDurumHelper} />
                <GuncelCalismaDisplayer genelDurumHelper={genelDurumHelper} />
            </Rows>
        </MainLayout>
    );
}

export default GuncelScreen;