import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button, Typography, FormControl, Switch, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { KoroVeHesapWithContentRequestModel } from "../../../models/requestModels/KoroVeHesapWithContentRequestModel";
import { KoroVeHesapModel } from "../../../models/businessModels/KoroVeHesapModel";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField"
import CsmCstField from "../../../library/components/mui/CsmCstField";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import StringHelper from "../../../library/helpers/StringHelper";

interface IKoroPopupEditorProps {
    koro: KoroVeHesapWithContentRequestModel,
    tumKorolar: KoroVeHesapModel[],
    guncellemeMi: boolean,
    closer: (model: KoroVeHesapWithContentRequestModel | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function KoroPopupEditor(props: IKoroPopupEditorProps) {
    const [koroModel, setKoroModel] = useState<KoroVeHesapWithContentRequestModel>(JsonHelper.deepCopy(props.koro));
    const [yeniKullanici, setYeniKullanici] = useState<boolean>(props.guncellemeMi ? false : true);
    const [kullaniciArandi, setKullaniciArandi] = useState<boolean>(false);

    const koroBilgiChanged = (action: (model: KoroVeHesapWithContentRequestModel) => void): void => {
        const dummy = JsonHelper.deepCopy(koroModel);
        action(dummy);
        setKoroModel(dummy);
    }

    const koroKaydet = async () => {
        if (props.guncellemeMi) {
            const webResponse = await props.genelDurumHelper.requestHelper.Koro.KoroGuncelle(koroModel, "Koro güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await props.genelDurumHelper.requestHelper.Koro.KoroEkle(koroModel, "Koro ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        props.closer(koroModel)
    }

    const handleKoroResimUpdate = (model: IcerikEklemeRequestModel | null) => {
        koroBilgiChanged(e => {
            // bir işlem öncesinde başka dosya upload edildiyse, onu upload listesinden kaldır
            const oldIcerikModel = e.icerikList.singleOrDefault(t => t.id === e.model.koroModel.resimIcerikId);
            if (oldIcerikModel)
                e.icerikList.remove(oldIcerikModel);

            // eğer yeni içerik dolu ise, onu upload listesine ekle
            if (model)
                e.icerikList.push(model);
            e.model.koroModel.resimIcerikId = model?.id ?? null;
        });
    }

    const handleHesapResimUpdate = (model: IcerikEklemeRequestModel | null) => {
        koroBilgiChanged(e => {
            // bir işlem öncesinde başka dosya upload edildiyse, onu upload listesinden kaldır
            const oldIcerikModel = e.icerikList.singleOrDefault(t => t.id === e.model.hesapModel.resimIcerikId);
            if (oldIcerikModel)
                e.icerikList.remove(oldIcerikModel);

            // eğer yeni içerik dolu ise, onu upload listesine ekle
            if (model)
                e.icerikList.push(model);
            e.model.hesapModel.resimIcerikId = model?.id ?? null;
        });
    }

    const handleKullaniciSearch = async () => {
        const webResponse = await props.genelDurumHelper.requestHelper.Koro.KullaniciAra(koroModel.model.hesapModel.kullaniciModel.kullaniciAdi, "Kullanıcı aranıyor");
        if (!webResponse.successfull)
            return;

        if (webResponse.data == null || webResponse.data.ad == null) {
            setKullaniciArandi(true);
            AlertHelper.ShowMessage("Dikkat", "Aranılan kullanıcı bulunamadı, yeni kullanıcı oluşturarak devam edebilirsiniz");
            return;
        }

        /*  if (genelDurumHelper.SeciliKoroModel.hesapBilgiList.any(e => e.kullaniciModelId === webResponse.data!.id)) {
             AlertHelper.ShowMessage("Dikkat", "Kullanıcı hesabı hali hazırda bulunmaktadır");
             return;
         } */

        koroBilgiChanged(e => {
            e.model.hesapModel.kullaniciModelId = webResponse.data!.id;
            e.model.hesapModel.kullaniciModel = webResponse.data!;
        })
        setKullaniciArandi(true);
        setYeniKullanici(false);
    }

    const handleKullaniciAdıGuncelle = (e: string) => {
        setYeniKullanici(true);
        setKullaniciArandi(false);
        koroBilgiChanged(t => t.model.hesapModel.kullaniciModel.kullaniciAdi = e);
    }

    const isimEksik = StringHelper.isEmpty(koroModel.model.koroModel.isim);
    const isimEssizlikHataVar = props.tumKorolar.count(e => e.koroModel.isim === koroModel.model.koroModel.isim && e.koroModel.id !== koroModel.model.koroModel.id) > 0;

    const kullaniciBilgileriGuncellenebilir = !props.guncellemeMi && yeniKullanici;
    const kullaniciAranabilir = !props.guncellemeMi && !!koroModel.model.hesapModel.kullaniciModel.kullaniciAdi;

    const kullaniciAdiHataVar = !koroModel.model.hesapModel.kullaniciModel.kullaniciAdi;
    const adHataVar = !koroModel.model.hesapModel.kullaniciModel.ad;
    const soyadHataVar = !koroModel.model.hesapModel.kullaniciModel.soyad;
    const dogumGunuHataVar = !koroModel.model.hesapModel.kullaniciModel.dogumTarihi;
    const cinstiyetHataVar = !koroModel.model.hesapModel.kullaniciModel.cinsiyet;

    const kaydedilebilir = !kullaniciAdiHataVar && !adHataVar
        && !soyadHataVar && !dogumGunuHataVar && !cinstiyetHataVar && (props.guncellemeMi || kullaniciArandi);


    return <Rows style={{ maxWidth: 1000 }}>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginBottom: "5px" }}>Koro Bilgileri</Typography>
        <Columns wrap mb={25}>
            <FormControl style={{ width: 395, marginRight: 5 }}>
                <CsmTextField
                    error={isimEksik}
                    helperText={isimEssizlikHataVar ? "Bu isimde başka bir koro bulunmaktadır" : undefined}
                    label="Koro İsmi"
                    value={koroModel.model.koroModel.isim}
                    onChange={(e) => koroBilgiChanged(t => t.model.koroModel.isim = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, display: "flex", flexDirection: "row" }}>
                <Switch checked={koroModel.model.koroModel.aktif} onChange={(e) => koroBilgiChanged(t => t.model.koroModel.aktif = e.target.checked)} />
                <Typography sx={{ fontSize: 12, marginTop: "8px" }}>{koroModel.model.koroModel.aktif ? "Aktif" : "Pasif"}</Typography>
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, display: "flex", flexDirection: "row" }}>
                <VatanIcerikUploadField
                    label="Koro Resmi"
                    showClearButton
                    onChange={handleKoroResimUpdate}
                    genelDurumHelper={props.genelDurumHelper}
                    icerikId={koroModel.model.koroModel.resimIcerikId}
                    icerikModel={koroModel.icerikList.singleOrDefault(e => e.id === koroModel.model.koroModel.resimIcerikId)}
                    icerikTip={EnmIcerikTip.KoroLogo}
                />
            </FormControl>
        </Columns>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginBottom: "5px" }}>Kullanıcı Bilgileri</Typography>
        <Columns pt={10} width={600}>
            <FormControl style={{ width: 395, marginRight: 5 }}>
                <CsmTextField
                    label="Kullanıcı adı"
                    error={kullaniciAdiHataVar}
                    value={koroModel.model.hesapModel.kullaniciModel.kullaniciAdi}
                    onChange={(e) => handleKullaniciAdıGuncelle(e)} />
            </FormControl>
            <IconButton disabled={!kullaniciAranabilir} onClick={() => handleKullaniciSearch()}>
                {appIcons.Genel.search}
            </IconButton>
            <FormControl style={{ width: 395, marginRight: 5 }}>
                <VatanIcerikUploadField
                    label="Profil Resmi"
                    showClearButton
                    onChange={handleHesapResimUpdate}
                    genelDurumHelper={props.genelDurumHelper}
                    icerikId={koroModel.model.hesapModel.resimIcerikId}
                    icerikModel={koroModel.icerikList.singleOrDefault(e => e.id === koroModel.model.hesapModel.resimIcerikId)}
                    icerikTip={EnmIcerikTip.Avatar}
                />
            </FormControl>
        </Columns>
        <Columns mt={10} wrap>
            <CsmTextField
                style={{ marginRight: "10px", width: "250px" }}
                disabled={!kullaniciBilgileriGuncellenebilir}
                label="Ad"
                error={adHataVar}
                value={koroModel.model.hesapModel.kullaniciModel.ad}
                onChange={(e) => koroBilgiChanged(t => t.model.hesapModel.kullaniciModel.ad = e)} />
            <CsmTextField
                style={{ marginRight: "10px", width: "250px" }}
                disabled={!kullaniciBilgileriGuncellenebilir}
                label="Soyad"
                error={soyadHataVar}
                value={koroModel.model.hesapModel.kullaniciModel.soyad}
                onChange={(e) => koroBilgiChanged(t => t.model.hesapModel.kullaniciModel.soyad = e)} />            
            <CsmCstField
                disabled={!kullaniciBilgileriGuncellenebilir}
                label="Cinsiyet"
                style={{ marginRight: "10px", width: "150px" }}
                error={cinstiyetHataVar}
                value={koroModel.model.hesapModel.kullaniciModel.cinsiyet}
                cstType={EnmCinsiyet}
                onChange={(e) => koroBilgiChanged(t => t.model.hesapModel.kullaniciModel.cinsiyet = e)} />

        </Columns>
        <CsmText italic note style={{ marginTop: "10px" }}>{!kullaniciArandi ? "Kullanıcı aranmadı!" : yeniKullanici ? "Kullanıcı arandı, yeni Kullanıcı oluşturulacak" : "Kullanıcı arandı, varolan kullanıcı kullanılacak"}</CsmText>
        <Columns mt={10}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={!kaydedilebilir} onClick={() => koroKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default KoroPopupEditor;