import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { mainScreenLocations } from "../../../app/mainScreens";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import { Button, FormControl, Switch } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KayitWithPdfPopupEditor from "./KayitWithPdfPopupEditor";
import VatanKayitRowListItem, { VatanKayitRowInnerPropsHazirla } from "../../../components/vatan/VatanKayitRowListItem";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { HesapKayitSesGrupGuncelleRequestModel } from "../../../models/requestModels/HesapKayitSesGrupGuncelleRequestModel";
import appColors from "../../../app/appColors";
import KayitSesGrubuSecmePopupEditor from "./KayitSesGrubuSecmePopupEditor";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import KorocanThumbnailForHesap from "../../../components/business/KorocanThumbnailForHesap";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { HesapYorumModel } from "../../../models/businessModels/HesapYorumModel";
import KoristYorumKoristPopupEditor from "../koristyorum/KoristYorumKoristPopupEditor";

function KayitSarkiYorumSarkiYonetimScreen() {
  const { sarkiId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  });

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KayitSarkiYorumSarkiYonetimScreenInner sarkiId={sarkiId!} genelDurumHelper={genelDurumHelper} />
}

function KayitSarkiYorumSarkiYonetimScreenInner(props: IMainScreenProps & { sarkiId: string }) {
  const { genelDurumHelper } = props;

  const [seciliHesapId, setSeciliHesapId] = useState<string | null>(null);
  const [araVerenKoristleriGoster, setAraVerenKoristleriGoster] = useState<boolean>(false);


  /* -- END OF STANDARD -- */

  const seciliHesap = seciliHesapId == null ? null : genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.id === seciliHesapId);
  const seciliHesabinKayitlari2 = seciliHesapId == null ? null : genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === seciliHesapId && e.sarkiModelId === props.sarkiId).orderBy(e => e.olusturmaTarih);
  const seciliHesabinKayitProps = !seciliHesabinKayitlari2 ? null : VatanKayitRowInnerPropsHazirla(seciliHesabinKayitlari2, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

  const sarki = genelDurumHelper.SeciliKoroModel.tumSarkilar.single(e => e.id === props.sarkiId);
  const hesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList;
  const kayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList;

  let yorumBekleyenlerIcerik: JSX.Element[] | JSX.Element = <CsmSwipableListItem><CsmText note>Yorum bekleyen kaydı olan kullanıcı bulunmamaktadır</CsmText></CsmSwipableListItem>;
  let hataliKaydiOlanlarIcerik: JSX.Element[] | JSX.Element = <CsmSwipableListItem><CsmText note>Hatalı kaydı olan kullanıcı bulunmamaktadır</CsmText></CsmSwipableListItem>;
  let kayitGondermeyenlerIcerik: JSX.Element[] | JSX.Element = <CsmSwipableListItem><CsmText note>Kayıt göndermemiş kullanıcı bulunmamaktadır</CsmText></CsmSwipableListItem>;
  let kaydiUygunOlanlarIcerik: JSX.Element[] | JSX.Element = <CsmSwipableListItem><CsmText note>Kaydı uygun kullanıcı bulunmamaktadır</CsmText></CsmSwipableListItem>;

  const sarkininTumKayitlari = kayitlar.where(e => e.sarkiModelId === sarki.id);

  const yorumBekleyenlenKullanicilar: HesapBilgiVeKullaniciModel[] = [];
  const hataliKaydiOlanKullanicilar: HesapBilgiVeKullaniciModel[] = [];
  const kayitGondermeyenKullanicilar: HesapBilgiVeKullaniciModel[] = [];
  const kaydiUygunOlanKullanicilar: HesapBilgiVeKullaniciModel[] = [];

  const kullanicilar = hesaplar.where(e => e.aktif === true && BusinessHelper.Genel.KoristMi(e) && (araVerenKoristleriGoster || !e.araVerdi));

  const seciliHesapAdSoyad = seciliHesap == null ? "" : `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;
  const seciliHesapYorum = genelDurumHelper.SeciliKoroModel.hesapYorumModelList.singleOrDefault(e => e.hesapModelId === seciliHesap?.id);

  const koristYorumVeYetkiVarMi = genelDurumHelper.SeciliKoroModel.koroAyarlari.koristYorumArabirimiKullanilsin && genelDurumHelper.HasYetkiAll([EnmYetki["Kayıt Onaylama"]]);

  kullanicilar.forEach((hesap) => {
    const kayitlar = sarkininTumKayitlari.where(e => e.hesapModelId === hesap.id);
    const sonKayitDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar);

    if (sonKayitDurum == null)
      kayitGondermeyenKullanicilar.push(hesap);
    else if (sonKayitDurum === EnmKayitDurum.Onaylandı)
      kaydiUygunOlanKullanicilar.push(hesap)
    else if (sonKayitDurum === EnmKayitDurum.Hatalı)
      hataliKaydiOlanKullanicilar.push(hesap)
    else if (sonKayitDurum === EnmKayitDurum["Yorum Bekleniyor"])
      yorumBekleyenlenKullanicilar.push(hesap)
  });

  if (kayitGondermeyenKullanicilar.length > 0)
    kayitGondermeyenlerIcerik = (
      <SesGrupluKisiAvatarListControl
        tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
        gosterilecekHesaplar={kayitGondermeyenKullanicilar}
        onClick={() => AlertHelper.ShowMessage("Dikkat", "Koristin şarkı için kaydı bulunmamaktadır.")}
        genelDurumHelper={genelDurumHelper} />
    );

  if (kaydiUygunOlanKullanicilar.length > 0) {
    kaydiUygunOlanlarIcerik = (
      <SesGrupluKisiAvatarListControl
        tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
        gosterilecekHesaplar={kaydiUygunOlanKullanicilar}
        onClick={(hesap) => hesapSec(hesap)}
        genelDurumHelper={genelDurumHelper} />
    );
  }
  if (hataliKaydiOlanKullanicilar.length > 0) {
    hataliKaydiOlanlarIcerik = (
      <SesGrupluKisiAvatarListControl
        tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
        gosterilecekHesaplar={hataliKaydiOlanKullanicilar}
        onClick={(hesap) => hesapSec(hesap)}
        genelDurumHelper={genelDurumHelper} />
    );
  }
  if (yorumBekleyenlenKullanicilar.length > 0) {
    yorumBekleyenlerIcerik = (
      <SesGrupluKisiAvatarListControl
        tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
        gosterilecekHesaplar={yorumBekleyenlenKullanicilar}
        onClick={(hesap) => hesapSec(hesap)}
        genelDurumHelper={genelDurumHelper} />
    );
  }


  const kayitYorumla = async (kayitModel: HesapSarkiSesKayitModel) => {
    const response = await AlertHelper.ShowClosableContentFullHeight<HesapSarkiSesKayitModel>("Kayıt Yorumla", (closer) => (
      <KayitWithPdfPopupEditor
        sarki={sarki}
        yorumKaydedebilir={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        hesapBilgi={seciliHesap!}
        hesapSarkiSesKayit={kayitModel} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToRemove = hesapSarkiKayitlar.single(e => e.id === response?.id);
        hesapSarkiKayitlar.remove(itemToRemove);
        hesapSarkiKayitlar.push(response!);
      });
  }

  const kayitSil = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel, sira: number, sesGrup: SesGrupModel) => {
    const seciliHesap = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.id === kayitModel.hesapModelId);
    const adSoyad = `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;

    if (await AlertHelper.Confirm("Dikkat", genelDurumHelper.translate("Kullanıcısının Eserindeki kaydını silmek istediğinizden emin misiniz", { adSoyad: adSoyad, sarkiIsim: sarki.isim, sira: sira.toFixed(), sesGrupIsim: sesGrup.isim })) !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.HesapMuzikal.HesapKayitSil(kayitModel.id, "Kayıt siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToRemove = hesapSarkiKayitlar.single(e => e.id === kayitModel.id);
        hesapSarkiKayitlar.remove(itemToRemove);
      });
  }

  const kayitSesGrupGuncelle = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel) => {
    const seciliHesap = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.id === kayitModel.hesapModelId);
    const adSoyad = `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;

    const secilenSesGrupId = await AlertHelper.ShowClosableContent<string>(`${adSoyad} Kişisinin Ses Grubu Güncelleme`, (closer) => (
      <KayitSesGrubuSecmePopupEditor
        sarkiModel={sarki}
        orijinalSesGrupModelId={kayitModel.sesGrupId}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (secilenSesGrupId == null)
      return;

    const model = new HesapKayitSesGrupGuncelleRequestModel(kayitModel.id, secilenSesGrupId);

    const webResponse = await genelDurumHelper.requestHelper.HesapMuzikal.HesapKayitSesGrupGuncelle(model, "Kayıt ses grubu güncelleniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToUpdate = hesapSarkiKayitlar.single(e => e.id === kayitModel.id);
        itemToUpdate.sesGrupId = secilenSesGrupId;
      });
  }

  const hesapSec = async (hesap: HesapBilgiVeKullaniciModel | null) => {
    setSeciliHesapId(hesap?.id ?? null);
  }

  const handleYorumGuncelleClick = async () => {
    if (seciliHesap == null)
      return;

    const adSoyad = `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;

    const response = await AlertHelper.ShowClosableContent<HesapYorumModel>(`${adSoyad} kişisinin şef yorumlarını güncelle`, (closer) => (
      <KoristYorumKoristPopupEditor
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        hesapBilgi={seciliHesap}
        hesapYorum={seciliHesapYorum} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newHesapYorumList = seciliKoro.hesapYorumModelList.slice();
        const oldHesapYorum = newHesapYorumList.singleOrDefault(e => e.hesapModelId === seciliHesap.id);
        if (oldHesapYorum != null)
          newHesapYorumList.remove(oldHesapYorum);

        newHesapYorumList.push(response!);

        seciliKoro.hesapYorumModelList = newHesapYorumList;
      });
  }


  return (
    <SubPageLayout
      title={sarki.isim}
      previousLocations={[{ location: mainScreenLocations.kayitSarkiYorum }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        {!seciliHesap ? null :
          (
            <Rows mb={10} style={{ background: appColors.GRAY_LIGHT3 }}>
              <Columns hasLightBottomBorder ml={10} pb={5} style={{ alignItems: "center" }}>
                <Button style={{ marginLeft: "-20px" }} onClick={() => hesapSec(null)}>{appIcons.Popup.iptal}</Button>
                <CsmText header>Seçili Hesap: </CsmText>
                <CsmText header style={{ marginLeft: "5px", color: appColors.SECONDARY }}>{seciliHesapAdSoyad}</CsmText>
                <KorocanThumbnailForHesap key={StringHelper.nullToEmpty(seciliHesap?.id)} style={{ marginLeft: "5px" }} hesap={seciliHesap} size="normal" genelDurumHelper={genelDurumHelper} />
              </Columns>
              <Columns>
                <Rows flex>
                  {seciliHesabinKayitProps?.map(item =>
                    <VatanKayitRowListItem
                      key={item.siraNo}
                      {...item}
                      style={{ background: BusinessHelper.SesKayit.KayitDurumRenkGetir(item.kayit.kayitDurum, item.sesGrubununSonKaydiMi ? 0.4 : 0.1) }}
                      kayitSec={kayitYorumla}
                      genelDurumHelper={genelDurumHelper}
                      kayitSil={genelDurumHelper.SeciliKoroModel.hesapBilgiModel.superAdmin ? (() => kayitSil(item.kayit, sarki, item.siraNo, item.sesGrup)) : undefined}
                      kayitSesGrupGuncelle={genelDurumHelper.SeciliKoroModel.hesapBilgiModel.superAdmin ? (e => kayitSesGrupGuncelle(e, sarki)) : undefined}
                    />
                  )}
                </Rows>
                {koristYorumVeYetkiVarMi && <Rows width={350} style={{ padding: "5px", background: appColors.WHITE }}>
                  <CsmText bold>Korist Genel Yorumları:</CsmText>
                  <CsmText note italic={StringHelper.isEmpty(seciliHesapYorum?.sefYorumlar)} >{StringHelper.emptyToValue(seciliHesapYorum?.sefYorumlar, "Seçilin Korist için şef yorumu girilmemiştir")}</CsmText>
                  <Button color="secondary" onClick={() => handleYorumGuncelleClick()} >{appIcons.CrudIcons.guncelle} {genelDurumHelper.translate("Güncelle")}</Button>
                </Rows>}
              </Columns>
            </Rows>
          )}
        <Columns>
          <CsmText header style={{ marginLeft: "10px" }}>Korist Listesi</CsmText>
          <FormControl style={{ marginLeft: 5, display: "flex", flexDirection: "row", marginTop: "-2px" }}>
            <Switch checked={araVerenKoristleriGoster} onChange={(e) => setAraVerenKoristleriGoster(e.target.checked)} />
            <CsmText note style={{ alignSelf: "center" }}>{araVerenKoristleriGoster ? "Ara Veren Koristleri Göster" : "Ara Veren Koristleri Gösterme"}</CsmText>
          </FormControl>
        </Columns>
        <CsmSwipableListItem itemDividerText={`Yorum Bekleyenler (${yorumBekleyenlenKullanicilar.length})`} />
        {yorumBekleyenlerIcerik}
        <CsmSwipableListItem itemDividerText={`Hatalı Kaydı Olanlar (${hataliKaydiOlanKullanicilar.length})`} />
        {hataliKaydiOlanlarIcerik}
        <CsmSwipableListItem itemDividerText={`Kayıt Göndermeyenler (${kayitGondermeyenKullanicilar.length})`} />
        {kayitGondermeyenlerIcerik}
        <CsmSwipableListItem itemDividerText={`Kaydı Uygun Olanlar (${kaydiUygunOlanKullanicilar.length})`} />
        {kaydiUygunOlanlarIcerik}
      </Rows>

    </SubPageLayout>
  );
}

export default KayitSarkiYorumSarkiYonetimScreen;