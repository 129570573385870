/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import VatanKadroMultiSelectField from "../../../components/vatan/VatanKadroMultiSelectField";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import VatanSesGrupMultiSelectField from "../../../components/vatan/VatanSesGrupMultiSelectField";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import StringHelper from "../../../library/helpers/StringHelper";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import VatanEkHesapMultiSelectField from "../../../components/vatan/VatanEkHesapMultiSelectField";

interface IAnketPopupEditorProps {
    anketModel: AnketModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: AnketModel | null) => void,
}

function AnketPopupEditor(props: IAnketPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [anketModel, setAnketModel] = useState<AnketModel>(JsonHelper.deepCopy(props.anketModel));

    const baslangicTarihHata = (anketModel.tarihBaslangic && anketModel.tarihBitis && anketModel.tarihBaslangic > anketModel.tarihBitis)
        ? "Başlanhıç tarihi, bitiş tarihinden sonra olamaz" : "";

    const baslikHataVar = StringHelper.isEmpty(anketModel.baslik);
    const baslangicTarihHataVar = !!baslangicTarihHata || !anketModel.tarihBaslangic;
    const bitisTarihHataVar = !anketModel.tarihBitis;
    const sezonHataVar = StringHelper.isEmpty(anketModel.sezonModelId);
    const kadroHataVar = anketModel.kadroIdList.length === 0;

    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === anketModel.sezonModelId);

    const updateAnketModel = (action: (model: AnketModel) => void) => {
        const result = JsonHelper.deepCopy(anketModel);
        action(result);
        setAnketModel(result);
    }

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Anket.AnketGuncelle(anketModel, "Anket güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Anket.AnketEkle(anketModel, "Anket ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(anketModel);
    }

    let baslamayaKalanSureText = "";
    let anketSuresiText = "";

    if (!baslangicTarihHataVar && !bitisTarihHataVar) {
        if (anketModel.tarihBaslangic < DateTimeHelper.today())
            baslamayaKalanSureText = "Anket başladı";
        else {
            const baslamayaKalanSure = DateTimeHelper.substractForDays(DateTimeHelper.today(), anketModel.tarihBaslangic);
            if (baslamayaKalanSure === 0)
                baslamayaKalanSureText = `bugün başlayacak`;
            else
                baslamayaKalanSureText = `${baslamayaKalanSure} gün sonra başlayacak`;
        }

        const anketSure = DateTimeHelper.substractForDays(anketModel.tarihBaslangic, anketModel.tarihBitis);
        if (anketModel.tarihBitis < DateTimeHelper.today())
            anketSuresiText = `Süre: Anket ${anketSure} gün sürdü`;
        else
            anketSuresiText = `Süre: Anket ${anketSure} gün sürecek`;
    }

    return (
        <Rows width={850} height={350} pt={20}>
            <CsmTextField
                disabled={!guncellenebilir}
                label="Anket Başlığı"
                error={baslikHataVar}
                value={anketModel.baslik}
                onChange={(e) => updateAnketModel(t => t.baslik = e)} />
            <Columns mt={10} gap={10}>
                <CsmDatePicker
                    disabled={!guncellenebilir}
                    label="Başlangıç Tarihi"
                    error={baslangicTarihHataVar}
                    helperText={baslangicTarihHata}
                    value={anketModel.tarihBaslangic}
                    onChange={(e) => updateAnketModel(t => t.tarihBaslangic = e ?? null as any)} />
                <CsmDatePicker
                    disabled={!guncellenebilir}
                    label="Bitiş Tarihi"
                    error={bitisTarihHataVar}
                    value={anketModel.tarihBitis}
                    onChange={(e) => updateAnketModel(t => t.tarihBitis = e ?? null as any)} />
                <Rows mt={5}>
                    <CsmText note>{`Başlangıç: ${baslamayaKalanSureText}`}</CsmText>
                    <CsmText note>{`${anketSuresiText}`}</CsmText>
                </Rows>
            </Columns>
            <Columns mt={10} gap={10}>
                <VatanSezonSelectField
                    disabled={!guncellenebilir}
                    value={anketModel.sezonModelId}
                    label={"Sezon"}
                    tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                    error={sezonHataVar}
                    onChange={(e) => updateAnketModel(t => { t.sezonModelId = e; t.kadroIdList = []; })}
                />
                <VatanKadroMultiSelectField
                    disabled={!guncellenebilir}
                    label="Hedef Kadrolar"
                    error={kadroHataVar}
                    value={anketModel.kadroIdList}
                    tumKadroList={secilebilirKadrolar}
                    onChange={(e) => updateAnketModel(t => t.kadroIdList = e)}
                    id={anketModel.id} />
            </Columns>
            <Columns mt={10} gap={10}>
                <VatanSesGrupMultiSelectField
                    sx={{ height: "65px", alignContent: "flex-start", justifyItems: "flex-start" }}
                    disabled={!guncellenebilir}
                    label="Hedef Ses Grupları"
                    value={anketModel.sesGrupIdList}
                    tumSesGruplari={genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip !== EnmSesGrupTip.Diğer)}
                    onChange={(e) => updateAnketModel(t => t.sesGrupIdList = e)}
                    id={anketModel.id} />
                <VatanEkHesapMultiSelectField
                    disabled={!guncellenebilir}
                    label="Ankete Dahil Ek Hesaplar"
                    value={anketModel.ekHesapIdList}
                    onChange={(e) => updateAnketModel(t => t.ekHesapIdList = e)}
                    id={anketModel.id}
                    genelDurumHelper={genelDurumHelper} />
            </Columns>

            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={baslikHataVar || baslangicTarihHataVar || bitisTarihHataVar || kadroHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default AnketPopupEditor;