/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { KonserModel } from "../../../models/businessModels/KonserModel";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { KonserModelWithContentRequestModel } from "../../../models/requestModels/KonserModelWithContentRequestModel";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import Cell from "../../../library/components/layout/Cell";
import KonserSarkiListControl from "./KonserSarkiListControl";
import StringHelper from "../../../library/helpers/StringHelper";

interface IKonserPopupEditorProps {
    konserModel: KonserModel,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: KonserModelWithContentRequestModel | null) => void,
}

function KonserPopupEditor(props: IKonserPopupEditorProps) {
    const { closer, guncellemeMi, genelDurumHelper } = props;

    const [konserModel, setKonserModel] = useState<KonserModel>(JsonHelper.deepCopy(props.konserModel));
    const [icerikEklemeRequestModel, setIcerikEklemeRequestModel] = useState<IcerikEklemeRequestModel | null>(null);

    const tumKonserler = genelDurumHelper.SeciliKoroModel.tumKonserler;

    const updateKonserModel = (action: (model: KonserModel) => void) => {
        const result = JsonHelper.deepCopy(konserModel);
        action(result);
        setKonserModel(result);
    }

    const handleAfisIcerikUpdate = (model: IcerikEklemeRequestModel | null) => {
        setIcerikEklemeRequestModel(model);
        updateKonserModel(e => e.resimIcerikId = model?.id ?? null);
    }

    const isimHataString = tumKonserler.any(e => e.id !== konserModel.id && e.isim === konserModel.isim) ? "Bu isimde başka bir konser bulunmaktadır" : "";
    const isimHata = isimHataString.length > 0 || StringHelper.isEmpty(konserModel.isim) || konserModel.isim.length > 150;
    const sezonHata = StringHelper.isEmpty(konserModel.sezonModelId);
    const kadroHata = StringHelper.isEmpty(konserModel.kadroModelId);
    const yerHata = (StringHelper.nullToEmpty(konserModel.yer)).trim() === "" || konserModel.yer.length > 150;
    const sehirUlkeHata = (StringHelper.nullToEmpty(konserModel.sehirUlke)).trim() === "" || konserModel.sehirUlke.length > 150;
    const ortakKoroError = StringHelper.nullToEmpty(konserModel.ortakKoro).length > 250;

    //const sezonSarkilari = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === konserModel.sezonModelId)).orderByTurkish(e => e.isim);
    const sarkiListHata = konserModel.konserSarkiModelList.any(e => StringHelper.nullToEmpty(e.videoUrl).length > 500);

    const hataVar = isimHata || sezonHata || kadroHata || yerHata || sehirUlkeHata || sarkiListHata || ortakKoroError;

    const kaydet = async () => {
        const model = new KonserModelWithContentRequestModel(konserModel, icerikEklemeRequestModel ? [icerikEklemeRequestModel] : []);

        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Konser.KonserGuncelle(model, "Konser güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Konser.KonserEkle(model, "Konser ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(model);
    }

    return (
        <Rows height={520} style={{ minWidth: 850 }}>
            <Columns flex dontGrowForChild>
                <Columns width={350} wrap mt={8}>
                    <Cell width={345} mr={5} mb={10}>
                        <CsmTextField
                            fullWidth
                            label="Konser İsmi"
                            error={isimHata}
                            helperText={isimHataString}
                            value={konserModel.isim}
                            appendMaxlenOnLabel={150}
                            onChange={(e) => updateKonserModel(t => t.isim = e)} />
                    </Cell>
                    <Cell width={195} mr={5} mb={10}>
                        <VatanSezonSelectField
                            error={sezonHata}
                            value={konserModel.sezonModelId}
                            label="Bağlı Olduğu Sezon"
                            tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                            onChange={value => updateKonserModel(e => { e.sezonModelId = value; e.kadroModelId = "" })} />
                    </Cell>
                    <Cell width={145} mr={5} mb={10}>
                        <CsmDatePicker
                            label={"Konser Tarihi"}
                            value={konserModel.tarih}
                            onChange={value => updateKonserModel(e => e.tarih = value)} />
                    </Cell>
                    <Cell width={345} mr={5} mb={10}>
                        <VatanKadroSelectField
                            label={"Konser Kadrosu"}
                            error={kadroHata}
                            value={konserModel.kadroModelId}
                            tumKadrolar={genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === konserModel.sezonModelId)}
                            onChange={value => updateKonserModel(e => e.kadroModelId = value)} />
                    </Cell>
                    <Cell width={345} mr={5} mb={10}>
                        <CsmTextField
                            label={"Konser Yeri"}
                            error={yerHata}
                            value={konserModel.yer}
                            appendMaxlenOnLabel={150}
                            onChange={value => updateKonserModel(e => e.yer = value)} />
                    </Cell>
                    <Cell width={345} mr={5} mb={10}>
                        <CsmTextField
                            label={"Şehir / Ülke"}
                            error={sehirUlkeHata}
                            value={konserModel.sehirUlke}
                            appendMaxlenOnLabel={150}
                            onChange={value => updateKonserModel(e => e.sehirUlke = value)} />
                    </Cell>
                    <Cell width={345} mr={5} mb={10}>
                        <VatanIcerikUploadField
                            fullWidth
                            label="Konser Afişi"
                            showClearButton
                            onChange={handleAfisIcerikUpdate}
                            genelDurumHelper={genelDurumHelper}
                            icerikId={konserModel.resimIcerikId}
                            icerikModel={icerikEklemeRequestModel ?? null}
                            icerikTip={EnmIcerikTip["Konser Afiş"]}
                        />
                    </Cell>
                    <Cell width={345} mr={5} mb={10}>
                        <CsmTextField
                            label={"Ortak Koro (varsa)"}
                            error={ortakKoroError}
                            value={konserModel.ortakKoro}
                            appendMaxlenOnLabel={250}
                            onChange={value => updateKonserModel(e => e.ortakKoro = value)} />
                    </Cell>
                </Columns>
                <Rows flex>
                    <KonserSarkiListControl
                        sezonModelId={konserModel.sezonModelId}
                        tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
                        konser={konserModel}
                        konserSarkiList={konserModel.konserSarkiModelList.orderBy(e => e.siraNo)}
                        onChange={value => updateKonserModel(e => e.konserSarkiModelList = value)}
                        genelDurumHelper={genelDurumHelper} />
                </Rows>
            </Columns>

            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default KonserPopupEditor;