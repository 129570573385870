// AutoGenerated
import { EnmSoruTipi } from "../enums/EnmSoruTipi"
import { EnmVeriTipi } from "../enums/EnmVeriTipi"
import { AnketSoruSecenekModel } from "../businessModels/AnketSoruSecenekModel"
import { AnketSoruKosulModel } from "../businessModels/AnketSoruKosulModel"

export class AnketSoruModel {
    soruIcerik!: string;
    zorunlu!: boolean;
    sira!: number;
    bolumNo!: number;
    soruTipi!: EnmSoruTipi;
    veritipi!: EnmVeriTipi;
    secenekModelList!: AnketSoruSecenekModel[];
    kosulModelList!: AnketSoruKosulModel[];
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}