/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { CalismaModel } from "../models/businessModels/CalismaModel";
import { DuyuruModel } from "../models/businessModels/DuyuruModel";
import { HataModel } from "../models/businessModels/HataModel";
import { HesapAnketCevapModel } from "../models/businessModels/HesapAnketCevapModel";
import { HesapBilgiModel } from "../models/businessModels/HesapBilgiModel";
import { HesapSarkiSesKayitModel } from "../models/businessModels/HesapSarkiSesKayitModel";
import { IcerikModel } from "../models/businessModels/IcerikModel";
import { KoroAyarlariModel } from "../models/businessModels/KoroAyarlariModel";
import { KullaniciModel } from "../models/businessModels/KullaniciModel";
import { RolModel } from "../models/businessModels/RolModel";
import { SarkiLinkModel } from "../models/businessModels/SarkiLinkModel";
import { SarkiModel } from "../models/businessModels/SarkiModel";
import { SesGrupModel } from "../models/businessModels/SesGrupModel";
import { SezonModel } from "../models/businessModels/SezonModel";
import { EnmKatilimBildirmeDurum } from "../models/enums/EnmKatilimBildirmeDurum";
import { AnketSonucGonderRequestModel } from "../models/requestModels/AnketSonucGonderRequestModel";
import { GeriIzlemeAraRequestModel } from "../models/requestModels/GeriIzlemeAraRequestModel";
import { GuidRequestModel } from "../models/requestModels/GuidRequestModel";
import { HataAraRequestModel } from "../models/requestModels/HataAraRequestModel";
import { HesapBilgiVeKullaniciWithContentRequestModel } from "../models/requestModels/HesapBilgiVeKullaniciWithContentRequestModel";
import { HesapCalismaKatilimDurumGuncelleRequestModel } from "../models/requestModels/HesapCalismaKatilimDurumGuncelleRequestModel";
import { HesapCalismaYoklamaBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaBildirRequestModel";
import { HesapCalismaYoklamaListBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaListBildirRequestModel";
import { HesapKayitSesGrupGuncelleRequestModel } from "../models/requestModels/HesapKayitSesGrupGuncelleRequestModel";
import { HesapKayitYorumRequestModel } from "../models/requestModels/HesapKayitYorumRequestModel";
import { HesapKuartetDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetDurumGuncelleRequestModel";
import { HesapKuartetListDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetListDurumGuncelleRequestModel";
import { HesapYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapYorumEkleGuncelleRequestModel";
import { KoroGuidVeKullaniciAdiRequestModel } from "../models/requestModels/KoroGuidVeKullaniciAdiRequestModel";
import { KoroVeHesapWithContentRequestModel } from "../models/requestModels/KoroVeHesapWithContentRequestModel";
import { KullaniciProfilSifreGuncelleRequestModel } from "../models/requestModels/KullaniciProfilSifreGuncelleRequestModel";
import { LoginRequestModel } from "../models/requestModels/LoginRequestModel";
import { SifreGuncellemeRequestModel } from "../models/requestModels/SifreGuncellemeRequestModel";
import { TaslakKayitSilRequestModel } from "../models/requestModels/TaslakKayitSilRequestModel";
import { TaslakKayitiOnaylaRequestModel } from "../models/requestModels/TaslakKayitiOnaylaRequestModel";
import { WithContentRequestModel } from "../models/requestModels/WithContentRequestModel";
import { WithParentIdAndContentRequestModel } from "../models/requestModels/WithParentIdAndContentRequestModel";
import { AuthenticationResponseViewModel } from "../models/viewModels/AuthenticationResponseViewModel";
import { GenelDurumViewModel } from "../models/viewModels/GenelDurumViewModel";
import { GeriIzlemeViewModel } from "../models/viewModels/GeriIzlemeViewModel";
import { IcerikListViewModel } from "../models/viewModels/IcerikListViewModel";
import { IcerikMetaViewModel } from "../models/viewModels/IcerikMetaViewModel";
import { SesGrupTopluSiraNoGuncelleRequestModel } from "../models/requestModels/SesGrupTopluSiraNoGuncelleRequestModel";
import { OtpSifreGuncellemeRequestModel } from "../models/requestModels/OtpSifreGuncellemeRequestModel";
import { OtpAraRequestModel } from "../models/requestModels/OtpAraRequestModel";
import { OtpViewModel } from "../models/viewModels/OtpViewModel";
import { KadroEkleGuncelleRequestModel } from "../models/requestModels/KadroEkleGuncelleRequestModel";
import { TatilModel } from "../models/businessModels/TatilModel";
import { WithParentIdRequestModel } from "../models/requestModels/WithParentIdRequestModel";
import { SarkiYorumModel } from "../models/businessModels/SarkiYorumModel";
import { KorocanTranslateFuncType } from "../translations/TranslationHelper";
import { KonserModelWithContentRequestModel } from "../models/requestModels/KonserModelWithContentRequestModel";
import { OzgemicGuncelleRequestModel } from "../models/requestModels/OzgemicGuncelleRequestModel";
import { SezonAdaySarkiGuncelleRequestModel } from "../models/requestModels/SezonAdaySarkiGuncelleRequestModel";
import { TarzModel } from "../models/businessModels/TarzModel";
import { SarkiZorlukModel } from "../models/businessModels/SarkiZorlukModel";
import { SecmeBasvuruWithContentRequestModel } from "../models/requestModels/SecmeBasvuruWithContentRequestModel";
import { SecmeBasvuruTarihGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruTarihGuncelleRequestModel";
import { SecmeBasvuruProfilGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruProfilGuncelleRequestModel";
import { SecmeBasvuruSonucRequestModel } from "../models/requestModels/SecmeBasvuruSonucRequestModel";
import { SecmeKatilimBildirmeDurumGuncelleRequestModel } from "../models/requestModels/SecmeKatilimBildirmeDurumGuncelleRequestModel";
import { SecmeBasvuruSecmeNotuGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruSecmeNotuGuncelleRequestModel";
import { HesapEgitmenYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapEgitmenYorumEkleGuncelleRequestModel";
import { IRequestProxy } from "./RequestHelper";
import { transMatrixKeyType } from "../translations/korocanTranslations";
import { AnketModel } from "../models/businessModels/AnketModel";
import { AnketAcKapatRequestModel } from "../models/requestModels/AnketAcKapatRequestModel";

export function RequestEndpoints(requestProxy: IRequestProxy, translate: KorocanTranslateFuncType) {
    return {
        Kullanici: {
            "Signin": (model: LoginRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<AuthenticationResponseViewModel>("api/Kullanici/Signin", model, message ? translate(message, v) : ""),
            "SifreGuncelle": (model: SifreGuncellemeRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction("api/Kullanici/SifreGuncelle", model, message ? translate(message, v) : ""),
            "OtpSifreGuncelle": (model: OtpSifreGuncellemeRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction("api/Kullanici/OtpSifreGuncelle", model, message ? translate(message, v) : ""),
            "ProfilGuncelle": (kullaniciModel: KullaniciModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction("api/Kullanici/ProfilGuncelle", kullaniciModel, message ? translate(message, v) : ""),
            "SifreHatirlat": (kullaniciAdi: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<string>("api/Kullanici/SifreHatirlat", kullaniciAdi, message ? translate(message, v) : ""),
        },
        Genel: {
            "GenelDurumGetir": (message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<GenelDurumViewModel>(`api/Genel/GenelDurumGetir`, null, message ? translate(message, v) : ""),
            "IcerikListGetir": (idList: string[], message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<IcerikListViewModel>(`api/Genel/IcerikListGetir`, idList, message ? translate(message, v) : ""),
            "IcerikGetirDirect": (id: string, extension: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformGetFileAction(`Icerik/file-${id}${extension}`, undefined, message ? translate(message, v) : ""),
            "IcerikGetir": (id: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformGetAction<IcerikModel>(`api/Genel/IcerikGetir?icerikId=${id}`, undefined, message ? translate(message, v) : ""),
            "IcerikGetirPDF": (id: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformGetAction<IcerikModel[]>(`api/Genel/IcerikGetirPDF?icerikId=${id}`, undefined, message ? translate(message, v) : ""),
            "IcerikMetaListGetir": (idList: string[], message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/Genel/IcerikMetaListGetir`, idList, message ? translate(message, v) : ""),
            "HataGetir": (model: HataAraRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<HataModel[]>(`api/Genel/HataGetir`, model, message ? translate(message, v) : ""),
            "GeriIzlemeGetir": (model: GeriIzlemeAraRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<GeriIzlemeViewModel[]>(`api/Genel/GeriIzlemeGetir`, model, message ? translate(message, v) : ""),
            "OtpGetir": (model: OtpAraRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<OtpViewModel[]>(`api/Genel/OtpGetir`, model, message ? translate(message, v) : ""),
            "OtpTemizle": (gunSayisi: number, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/Genel/OtpTemizle`, gunSayisi, message ? translate(message, v) : ""),
        },
        Kadro: {
            "KadroSil": (kadroId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/kadro/sil`, new GuidRequestModel(kadroId), message ? translate(message, v) : ""),
            "KadroGuncelle": (model: KadroEkleGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/kadro/guncelle`, model, message ? translate(message, v) : ""),
            "KadroEkle": (kadro: KadroEkleGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/kadro/ekle`, kadro, message ? translate(message, v) : ""),
        },
        Tarz: {
            "TarzSil": (tarzId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tarz/sil`, new GuidRequestModel(tarzId), message ? translate(message, v) : ""),
            "TarzGuncelle": (model: TarzModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tarz/guncelle`, model, message ? translate(message, v) : ""),
            "TarzEkle": (model: TarzModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tarz/ekle`, model, message ? translate(message, v) : ""),
        },
        SesGrup: {
            "SesGrupSil": (sesGrupId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/sil`, new GuidRequestModel(sesGrupId), message ? translate(message, v) : ""),
            "SesGrupGuncelle": (sesGrup: SesGrupModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/guncelle`, sesGrup, message ? translate(message, v) : ""),
            "SesGrupEkle": (sesGrup: SesGrupModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/ekle`, sesGrup, message ? translate(message, v) : ""),
            "TopluSiraNoGuncelle": (siraliIdList: string[], message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/TopluSiraNoGuncelle`, new SesGrupTopluSiraNoGuncelleRequestModel(siraliIdList), message ? translate(message, v) : ""),
        },
        Rol: {
            "RolSil": (rolId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/rol/sil`, new GuidRequestModel(rolId), message ? translate(message, v) : ""),
            "RolGuncelle": (rol: RolModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/rol/guncelle`, rol, message ? translate(message, v) : ""),
            "RolEkle": (rol: RolModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/rol/ekle`, rol, message ? translate(message, v) : ""),
        },
        Sezon: {
            "SezonSil": (sezonId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sezon/sil`, new GuidRequestModel(sezonId), message ? translate(message, v) : ""),
            "SezonGuncelle": (sezon: SezonModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sezon/guncelle`, sezon, message ? translate(message, v) : ""),
            "SezonEkle": (sezon: SezonModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sezon/ekle`, sezon, message ? translate(message, v) : ""),
        },
        Duyuru: {
            "DuyuruSil": (duyuruId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/duyuru/sil`, new GuidRequestModel(duyuruId), message ? translate(message, v) : ""),
            "DuyuruGuncelle": (duyuru: DuyuruModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/duyuru/guncelle`, duyuru, message ? translate(message, v) : ""),
            "DuyuruEkle": (duyuru: DuyuruModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/duyuru/ekle`, duyuru, message ? translate(message, v) : ""),
        },
        Calisma: {
            "CalismaSil": (calismaId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/calisma/sil`, new GuidRequestModel(calismaId), message ? translate(message, v) : ""),
            "CalismaGuncelle": (calisma: CalismaModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/calisma/guncelle`, calisma, message ? translate(message, v) : ""),
            "CalismaEkle": (calisma: CalismaModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/calisma/ekle`, calisma, message ? translate(message, v) : ""),
            "HesapCalismaYoklamaBildir": (model: HesapCalismaYoklamaBildirRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/calisma/HesapCalismaYoklamaBildir`, model, message ? translate(message, v) : ""),
            "HesapCalismaYoklamaListBildir": (model: HesapCalismaYoklamaListBildirRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/calisma/HesapCalismaYoklamaListBildir`, model, message ? translate(message, v) : ""),
        },
        Anket: {
            "AnketSil": (anketId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/anket/sil`, new GuidRequestModel(anketId), message ? translate(message, v) : ""),
            "AnketGuncelle": (anket: AnketModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/anket/guncelle`, anket, message ? translate(message, v) : ""),
            "AnketEkle": (anket: AnketModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/anket/ekle`, anket, message ? translate(message, v) : ""),
            "AnketAcKapat": (model: AnketAcKapatRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/anket/acKapat`, model, message ? translate(message, v) : ""),
        },
        Hesap: {
            "HesapSil": (hesapId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesap/sil`, new GuidRequestModel(hesapId), message ? translate(message, v) : ""),
            "HesapGuncelle": (hesap: HesapBilgiVeKullaniciWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesap/guncelle`, hesap, message ? translate(message, v) : ""),
            "HesapEkle": (hesap: HesapBilgiVeKullaniciWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesap/ekle`, hesap, message ? translate(message, v) : ""),
            "HesapPasifYap": (hesapId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesap/pasifYap`, new GuidRequestModel(hesapId), message ? translate(message, v) : ""),
            "HesapAktifYap": (hesapId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesap/aktifYap`, new GuidRequestModel(hesapId), message ? translate(message, v) : ""),
            "KullaniciAra": (kullaniciAdi: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/hesap/KullaniciAra`, kullaniciAdi, message ? translate(message, v) : ""),
        },
        HesapMuzikal: {
            "HesapKuartetDurumGuncelle": (kuartetDurum: HesapKuartetDurumGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKuartetDurumGuncelle`, kuartetDurum, message ? translate(message, v) : ""),
            "HesapKuartetListDurumGuncelle": (kuartetListDurum: HesapKuartetListDurumGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKuartetListDurumGuncelle`, kuartetListDurum, message ? translate(message, v) : ""),
            "HesapKayitYorumla": (model: WithContentRequestModel<HesapKayitYorumRequestModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitYorumla`, model, message ? translate(message, v) : ""),
            "HesapKayitSesGrupGuncelle": (model: HesapKayitSesGrupGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitSesGrupGuncelle`, model, message ? translate(message, v) : ""),
            "HesapKayitSil": (hesapKayitId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitSil`, new GuidRequestModel(hesapKayitId), message ? translate(message, v) : ""),
            "HesapYorumEkleGuncelle": (model: HesapYorumEkleGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapYorumEkleGuncelle`, model, message ? translate(message, v) : ""),
            "HesapEgitmenYorumEkleGuncelle": (model: HesapEgitmenYorumEkleGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapEgitmenYorumEkleGuncelle`, model, message ? translate(message, v) : ""),
        },
        Sarki: {
            "SarkiSil": (sarkiId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/Sil`, new GuidRequestModel(sarkiId), message ? translate(message, v) : ""),
            "SarkiGuncelle": (model: SarkiModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/Guncelle`, model, message ? translate(message, v) : ""),
            "SarkiEkle": (model: SarkiModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/Ekle`, model, message ? translate(message, v) : ""),
            "SarkiKayitSureciAc": (sarkiId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/KayitSureciAc`, new GuidRequestModel(sarkiId), message ? translate(message, v) : ""),
            "SarkiKayitSureciKapat": (sarkiId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/KayitSureciKapat`, new GuidRequestModel(sarkiId), message ? translate(message, v) : ""),
            "SarkiLinkSil": (sarkiLinkId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkSil`, new GuidRequestModel(sarkiLinkId), message ? translate(message, v) : ""),
            "SarkiLinkEkle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkEkle`, model, message ? translate(message, v) : ""),
            "SarkiLinkGuncelle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkGuncelle`, model, message ? translate(message, v) : ""),
            "SarkiLinkListGuncelle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel[]>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkListGuncelle`, model, message ? translate(message, v) : ""),
            "SezonAdaySarkiListGuncelle": (model: SezonAdaySarkiGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarki/AdaySarkiListGuncelle`, model, message ? translate(message, v) : ""),
        },
        SarkiYorum: {
            "SarkiYorumSil": (sarkiLinkId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumSil`, new GuidRequestModel(sarkiLinkId), message ? translate(message, v) : ""),
            "SarkiYorumEkle": (model: WithParentIdRequestModel<SarkiYorumModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumEkle`, model, message ? translate(message, v) : ""),
            "SarkiYorumGuncelle": (model: WithParentIdRequestModel<SarkiYorumModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumGuncelle`, model, message ? translate(message, v) : ""),
        },
        SarkiZorluk: {
            "SarkiZorlukSil": (sarkiLinkId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukSil`, new GuidRequestModel(sarkiLinkId), message ? translate(message, v) : ""),
            "SarkiZorlukEkle": (model: WithParentIdRequestModel<SarkiZorlukModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukEkle`, model, message ? translate(message, v) : ""),
            "SarkiZorlukGuncelle": (model: WithParentIdRequestModel<SarkiZorlukModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukGuncelle`, model, message ? translate(message, v) : ""),
        },
        Korist: {
            "HesapCalismaKatilimDurumGuncelle": (calismaId: string, katilimDurum: EnmKatilimBildirmeDurum, gerekce: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/HesapCalismaKatilimDurumGuncelle`, new HesapCalismaKatilimDurumGuncelleRequestModel(calismaId, katilimDurum, gerekce), message ? translate(message, v) : ""),
            "TaslakKayitiOnayla": (sarkiId: string, hesapSarkiSesKayitModelId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/TaslakKayitiOnayla`, new TaslakKayitiOnaylaRequestModel(sarkiId, hesapSarkiSesKayitModelId), message ? translate(message, v) : ""),
            "HesapSarkiGonder": (model: WithContentRequestModel<HesapSarkiSesKayitModel>, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/HesapSarkiGonder`, model, message ? translate(message, v) : ""),
            "TaslakKayitSil": (sarkiId: string, hesapSarkiSesKayitModelId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/TaslakKayitSil`, new TaslakKayitSilRequestModel(sarkiId, hesapSarkiSesKayitModelId), message ? translate(message, v) : ""),
            "AnketSonucGonder": (anketId: string, hesapAnketSecenekList: HesapAnketCevapModel[], message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/AnketSonucGonder`, new AnketSonucGonderRequestModel(anketId, hesapAnketSecenekList), message ? translate(message, v) : ""),
            "DuyuruOkunduIsaretle": (duyuruId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/DuyuruOkunduIsaretle`, new GuidRequestModel(duyuruId), message ? translate(message, v) : ""),
            "OzgecmicGuncelle": (model: OzgemicGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/korist/OzgecmisGuncelle`, model, message ? translate(message, v) : ""),
        },
        Koro: {
            "KoroSil": (koroId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/Sil`, new GuidRequestModel(koroId), message ? translate(message, v) : ""),
            "KoroGuncelle": (koro: KoroVeHesapWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/Guncelle`, koro, message ? translate(message, v) : ""),
            "KoroEkle": (koro: KoroVeHesapWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/Ekle`, koro, message ? translate(message, v) : ""),
            "KullaniciAra": (kullaniciAdi: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/koro/KullaniciAra`, kullaniciAdi, message ? translate(message, v) : ""),
            "KullaniciProfilSifreGuncelle": (model: KullaniciProfilSifreGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/koro/KullaniciProfilSifreGuncelle`, model, message ? translate(message, v) : ""),
            "HesapAra": (koroId: string, kullaniciAdi: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<HesapBilgiModel>(`api/yonetim/koro/KullaniciAra`, new KoroGuidVeKullaniciAdiRequestModel(koroId, kullaniciAdi), message ? translate(message, v) : ""),
            "KoroAyarlariniGuncele": (koroAyarlariModel: KoroAyarlariModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/KoroAyarlariniGuncele`, koroAyarlariModel, message ? translate(message, v) : ""),
            "KoroDosyaBoyutGetir": (koroId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<number>(`api/yonetim/koro/KoroDosyaBoyutGetir`, new GuidRequestModel(koroId), message ? translate(message, v) : ""),
            "KullanilmayanIcerikSil": (idList: string[], message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/KullanilmayanIcerikSil`, idList, message ? translate(message, v) : ""),
            "KullanilmayanIcerikGetir": (koroId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/yonetim/koro/KullanilmayanIcerikGetir`, new GuidRequestModel(koroId), message ? translate(message, v) : ""),
            "TopluIslem": (sarkiId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/koro/TopluIslem`, new GuidRequestModel(sarkiId), message ? translate(message, v) : ""),
        },
        Tatil: {
            "TatilSil": (tatilId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tatil/sil`, new GuidRequestModel(tatilId), message ? translate(message, v) : ""),
            "TatilGuncelle": (tatil: TatilModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tatil/guncelle`, tatil, message ? translate(message, v) : ""),
            "TatilEkle": (tatil: TatilModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/tatil/ekle`, tatil, message ? translate(message, v) : ""),
        },
        Konser: {
            "KonserSil": (konserId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/konser/sil`, new GuidRequestModel(konserId), message ? translate(message, v) : ""),
            "KonserGuncelle": (konser: KonserModelWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/konser/guncelle`, konser, message ? translate(message, v) : ""),
            "KonserEkle": (konser: KonserModelWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/konser/ekle`, konser, message ? translate(message, v) : ""),
        },
        Secme: {
            "BasvuruSil": (secmeBasvuruId: string, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruSil`, new GuidRequestModel(secmeBasvuruId), message ? translate(message, v) : ""),
            "BasvuruGuncelle": (model: SecmeBasvuruWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruGuncelle`, model, message ? translate(message, v) : ""),
            "BasvuruEkle": (model: SecmeBasvuruWithContentRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruEkle`, model, message ? translate(message, v) : ""),
            "SecmeKatilimBildirmeDurumGuncelle": (model: SecmeKatilimBildirmeDurumGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/SecmeKatilimBildirmeDurumGuncelle`, model, message ? translate(message, v) : ""),
            "BasvuruTarihGuncelle": (model: SecmeBasvuruTarihGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruTarihGuncelle`, model, message ? translate(message, v) : ""),
            "SecmeBasvuruSecmeNotuGuncelle": (model: SecmeBasvuruSecmeNotuGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/SecmeBasvuruSecmeNotuGuncelle`, model, message ? translate(message, v) : ""),
            "BasvuruProfilGuncelle": (model: SecmeBasvuruProfilGuncelleRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruProfilGuncelle`, model, message ? translate(message, v) : ""),
            "SonucGuncelle": (model: SecmeBasvuruSonucRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucGuncelle`, model, message ? translate(message, v) : ""),
            "SonucEkle": (model: SecmeBasvuruSonucRequestModel, message?: transMatrixKeyType, v?: { [key: string]: string }) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucekle`, model, message ? translate(message, v) : ""),
        },
    }
}
