import { createTheme, ThemeOptions } from "@mui/material";
import EnvironmentHelper from "../helpers/EnvironmentHelper";

const defaultThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: EnvironmentHelper.isTest() ? "#f1f2da" : "#fafafa",
      paper: "#ccf0ea",
    },
    primary: {
      main: EnvironmentHelper.isTest() ? '#b6bc47' : '#004d40',
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "58px",
          minHeight: "58px !important",
          maxHeight: "58px"
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: 0,
          marginBottom: 0,
        },
      }
    },
  }
};

const theme = createTheme(defaultThemeOptions);

// export const narrowTheme = createTheme(JsonHelper.mergeDeep(defaultThemeOptions, narrowThemeOptions));
// export const narrowThemeForSelect = createTheme(JsonHelper.mergeDeep(JsonHelper.mergeDeep(defaultThemeOptions, narrowThemeOptions), narrowThemeOptionsSelectAdditions));

export default theme;