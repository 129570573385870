/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import VatanSesGrupSelectField from "../../../components/vatan/VatanSesGrupSelectField";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";

interface IKayitSesGrubuSecmePopupEditorProps {
    sarkiModel: SarkiModel,
    orijinalSesGrupModelId: string,
    genelDurumHelper: GenelDurumHelper,
    closer: (sesGrupModelId: string | null) => void,
}

function KayitSesGrubuSecmePopupEditor(props: IKayitSesGrubuSecmePopupEditorProps) {
    const { closer, sarkiModel, orijinalSesGrupModelId, genelDurumHelper } = props;

    const [sesGrupId, setSesGrupId] = useState<string>(orijinalSesGrupModelId);

    const kaydet = async () => {
        closer(sesGrupId);
    }

    function sesGrubuAyarla(e: any): void {
        setSesGrupId(e);
    }

    const secilebilirSesGruplariniGetir = (sarkiSesGrupIdList: string[], tumSesGruplari: SesGrupModel[], varolanSesGrupId: string): SesGrupModel[] => {
        if (sarkiSesGrupIdList.length === 0)
            return tumSesGruplari;
        else
            return tumSesGruplari.where(e => sarkiSesGrupIdList.contains(e.id) || e.id === varolanSesGrupId);
    }

    return (
        <Rows width={400} pt={20}>
            <div style={{ margin: 10 }}>
                <VatanSesGrupSelectField
                    label="Ses Grubu"
                    error={sesGrupId === ""}
                    tumSesGruplari={secilebilirSesGruplariniGetir(sarkiModel.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumSesGruplari, sesGrupId)}
                    value={sesGrupId}
                    onChange={(e) => sesGrubuAyarla(e)} />
            </div>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={sesGrupId === orijinalSesGrupModelId} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );
}

export default KayitSesGrubuSecmePopupEditor;