/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../translations/TranslationHelper";
import Rows from "../../library/components/layout/Rows";


function YardimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  //   const requestHelper = RequestHelper(navigate, dispatch, translate);

  //   const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  //   const user = useAppSelector(getCurrentUser);

  //   useEffect(() => {
  //     if (!user.token || genelDurum == null) {
  //       GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
  //     }
  //   })

  //   if (!user.token || genelDurum == null) {
  //     return <></>;
  //   }

  //   const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  //   return <YardimScreenInner genelDurumHelper={genelDurumHelper} />
  // }

  // function YardimScreenInner(props: IMainScreenProps) {
  //   const { genelDurumHelper } = props;


  return (
    <Rows m={20}>
      <h1>Sisteme Koro Katılımına dair</h1>
      <h2>Korocan Sistemine Üyelik için gerekli bilgileri:</h2>
      <ol>
        <li>Koronun Adı</li>
        <li>Koronun Logosu (tercihen 650x300 civarlarında, yatay logo)</li>
        <li>Her korist için</li>
        <ul>
          <li>ad,</li>
          <li>soyad,</li>
          <li>eposta,</li>
          <li>ses grubu (varsa sop1-2 ayrımı seviyesinde) ve</li>
          <li>suratın net göründüğü fotoğraf</li>
        </ul>



        <li>Her eser için</li>
        <ul>
          <li>eserin adı,</li>
          <li>PDF dosyası,</li>
          <li>ses grupları (herhangi bir parti ikiye ayrılıyorsa onlar da ayrılarak),</li>
          <li>eserdeki enstruman eşlikleri (varsa)</li>
          <li>eserdeki solo partiler (varsa)</li>
          <li>tüm ses grupları ve eşlikler için ayrı ayrı hazırlanmış midi mp3 dosyaları ve bir adet tutti midi mp3 dosyası _(örnek: Bir eser SSATB + Piyano ise: toplam 7 dosya hazırlanmalıdır. Sop1, Sop2, Alto, Tenor, Bas, Piyano ve Tutti dosyaları)_</li>
        </ul>
      </ol>

      <span style={{ fontStyle: "italic" }}>
        Koristlerin bilgileri bir google sheets dosyasında, fotoğrafları da google drive&lsquo;da bir klasörde barındırılarak, sheets ve drive bağlantıları benimle paylaşılabilir
      </span>

      <h2>Midi hazırlığına dair tavsiyeler:</h2>
      <ul>
        <li>hazırlanan midilerde, ses yüksekliği %30 civarında metronom sesinin dahil olması, (özellikle boş ölçü içeren partilerde) eserin takibini kolaylaştırmaktadır.</li>
        <li>Hazırlanan midinin başında 1 boş ölçü eklenmesi, kayıt sırasında başlama kolaylığı sağlamaktadır.</li>
        <li>mididen hazırlanan dosyalarda, alakalı ses grubunun dışında başka ses grubu BULUNMAMALIDIR (metronom hariç)</li>
        <li>Çoğu eserde midide ses olarak piyano tercih edilebilir, ancak çok uzun tutulan notalar içeren eserlerde tercihe göre temiz pedal ses üreten obua, vs. gibi üflemeliler de kullanılabilir.</li>
      </ul>

      <h1>Koristlerin İçin</h1>

      <h2>1- Sisteme İlk Kayıt:</h2>
      Sisteme ilk giriş: Giriş ekranında e-posta adresinizi girip, şifre alanını boş geçip Şifremi Unuttum düğmesine basıyorsunuz, ve size gelen epostadaki adımları takip ediyorsunuz. Eğer 10 dakika içinde e-posta gelmezsa bana özelden lütfen yazınız.

      <h2>2- Çalışma Programı ve Yoklama</h2>
      Çalışma programına, sistemin &ldquo;Takvim&ldquo; modülünden erişebilirsiniz. Aynı zamanda bu ekran üzerinden çalışmalara gelip gelemeyeceğinizi işaretleyebilirsiniz.

      Not: Hem katılma, hem de katılmama durumlarınızı işaretlemeniz, programın sağlıklı ilerlemesi açısından önemlidir. Dolayısı ile &ldquo;nasıl olsa çalışmaya katılacağım, işaretlememe gerek yok&ldquo; demezseniz çok sevinirim 😊


      <h2>3- Eser Öğrenme Aşaması</h2>
      <ol>
        <li>Eserlere çalışırken ilk başta kendi partinizi dinleyerek başlıyorsunuz. Bunun için de MIDI dinle ekranında kendi ses grubumuzun sesini açıp diğer ses grubun seslerini kapatıyorsunuz ve dinleyerek kulaktan öğreniyorsunuz melodinizi</li>
        <li>Kendi melodinize sözleriyle eşlik edebilir hale geldikten sonra, yavaş yavaş diğer sesleri de açıp, o sesler varken yine hâlâ kendi melodinizi söylemeye devam ediyorsunuz.</li>
        <li>Hedefimiz, bir eserde bütün sesler tam açıkken kendi partimizi doğru bir şekilde söyleyebilmek oluyor.</li>
      </ol>
      <h2>4- Kayıt Aşaması:</h2>
      Öğrenme aşamasını tamamladığınız zaman kayıt aşaması geliyor. Amacımız: yapacağımız kayıtta hem full MIDI kaydının duyuluyor olması, hem de kendi söyleyişimizin duyuluyor olması. Bunun için tavsiye ettiğim yöntem:

      <ol>
        <li>Cep telefonunuzu, bilgisayara kendiniz arasında bir yere yerleştirmeniz</li>
        <li>Cep telefonundan herhangi bir uygulama üzerinden ses kaydı başlatmanız</li>
        <li>Bilgisayardan &ldquo;Full MIDI Dinle&ldquo; bağlantısı üzerinden midi kaydını çaldırmaya başlamanız ve o sırada kendi partinizi söylemeye başlamanız.</li>
        <li>Kayıt tamamlanınca, sistemdeki Kayıtlarım modülünden, kayıt yaptığınız dosyayı yüklemeniz.</li>
      </ol>

      <h2>5- Yorum Bekleme:</h2>
      Siz bu yüklemeyi tamamlanınca o eserin kutusu o ekranda sarı olacaktır. Bu, eserin &ldquo;şeften yorum bekliyor&ldquo; durumunda olduğunu gösterir. Bu şekilde benim ekranlarıma düştüğü zaman ben sizin kaydınızı dinliyorum ve yorumluyorum. Eğer kaydınız yeterince uygunsa &ldquo;uygun&ldquo; olarak işaretliyorum ve sizin ekranlarınızda yeşil oluyor. Kaydınız da çok (veya kritik) hata varsa &ldquo;hatalı&ldquo; olarak işaretliyorum ve sizin ekranlarınızda kırmızı oluyor.

      Yeşil olduysa, o şarkı ile ilgili sorumluluğunuzu tamamlamışsınız demektir. Kırmızı olduysa, biraz daha çalışıp daha doğru bir kayıt yapıp onu yüklemeniz gerekiyor demektir. Çalışmalara herkesin yeşil kayıtlara gelmesini bekliyoruz.

      <h2>6- Kayıt Son Tarihi:</h2>
      Kayıt son tarihlerimiz genellikle çalışma gününden 2 gün önce olarak belirleniyor. Bunun sebebi; hem bana kayıtları dinleyip yorumlayacak vakit kalması, hem de kaydın hatalı (kırmızı) olması durumunda, çalışmaya kadar kaydın düzeltilmesi için size yeterli zaman kalması.

      Not: Tabi ki kayıtları sisteme belirtilen son tarihten sonra da yükleyebilirsiniz. Ancak temel olarak amacımız, çalışmalara herkesin hazır olarak gelmesi, ve bu hazır olmanın en önemli parçası da kayıtların yapılmış olması. Kendi partisine hakim olmayarak çalışmaya gelen bir arkadaş, ister istemez önce kendi ses grubunun, sonra da genel olarak tüm çalışmanın hızını düşürüyor, bu da çalışma verimimizi azaltıyor. Aynı zamanda geç yüklenen kayıtlar sistemde görünüyor tek bir sesin bile çok kıymetli olduğu koromuzda bu şekilde bir süreç yaşanmaması için koristlerin vaktinde kayıt göndermeleri ve çalışmalara hazırlıklı gelmiş olmaları takip ediliyor.
    </Rows>
  );
}

export default YardimScreen;