import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import { Link } from "@mui/material";
import appColors from "../../../app/appColors";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import { mainScreenLocations } from "../../../app/mainScreens";

interface IAnketRaporDisplayerProps {
    genelDurumHelper: GenelDurumHelper,
    anket: AnketModel,
}

function AnketRaporDisplayer(props: IAnketRaporDisplayerProps) {
    const { anket, genelDurumHelper } = props;

    const aktifKoro = genelDurumHelper.SeciliKoroModel;

    let sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(aktifKoro.tumSesGruplari, anket.sesGrupIdList);
    const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(aktifKoro.tumKadrolar, anket.kadroIdList, "Full");

    const uygunKisiIdList = BusinessHelper.Anket.AnketeUygunKisiIdleriGetir(anket, genelDurumHelper);
    const tumCevaplayanlarIdList = aktifKoro.hesapAnketCevapModelList.where(e => e.anketModelId === anket.id).select(e => e.hesapModelId).distinct();
    const anketeDahilOlacakHerkes = [...uygunKisiIdList, ...tumCevaplayanlarIdList].distinct();
    //const cevaplamayanHerkes = anketeDahilOlacakHerkes.except(tumCevaplayanlarIdList).distinct();

    const durumIcerik = anket.aktif ? <>
        <span style={{ fontWeight: "bold", color: appColors.GREEN }}>
            &nbsp;{anket.aktif ? "Açık" : "Kapalı"}
        </span>
        &nbsp;({tumCevaplayanlarIdList.length} / {anketeDahilOlacakHerkes.length})
    </> : <>
        <span>
            Kapalı
        </span>
        &nbsp;
        ({tumCevaplayanlarIdList.length} Kişi doldurdu)</>

    if (sesGrupIsim)
        sesGrupIsim = ` (${sesGrupIsim})`;

    const altBilgi2 = `Hedef kitle: ${kadroIsim}${sesGrupIsim}`;
    const altBilgi3 = `Tarih: ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBaslangic, genelDurumHelper.translate)} - ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBitis, genelDurumHelper.translate)}`;

    function anketeDetayGit(anketId: string) {
        genelDurumHelper.navigate(mainScreenLocations.anketRaporDetay_func(anketId))
    }

    return (
        <Rows ml={20} mt={20} mr={20} pr={20} hasLightRightBorder>
            <Rows key={anket.id} style={{ width: "250px" }}>
                <CsmText style={{ alignSelf: "flex-start" }}><Link style={{ cursor: "pointer"}} onClick={() => anketeDetayGit(anket.id)}>{anket.baslik}</Link></CsmText>
                <Columns>
                    <CsmText style={{ alignSelf: "flex-start" }} note>
                        Durum:
                        {durumIcerik}
                    </CsmText>
                </Columns>
                <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</CsmText>
                <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</CsmText>
            </Rows>
        </Rows>
    );

}

export default AnketRaporDisplayer;