import DateTimeHelper from '../library/helpers/DateTimeHelper';
import { KadroModel } from '../models/businessModels/KadroModel';
import { GuidHelper } from '../library/helpers/GuidHelper';
import theme from '../app/theme';
import { ICurrentUserState } from '../features/currentUserSlice';
import { alpha } from '@mui/material';
import appColors from '../app/appColors';
import GenelDurumHelper from './GenelDurumHelper';
import { AnketModel } from '../models/businessModels/AnketModel';
import { CalismaModel } from '../models/businessModels/CalismaModel';
import { DuyuruModel } from '../models/businessModels/DuyuruModel';
import { HesapBilgiModel } from '../models/businessModels/HesapBilgiModel';
import { HesapBilgiVeKullaniciModel } from '../models/businessModels/HesapBilgiVeKullaniciModel';
import { HesapCalismaModel } from '../models/businessModels/HesapCalismaModel';
import { HesapSarkiSesKayitModel } from '../models/businessModels/HesapSarkiSesKayitModel';
import { RolModel } from '../models/businessModels/RolModel';
import { SarkiModel } from '../models/businessModels/SarkiModel';
import { SesGrupModel } from '../models/businessModels/SesGrupModel';
import { EnmKatilimBildirmeDurum } from '../models/enums/EnmKatilimBildirmeDurum';
import { EnmKatilimDurum } from '../models/enums/EnmKatilimDurum';
import { EnmKayitDurum } from '../models/enums/EnmKayitDurum';
import { EnmSesGrupTip } from '../models/enums/EnmSesGrupTip';
import { GenelDurumViewModel } from '../models/viewModels/GenelDurumViewModel';
import { KullaniciKoroViewModel } from '../models/viewModels/KullaniciKoroViewModel';
import { TatilModel } from '../models/businessModels/TatilModel';
import { EnmSarkiLinkTip } from '../models/enums/EnmSarkiLinkTip';
import { MetaSearchField } from './MetaSearchField';
import { SezonModel } from '../models/businessModels/SezonModel';
import { TarzModel } from '../models/businessModels/TarzModel';
import { SarkiZorlukModel } from '../models/businessModels/SarkiZorlukModel';
import { SecmeBasvuruModel } from '../models/businessModels/SecmeBasvuruModel';
import { EnmCinsiyet } from '../models/enums/EnmCinsiyet';
import StringHelper from '../library/helpers/StringHelper';
import { SarkiLinkModel } from '../models/businessModels/SarkiLinkModel';
import DateTimeToStringHelper from '../library/helpers/DateTimeToStringHelper';
import { AnketSoruModel } from '../models/businessModels/AnketSoruModel';
import { HesapAnketCevapModel } from '../models/businessModels/HesapAnketCevapModel';
import { ColorCalculationHelper } from '../library/helpers/ColorCalculationHelper';
import { AnketSoruKosulModel } from '../models/businessModels/AnketSoruKosulModel';
import { AltSezonModel } from '../models/businessModels/AltSezonModel';

const bilinmiyorText = "(Bilinmiyor)";
const emtpyTarz = new TarzModel();
emtpyTarz.id = GuidHelper.Empty;
emtpyTarz.isim = bilinmiyorText;

export const BusinessHelper = {
    Tablo: {
        CSVOlustur: (columnNames: string[], rows: string[][]) => {
            const dataLines = rows.map(columns => columns.join("\t"));
            const columnString = columnNames.join("\t");

            return [columnString, ...dataLines].join("\n");
        }
    },
    Konser: {
        KoristKonserdeSarkiyiSoylemisMi: (genelDurumHelper: GenelDurumHelper, hesap: HesapBilgiVeKullaniciModel, sarkiId: string): boolean => {
            const kadroIdList = hesap.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId);
            const sarkininKonserleri = genelDurumHelper.SeciliKoroModel.tumKonserler.where(e => e.konserSarkiModelList.any(t => t.sarkiModelId === sarkiId));

            return sarkininKonserleri.any(e => kadroIdList.contains(e.kadroModelId));
        }
    },
    SarkiMeta: {
        Filter: (originalList: SarkiModel[], fields: MetaSearchField[]): SarkiModel[] => {
            let result = originalList.slice();

            // filtreleme
            fields.forEach(field => {
                if (field.fieldType === "freeText" || field.fieldType === "sanatci") {
                    if (field.value !== undefined)
                        result = result.where(e => field.sarkiFieldValue(e).toLocaleLowerCase().includes(field.value!.toLocaleLowerCase()))
                }
                else if (field.fieldType === "itemsFromList") {
                    if (field.value !== undefined && (field.value?.length ?? 0) > 0) {
                        const containsBilinmiyor = field.value.contains(bilinmiyorText);
                        result = result.where(e => field.value!.contains(field.sarkiFieldValue(e)) || (containsBilinmiyor && field.sarkiFieldValue(e) === ""));
                    }
                }
                else if (field.fieldType === "trueFalse") {
                    if (field.value !== undefined) {
                        result = result.where(e => field.value! === field.sarkiFieldValue(e));
                    }
                }
                else if (field.fieldType === "stringList") {
                    if (field.value !== undefined && (field.value?.length ?? 0) > 0)
                        result = result.where(e => field.value!.intersection(field.sarkiFieldValue(e)).length > 0);
                }
                else if (field.fieldType === "number") {
                    if (field.value !== undefined) {
                        if (field.value.min && field.value.min !== 0)
                            result = result.where(e => field.sarkiFieldValue(e) >= field.value!.min)
                        if (field.value.max && field.value.max !== 0)
                            result = result.where(e => field.sarkiFieldValue(e) <= field.value!.max)
                    }
                }
                else if (field.fieldType === "SesGruplari") {
                    if (field.value !== undefined)
                        result = result.where(e => field.value!.intersection(field.sarkiFieldValue(e)).length > 0);
                }
                else if (field.fieldType === "Tarz") {
                    if (field.value !== undefined) {
                        const containsBilinmiyor = field.value.contains(GuidHelper.Empty);
                        result = result.where(e => field.value!.intersection(field.sarkiFieldValue(e)).length > 0 || (e.tarzModelIdList.length === 0 && containsBilinmiyor));
                    }
                }
                else if (field.fieldType === "sezon") {
                    if (field.value !== undefined && field.value.length > 0) {
                        result = result.where(e => field.value!.intersection(field.sarkiFieldValue(e)).length > 0);
                    }
                }

            });
            return result;
        },
        MetaSearchFields: (genelDurumHelper: GenelDurumHelper): MetaSearchField[] => [
            { isim: "Eser İsmi", sarkiFieldValue: e => e.isim, fieldType: "freeText", comparison: "contains" },
            {
                isim: "Sezon",
                sarkiFieldValue: e => e.sezonSarkiModelList.select(t => t.sezonModelId),
                fieldType: "sezon",
            },
            {
                isim: "Tarz",
                sarkiFieldValue: e => e.tarzModelIdList, fieldType: "Tarz",
                possibleValues: [
                    ...genelDurumHelper.SeciliKoroModel.tumTarzlar.orderByTurkish(e => e.isim),
                    emtpyTarz
                ]
            },
            { isim: "Süre (sny)", sarkiFieldValue: e => e.sure, fieldType: "number", comparison: "numberRange" },
            { isim: "Ölçü Sayısı", sarkiFieldValue: e => Number(e.olcuSayisi) ?? 0, fieldType: "number", comparison: "numberRange" },
            {
                isim: "Dil", sarkiFieldValue: e => StringHelper.nullToEmpty(e.dil),
                fieldType: "itemsFromList",
                possibleValues: [bilinmiyorText, ...genelDurumHelper.SeciliKoroModel.tumSarkilar.select(e => StringHelper.nullToEmpty(e.dil)).where(e => e !== "").distinct().orderByTurkish(e => e)]
            },
            {
                isim: "Dönem", sarkiFieldValue: e => StringHelper.nullToEmpty(e.donem),
                fieldType: "itemsFromList",
                possibleValues: [bilinmiyorText, ...genelDurumHelper.SeciliKoroModel.tumSarkilar.select(e => StringHelper.nullToEmpty(e.donem)).where(e => e !== "").distinct().orderByTurkish(e => e)]
            },
            // { isim: "Eser İsmi", sarkiFieldValue: e => e.zorluk, fieldType: "freeText", comparison: "contains" },
            // { isim: "Eser İsmi", sarkiFieldValue: e => e.ekYorum, fieldType: "freeText", comparison: "contains" },
            { isim: "Eşlik", sarkiFieldValue: e => StringHelper.nullToEmpty(e.eslik), fieldType: "freeText", comparison: "contains" },
            { isim: "Tempo", sarkiFieldValue: e => StringHelper.nullToEmpty(e.tempo), fieldType: "freeText", comparison: "contains" },
            { isim: "Söz Yazarı", sarkiFieldValue: e => StringHelper.nullToEmpty(e.sozYazari), fieldType: "sanatci", comparison: "equals" },
            { isim: "Besteci", sarkiFieldValue: e => StringHelper.nullToEmpty(e.besteci), fieldType: "sanatci", comparison: "equals" },
            { isim: "Düzenleme", sarkiFieldValue: e => StringHelper.nullToEmpty(e.duzenleme), fieldType: "sanatci", comparison: "equals" },
            { isim: "Partisyon Sayısı", sarkiFieldValue: e => e.sesGrupIdList.length, fieldType: "number", comparison: "numberRange" },
            {
                isim: "Ses Grupları",
                sarkiFieldValue: e => e.sesGrupIdList,
                fieldType: "SesGruplari",
                possibleValues: genelDurumHelper.SeciliKoroModel.tumSesGruplari
            },
            {
                isim: "PDF Var Mı",
                sarkiFieldValue: e => e.sarkiLinkModelList.any(t => t.linkTip === EnmSarkiLinkTip.PDF),
                fieldType: "trueFalse",
            },
            {
                isim: "Eski PDF Var Mı",
                sarkiFieldValue: e => e.sarkiLinkModelList.any(t => t.linkTip === EnmSarkiLinkTip['Eski PDF']),
                fieldType: "trueFalse",
            },
            {
                isim: "Daha önce Çalışılmış mı?",
                sarkiFieldValue: e => e.sezonSarkiModelList.length > 0,
                fieldType: "trueFalse",
            },
            {
                isim: "Zorluk Bilgileri Var Mı?",
                sarkiFieldValue: e => genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin && genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList.any(t => t.sarkiModelId === e.id),
                fieldType: "trueFalse",
            }

            // { isim: "Eser İsmi", sarkiFieldValue: e => e.kisaAciklama, fieldType: "freeText", comparison: "contains" },
            // { isim: "Eser İsmi", sarkiFieldValue: e => e.uzunAciklama, fieldType: "freeText", comparison: "contains" },
            // { isim: "Eser İsmi", sarkiFieldValue: e => e.kisaIngilizceAciklama, fieldType: "freeText", comparison: "contains" },
            // { isim: "Eser İsmi", sarkiFieldValue: e => e.uzunIngilizceAciklama, fieldType: "freeText", comparison: "contains" },
        ],
        MetaPairs: [
            { isim: "Aktif", field: (e: SarkiModel) => e.aktif ? "Aktif" : "Pasif" },
            { isim: "Tarz", field: (e: SarkiModel, g: GenelDurumHelper): string => BusinessHelper.Tarz.IdListtenIsimBirlestir(g.SeciliKoroModel.tumTarzlar, e.tarzModelIdList) },
            { isim: "Süre", field: (e: SarkiModel) => e.sure === 0 ? "" : DateTimeToStringHelper.convertSecondsToDurationString(e.sure) },
            { isim: "Tempo", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.tempo) },
            { isim: "Söz Yazarı", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.sozYazari) },
            { isim: "Besteci", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.besteci) },
            { isim: "Düzenleme", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.duzenleme) },
            { isim: "Kısa Açıklama", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.kisaAciklama) },
            { isim: "Uzun Açıklama", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.uzunAciklama) },
            { isim: "Kısa İngilizce Açıklama", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.kisaIngilizceAciklama) },
            { isim: "Uzun İngilizce Açıklama", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.uzunIngilizceAciklama) },
            { isim: "Kayıt Gönderme Sürecinde", field: (e: SarkiModel) => e.kayitGondermeSurecinde ? "Evet" : "Hayır" },
            { isim: "Ses Grupları", field: (e: SarkiModel, g: GenelDurumHelper): string => BusinessHelper.SesGrubu.IdListtenIsimBirlestir(g.SeciliKoroModel.tumSesGruplari, e.sesGrupIdList) },
            { isim: "Ses Grupları (Kısaltma)", field: (e: SarkiModel, g: GenelDurumHelper): string => BusinessHelper.SesGrubu.IdListtenSATBUret(g.SeciliKoroModel.tumSesGruplari, e.sesGrupIdList) },
            { isim: "Partisyon Sayısı", field: (e: SarkiModel) => e.sesGrupIdList.length.toString() },
            { isim: "Sıra", field: (e: SarkiModel) => e.sira <= 0 ? "" : e.sira.toFixed(0) },
            { isim: "Ölçü Sayısı", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.olcuSayisi) },
            { isim: "Dil", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.dil) },
            { isim: "Dönem", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.donem) },
            { isim: "Tarih", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.tarih) },
            { isim: "Eşlik", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.eslik) },
            { isim: "Ek Yorum", field: (e: SarkiModel) => StringHelper.nullToEmpty(e.ekYorum) },
            { isim: "Midi Tam Mı", field: (e: SarkiModel): string => BusinessHelper.SarkiMeta.midiTamMi(e) ? "Evet" : "Hayır" },
            { isim: "Örnek Ses Kaydı Tam Mı", field: (e: SarkiModel): string => BusinessHelper.SarkiMeta.sesKayitTamMi(e) ? "Evet" : "Hayır" },
            { isim: "Örnek Video Kaydı", field: (e: SarkiModel): string => e.sarkiLinkModelList.any(e => e.linkTip === EnmSarkiLinkTip['Başka Koro Videosu'] || e.linkTip === EnmSarkiLinkTip['Konser Videosu']) ? "Var" : "Yok" },
            { isim: "PDF", field: (e: SarkiModel): string => e.sarkiLinkModelList.any(e => e.linkTip === EnmSarkiLinkTip.PDF) ? "Var" : "Yok" },
            { isim: "Eski PDF", field: (e: SarkiModel): string => e.sarkiLinkModelList.any(e => e.linkTip === EnmSarkiLinkTip['Eski PDF']) ? "Var" : "Yok" },
            {
                isim: "Zorluk Öğrenme",
                field: (e: SarkiModel, g: GenelDurumHelper): string => {
                    const foundZorluk = g.SeciliKoroModel.sarkiZorlukModelList?.singleOrDefault(t => t.sarkiModelId === e.id);
                    if (!foundZorluk)
                        return "";
                    const myStatics = BusinessHelper.SarkiZorluk.StatOlustur(e, foundZorluk);
                    return (myStatics.ortalamaOgrenme === undefined) ? "-" : myStatics.ortalamaOgrenme.toFixed(1);
                }
            },
            {
                isim: "Zorluk Söyleme",
                field: (e: SarkiModel, g: GenelDurumHelper): string => {
                    const foundZorluk = g.SeciliKoroModel.sarkiZorlukModelList?.singleOrDefault(t => t.sarkiModelId === e.id);
                    if (!foundZorluk)
                        return "";
                    const myStatics = BusinessHelper.SarkiZorluk.StatOlustur(e, foundZorluk);
                    return (myStatics.ortalamaSoyleme === undefined) ? "-" : myStatics.ortalamaSoyleme.toFixed(1);
                }
            },
        ],
        midiTamMi: (sarki: SarkiModel): boolean => {
            if (sarki.sesGrupIdList.length === 0)
                return false;

            return sarki.sesGrupIdList.all(sesGrupId => sarki.sarkiLinkModelList.any(link => link.linkTip === EnmSarkiLinkTip["Midi Kaydı"] && link.sesGrupId === sesGrupId));
        },
        sesKayitTamMi: (sarki: SarkiModel): boolean => {
            if (sarki.sesGrupIdList.length === 0)
                return false;

            return sarki.sesGrupIdList.all(sesGrupId => sarki.sarkiLinkModelList.any(link => link.linkTip === EnmSarkiLinkTip["Ses Kaydı"] && link.sesGrupId === sesGrupId));
        }
    },
    Icerik: {
        SarkiLinktenPdfLinkGetir: (sarkiLinkModelList?: SarkiLinkModel[]): { pdfVariMi: boolean, eskiPdfMi: boolean, pdfIcerikId: string } => {
            const pdfIcerikId = sarkiLinkModelList?.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip.PDF)?.icerikId;
            const eskiIcerikId = sarkiLinkModelList?.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip['Eski PDF'])?.icerikId;

            if (sarkiLinkModelList == null || sarkiLinkModelList == undefined || (!pdfIcerikId && !eskiIcerikId))
                return { pdfVariMi: false, eskiPdfMi: false, pdfIcerikId: "" };

            return { pdfVariMi: true, eskiPdfMi: pdfIcerikId ? false : true, pdfIcerikId: (pdfIcerikId ?? eskiIcerikId) ?? "" }
        },
        LoginIcerikIdleriGetir: (korolar: KullaniciKoroViewModel[]): string[] => {
            const result: string[] = [];

            for (const koro of korolar) {
                addToArrayIfNotNull(result, koro.hesapBilgiModel.resimIcerikId);
                addToArrayIfNotNull(result, koro.hesapBilgiModel.resimIcerikId);
                addToArrayIfNotNull(result, koro.koroModel.resimIcerikId);
            }

            return result.where(e => !GuidHelper.IsEmpty(e)).distinct();
        },

        SesKayitIcerikleriGetir: (korolar: KullaniciKoroViewModel[]): string[] => {
            const result: string[] = [];

            for (const koro of korolar) {
                for (const calismaSesKayit of koro.hesapSarkiSesKayitModelList) {
                    addToArrayIfNotNull(result, calismaSesKayit.sesIcerikId);
                    addToArrayIfNotNull(result, calismaSesKayit.resimIcerikId);
                }
                if (koro.hesapSarkiSesKayitModelList)
                    for (const hesapSarkiSesKayit of koro.hesapSarkiSesKayitModelList) {
                        addToArrayIfNotNull(result, hesapSarkiSesKayit.resimIcerikId);
                        addToArrayIfNotNull(result, hesapSarkiSesKayit.resimIcerikId);
                    }

                if (koro.tumSarkilar)
                    for (const sarki of koro.tumSarkilar) {
                        for (const sarkiLink of sarki.sarkiLinkModelList)
                            addToArrayIfNotNull(result, sarkiLink.icerikId);
                    }
            }

            return result.where(e => !GuidHelper.IsEmpty(e)).distinct();
        },

        KonserAfisIcerikleriGetir: (korolar: KullaniciKoroViewModel[]): string[] => {
            const result: string[] = [];

            for (const koro of korolar) {
                for (const konser of koro.tumKonserler) {
                    addToArrayIfNotNull(result, konser.resimIcerikId);
                }
            }

            return result.where(e => !GuidHelper.IsEmpty(e)).distinct();
        },

        BasvuruIcerikleriGetir: (korolar: KullaniciKoroViewModel[]): string[] => {
            const result: string[] = [];

            for (const koro of korolar) {
                if (koro.tumSecmeBasvurular)
                    for (const basvuru of koro.tumSecmeBasvurular) {
                        addToArrayIfNotNull(result, basvuru.resimIcerikId);
                    }
            }

            return result.where(e => !GuidHelper.IsEmpty(e)).distinct();
        },

        KullanilanIcerikIdleriGetir: (korolar: KullaniciKoroViewModel[]): string[] => {
            const result: string[] = [];

            for (const koro of korolar) {
                addToArrayIfNotNull(result, koro.hesapBilgiModel.resimIcerikId);

                addToArrayIfNotNull(result, koro.koroModel.resimIcerikId);
                if (koro.hesapBilgiList)
                    for (const hesapBilgi of koro.hesapBilgiList)
                        addToArrayIfNotNull(result, hesapBilgi.resimIcerikId);

                if (koro.hesapSarkiSesKayitModelList)
                    for (const hesapSarkiSesKayit of koro.hesapSarkiSesKayitModelList) {
                        addToArrayIfNotNull(result, hesapSarkiSesKayit.resimIcerikId);
                        addToArrayIfNotNull(result, hesapSarkiSesKayit.resimIcerikId);
                    }

                if (koro.koroVeHesapModelList)
                    for (const koroVeHesapBilgi of koro.koroVeHesapModelList) {
                        addToArrayIfNotNull(result, koroVeHesapBilgi.koroModel.resimIcerikId);
                        addToArrayIfNotNull(result, koroVeHesapBilgi.hesapModel.resimIcerikId);
                    }

                if (koro.tumSarkilar)
                    for (const sarki of koro.tumSarkilar) {
                        for (const sarkiLink of sarki.sarkiLinkModelList)
                            addToArrayIfNotNull(result, sarkiLink.icerikId);
                    }
            }

            return result.where(e => !GuidHelper.IsEmpty(e)).distinct();
        },
    },
    Sezon: {
        AltSezonColorOrder: [
            "#FF0000",
            "#00FF00",
            "#0000FF",
            "#FFFF00",
            "#FF00FF",
            "#00FFFF",
        ],
        FindAltSezonById: (altSezonId: string, tumSezonlar: SezonModel[]): AltSezonModel | null => {
            const sezon = tumSezonlar.singleOrDefault(e => e.altSezonlar.any(t => t.id === altSezonId));

            if (sezon == null)
                return null;

            return sezon.altSezonlar.single(e => e.id == altSezonId);
        },
        GetAltSezonColor: (altSezonId: string, tumSezonlar: SezonModel[]) => {
            const sezon = tumSezonlar.singleOrDefault(e => e.altSezonlar.any(t => t.id === altSezonId));

            if (sezon == null)
                return appColors.PRIMARY;

            const orderNo = sezon.altSezonlar.select(e => e.id).indexOf(altSezonId);

            if (orderNo < 0)
                return appColors.PRIMARY;

            return BusinessHelper.Sezon.AltSezonColorOrder[orderNo % 6];
        },
    },
    Nullable: {
        NullableBoolToNumber: (value: boolean | null): number => {
            return value === null ? 1 : value === false ? 2 : 3;
        },
        NumberToNullableBool: (value: number | null) => {
            return (value === null || value === 1) ? null : value === 2 ? false : true;
        },
        NullableBoolValueList: [1, 2, 3] as number[],
        NullableBoolNumberValueSelector: (value: number | null, nullLabel: string, falseLabel: string, trueLabel: string) => {
            const convertedValue = BusinessHelper.Nullable.NumberToNullableBool(value);
            return convertedValue === null ? nullLabel : convertedValue === false ? falseLabel : trueLabel;
        }
    },
    Genel: {
        KadroVeSesGrupIdListHesabaUygunMu: (hesapBilgi: HesapBilgiModel, kadroIdList: string[], sesGrupIdList: string[], ekHesapIdList: string[]) => {
            if (ekHesapIdList.any(e => e === hesapBilgi.id))
                return true;
            if (sesGrupIdList.length > 0 && hesapBilgi.sesGrupIdList.intersection(sesGrupIdList).length === 0)
                return false;
            if (kadroIdList.length > 0)
                return hesapBilgi.kadroVeSesGrubuBilgiModelList.any(kadroVeSesGrup =>
                    // (sesGrupIdList.length === 0 || (sesGrupIdList.contains(kadroVeSesGrup.anaSesGrupModelId!))) &&
                    kadroIdList.contains(kadroVeSesGrup.kadroModelId)
                );

            return true;
        },
        KoristMi: (hesap: HesapBilgiModel) => {
            return hesap.sesGrupIdList.length > 0;
        },
        TatilModelinGunuMu: (tatilModel: TatilModel, date: Date) => {
            if (!tatilModel.sabitTarihTekrarEdiyorMu) {
                for (const tatilTarih of tatilModel.tarihler)
                    if (DateTimeHelper.isDateInBetween(date, tatilTarih.tarihBaslangic, tatilTarih.tarihBitis))
                        return true;
                return false;
            }
            else {
                const tatilTarih = tatilModel.tarihler.first();
                return DateTimeHelper.isDateInBetween(DateTimeHelper.createDate(tatilTarih.tarihBaslangic.getFullYear(), date.getMonth() + 1, date.getDate()), tatilTarih.tarihBaslangic, tatilTarih.tarihBitis);
            }
        },
        TatilIsimGetir: (tatilList: TatilModel[], date: Date) => {
            const tatiller = tatilList.where(e => BusinessHelper.Genel.TatilModelinGunuMu(e, date));

            if (tatiller.length > 0) {
                const result = tatiller.select(e => e.isim).join(", ");
                return result;
            }

            return "";
        },
        HedefKitleTextGetir: (kadroIdList: string[], sesGrupIdList: string[], tumKadrolar: KadroModel[], tumSesGruplari: SesGrupModel[]) => {
            let result = BusinessHelper.Genel.KadrolariGetir(kadroIdList, tumKadrolar).select(e => e.isim).join(", ");
            if (sesGrupIdList.length > 0)
                result = result + " (" + BusinessHelper.Genel.SesGruplariniGetir(sesGrupIdList, tumSesGruplari).select(e => e.isim).join(", ") + ")";
            return result;
        },

        KadrolariGetir: (kadroIdList: string[], tumKadrolar: KadroModel[]) => {
            return tumKadrolar.where(e => kadroIdList.contains(e.id));
        },

        SesGruplariniGetir: (sesGrupIdList: string[], tumSesGruplari: SesGrupModel[]) => {
            return tumSesGruplari.where(e => sesGrupIdList.contains(e.id));
        },

        SeciliKoroGetir: (genelDurum: GenelDurumViewModel, user: ICurrentUserState): (KullaniciKoroViewModel | null) => {
            if (!user.token || genelDurum == null)
                return null;

            return genelDurum.korolar.single(e => e.koroModel.id === user.seciliKoroId);
        },
        KoristinKatildigiSezonlariGetir: (genelDurumHelper: GenelDurumHelper) => {
            const bildirimYapilanCalismaIdler = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.select(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.calismaModelId).distinct();
            const sezonIdList = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => bildirimYapilanCalismaIdler.contains(e.id)).select(e => e.sezonModelId).distinct();
            return genelDurumHelper.SeciliKoroModel.tumSezonlar.where(e => sezonIdList.contains(e.id)).orderByTurkish(e => e.isim);
        },
    },
    SesKayit: {
        KayitDurumRenkGetir: (kayitDurum: EnmKayitDurum, alphaLevel?: number) => {
            const paletteColor = BusinessHelper.SesKayit.KayitDurumPaletteRenkGetir(kayitDurum);
            if (!paletteColor)
                return undefined;

            return alpha(paletteColor.main, alphaLevel ?? 1);
        },
        KayitDurumPaletteRenkGetir: (kayitDurum: EnmKayitDurum) => {
            if (kayitDurum === EnmKayitDurum['Yorum Bekleniyor'])
                return theme.palette.warning;
            else if (kayitDurum === EnmKayitDurum['Hatalı'])
                return theme.palette.error;
            else if (kayitDurum === EnmKayitDurum['Onaylandı'])
                return theme.palette.success;
            else if (kayitDurum === EnmKayitDurum['Onay Bekleniyor'])
                return null;
        },
        HesabinSarkiIcinYapmasiGerekenKayitVarmi: (kayitlar: HesapSarkiSesKayitModel[]) => {
            if (kayitlar.length === 0)
                return true;

            const sesGruplariSonDurumlar = BusinessHelper.SesKayit.HesabinSarkiSesGrupKayitSonDurumlariniGetir(kayitlar)

            if (sesGruplariSonDurumlar.any(e => e.sonDurum === EnmKayitDurum.Hatalı))
                return true;
            else
                return false;
        },
        HesabinYorumBekleyenKayitSayisiniGetirForYonetim: (hesapId: string, geneldurumHeler: GenelDurumHelper) => {
            const aktifSarkilar = geneldurumHeler.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === geneldurumHeler.AktifSezon.id) && e.kayitGondermeSurecinde);
            const kayitlar = geneldurumHeler.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === hesapId);


            return aktifSarkilar.where(sarki => BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar.where(e => e.sarkiModelId === sarki.id)) === EnmKayitDurum['Yorum Bekleniyor']).length;
        },
        HesabinGonderilmisSarkiSayisiniGetirForYonetim: (hesapId: string, geneldurumHeler: GenelDurumHelper) => {
            const aktifSarkilar = geneldurumHeler.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === geneldurumHeler.AktifSezon.id) && e.kayitGondermeSurecinde);
            const kayitlar = geneldurumHeler.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === hesapId);
            return aktifSarkilar.where(sarki => {
                const sonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar.where(e => e.sarkiModelId === sarki.id));
                return (sonDurum === EnmKayitDurum.Onaylandı) || (sonDurum === EnmKayitDurum.Hatalı);
            }).length;
        },
        HesabinSarkiSesGrupKayitSonDurumlariniGetir: (kayitlar: HesapSarkiSesKayitModel[]): { sesGrupId: string, sonDurum: EnmKayitDurum | null }[] => {
            const sesGrupIdList: string[] = kayitlar.select(e => e.sesGrupId).distinct();

            return sesGrupIdList.select(sesGrupId => { return { sesGrupId: sesGrupId, sonDurum: BusinessHelper.SesKayit.HesabinSarkiKayitSesGrupDurumunuGetir(kayitlar, sesGrupId) } });
        },
        HesabinSarkiEkransalKayitSonDurumunuGetir: (kayitlar: HesapSarkiSesKayitModel[]): EnmKayitDurum | null => {
            const sesGruplariSonDurumlar = BusinessHelper.SesKayit.HesabinSarkiSesGrupKayitSonDurumlariniGetir(kayitlar)

            if (sesGruplariSonDurumlar.length === 0)
                return null;
            else if (sesGruplariSonDurumlar.any(e => e.sonDurum === EnmKayitDurum["Yorum Bekleniyor"]))
                return EnmKayitDurum["Yorum Bekleniyor"];
            else if (sesGruplariSonDurumlar.all(e => e.sonDurum === EnmKayitDurum.Onaylandı))
                return EnmKayitDurum.Onaylandı;
            else if (sesGruplariSonDurumlar.any(e => e.sonDurum === EnmKayitDurum["Onay Bekleniyor"]))
                return EnmKayitDurum["Onay Bekleniyor"]
            else
                return EnmKayitDurum.Hatalı;
        },

        SarkininBelirliKayitDurumunaSahipHesaplariniGetir: (sarki: SarkiModel, kayitDurum: EnmKayitDurum, kullanicilar: HesapBilgiModel[], kayitlar: HesapSarkiSesKayitModel[]): HesapBilgiModel[] => {
            return kullanicilar.where(e => BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar.where(t => t.sarkiModelId === sarki.id && t.hesapModelId === e.id)) === kayitDurum);
        },

        HesabinSarkiKayitSesGrupDurumunuGetir: (kayitlar: HesapSarkiSesKayitModel[], sesGrupId: string | null): EnmKayitDurum | null => {
            kayitlar = kayitlar.where(e => e.sesGrupId === sesGrupId);

            if (kayitlar.length === 0)
                return null;
            else if (kayitlar.any(e => e.kayitDurum === EnmKayitDurum.Onaylandı))
                return EnmKayitDurum.Onaylandı;
            else if (kayitlar.any(e => e.kayitDurum === EnmKayitDurum["Yorum Bekleniyor"]))
                return EnmKayitDurum["Yorum Bekleniyor"];
            else if (kayitlar.any(e => e.kayitDurum === EnmKayitDurum["Onay Bekleniyor"]))
                return EnmKayitDurum["Onay Bekleniyor"]
            else
                return EnmKayitDurum.Hatalı;
        },

        KayitBeklenenSarkilariGetir: (sarkiList: SarkiModel[], aktifSezonId: string) => {
            return sarkiList.where(e =>
                e.sezonSarkiModelList.any(t => t.sezonModelId === aktifSezonId) &&
                e.kayitGondermeSurecinde === true);
        },
        KisininKayitYuklenemezSesGruplariniGetir: (genelDurumHelper: GenelDurumHelper, kisininKayitlari: HesapSarkiSesKayitModel[]) => {
            const idler = kisininKayitlari.where(e => e.kayitDurum === EnmKayitDurum.Onaylandı || e.kayitDurum === EnmKayitDurum["Yorum Bekleniyor"]).select(e => e.sesGrupId).distinct();
            return genelDurumHelper.SesGruplariniGetir(idler).orderBy(e => e.sira);
        }
    },
    Calisma: {
        KatilimDurumRenkYaziGetir: (calisma: CalismaModel, hesapCalismaList: HesapCalismaModel[], hesapBilgi: HesapBilgiModel, yoklamaArabirimiKullanilsin: boolean): { renk: import("csstype").Property.Background<string> | undefined, yazi: string, aksiyonLazim: boolean } => {
            if (!yoklamaArabirimiKullanilsin)
                return { renk: undefined, yazi: "", aksiyonLazim: false };

            const hesapCalisma = hesapCalismaList.singleOrDefault(e => e.hesapModelId === hesapBilgi.id && e.calismaModelId === calisma.id);

            if (calisma.tarihBitis < DateTimeHelper.today()) {
                if (hesapCalisma == null || hesapCalisma.derseGercekKatilimDurum === null) {
                    // if (BusinessHelper.Calisma.GelecekCalismaKisiyeHedefliMi(calisma, hesapBilgi))
                    //     return { renk: appColors.LIGHTRED, yazi: "Çalışmaya katılmadınız", aksiyonLazim: false };
                    // else
                    return { renk: undefined, yazi: "", aksiyonLazim: false }
                }
                else if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
                    return { renk: appColors.LIGHTGREEN, yazi: "Çalışmaya katıldınız", aksiyonLazim: false };
                else if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum['Geç Katıldı'])
                    return { renk: appColors.LIGHTYELLOW, yazi: "Çalışmaya geç katıldınız", aksiyonLazim: false };
                else
                    return { renk: appColors.LIGHTRED, yazi: "Çalışmaya katılmadınız", aksiyonLazim: false };
            }
            else {
                if (hesapCalisma == null || hesapCalisma.katilimDurum == null) {
                    if (BusinessHelper.Calisma.GelecekCalismaKisiyeHedefliMi(calisma, hesapBilgi))
                        return { renk: appColors.RED, yazi: "Çalışmaya katılma bildirimi yapmadınız", aksiyonLazim: true };
                    else
                        return { renk: undefined, yazi: "", aksiyonLazim: false }
                }
                else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Belirsiz)
                    return { renk: appColors.YELLOW, yazi: "Çalışmaya katılımınızın belirsiz olduğu bildirdiniz", aksiyonLazim: false };
                else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum)
                    return { renk: appColors.YELLOW, yazi: "Çalışmaya katılmayacağınızı bildirdiniz", aksiyonLazim: false };
                else
                    return { renk: appColors.GREEN, yazi: "Çalışmaya katılacağınızı bildirdiniz.", aksiyonLazim: false };
            }
        },
        KisiyeUygunCalismalariGetir: (tumCalismalar: CalismaModel[], hesapBilgi: HesapBilgiModel, hesapCalismaList: HesapCalismaModel[], ignoreRol: boolean) => {
            hesapCalismaList = hesapCalismaList.where(e => e.hesapModelId === hesapBilgi.id);

            if (ignoreRol === false) {
                return tumCalismalar.where(calisma => BusinessHelper.Calisma.CalismaKisiyeUygunMu(calisma, hesapBilgi, hesapCalismaList));
            }

            return tumCalismalar;
        },
        CalismaKisiyeUygunMu: (calisma: CalismaModel, hesapBilgi: HesapBilgiModel, verilenYoklamalar: HesapCalismaModel[]) => {
            // verilenYoklamalar = verilenYoklamalar.where(e => e.hesapModelId === hesapBilgi.id);

            // verilen yoklama varsa, uygunmuş (belki de o zamanlar)
            if (calisma.kadroIdList.length === 0)
                return false;
            if (verilenYoklamalar.any(t => t.calismaModelId === calisma.id))
                return true;
            return BusinessHelper.Genel.KadroVeSesGrupIdListHesabaUygunMu(hesapBilgi, calisma.kadroIdList, calisma.sesGrupIdList, []);
        },
        GelecekCalismaKisiyeHedefliMi: (calisma: CalismaModel, hesapBilgi: HesapBilgiModel) => {
            return BusinessHelper.Calisma.CalismaKisiyeUygunMu(calisma, hesapBilgi, []);
        },
        CalismayaUygunKisileriGetir: (calisma: CalismaModel, hesapList: HesapBilgiVeKullaniciModel[], eskileriGoster: boolean): HesapBilgiVeKullaniciModel[] => {
            return hesapList.where(hesap => {
                if (BusinessHelper.Genel.KoristMi(hesap) == false)
                    return false;

                const kadroUygun = BusinessHelper.Calisma.CalismaKisiyeUygunMu(calisma, hesap, []);
                const aktfiVeAraVermedi = hesap.aktif && !hesap.araVerdi;

                return eskileriGoster ? (kadroUygun || aktfiVeAraVermedi) : (kadroUygun && aktfiVeAraVermedi);
            })
        },
        HesabinKayitCalismaBildirimSayisiniGetir: (genelDurumHelper: GenelDurumHelper) => {
            const hesapCalismalar = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);
            const ileriCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.tarihBaslangic > DateTimeHelper.today());
            const alakaliCalismalar = ileriCalismalar.where(e => BusinessHelper.Calisma.GelecekCalismaKisiyeHedefliMi(e, genelDurumHelper.SeciliHesapBilgiModel));

            return alakaliCalismalar.where(calisma => hesapCalismalar.singleOrDefault(e => e.calismaModelId === calisma.id)?.katilimDurum == null).length;
        }
    },
    Anket: {
        // KosulIcinUygunSorulariGetir: (kosulTip: EnmKosulTip | null, anket: AnketModel, kosulaBagliOlacakSoru: AnketSoruModel): AnketSoruModel[] => {
        //     if (!kosulTip)
        //         return [];

        //     if (kosulTip === EnmKosulTip['Seçili cevap'] || kosulTip === EnmKosulTip['Seçili dışında cevap'])
        //         return anket.soruModelList.where(e => e.sira < kosulaBagliOlacakSoru.sira && e.soruTipi === EnmSoruTipi['Tek Seçmeli']);
        //     else
        //         return anket.soruModelList.where(e => e.sira < kosulaBagliOlacakSoru.sira);
        // },
        SoruUygunMu: (hesaplananSoru: AnketSoruModel, verilenTumCevaplar: HesapAnketCevapModel[], tumSorular: AnketSoruModel[]) => {
            return BusinessHelper.Anket.BolumUygunMu(hesaplananSoru.bolumNo, verilenTumCevaplar, tumSorular);
        },

        BolumUygunMu: (bolumNo: number, verilenTumCevaplar: HesapAnketCevapModel[], tumSorular: AnketSoruModel[]) => {
            if (bolumNo == 1)
                return true;

            // bu bölüme gidilmesini sağlayan bir koşul varsa: koşul tutuyorsa true dönsün
            const bolumeHedefliSorularVeKosullar = tumSorular
                .selectMany(e => e.kosulModelList.select(t => ({
                    soru: e,
                    kosul: t
                })))
                .where(e => e.kosul.hedefBolum === bolumNo);

            if (bolumeHedefliSorularVeKosullar.any(e => BusinessHelper.Anket.KosulTutuyorMu(e.kosul, e.soru, verilenTumCevaplar) && BusinessHelper.Anket.BolumUygunMu(e.soru.bolumNo, verilenTumCevaplar, tumSorular)))
                return true;

            // bir evvelki bölüm uygunsa ve bölümün sonunda koşul yoksa, true dönsün
            if (BusinessHelper.Anket.BolumUygunMu(bolumNo - 1, verilenTumCevaplar, tumSorular)) {
                const evvelkiBolumunDevamKosullari = BusinessHelper.Anket.BolumunKosullariniGetir(bolumNo - 1, tumSorular);
                if (evvelkiBolumunDevamKosullari.length == 0)
                    return true;

                // bir evvelki bölümde kosullanmamis secenek varsa ve onlardan biri secildiyse, true dönsün
                // const evvelkiBolumunSorusu = BusinessHelper.Anket.BolumunSonSorusunuGetir(bolumNo, tumSorular);
                // const evvelkiBolumunSorunaVerilenCevap = verilenTumCevaplar.singleOrDefault(e => e.anketSoruModelId == evvelkiBolumunSorusu.id);

                // const kosulluSecenekIdler = evvelkiBolumunDevamKosullari.select(e => e.secenekModelId);
                // if (evvelkiBolumunSorunaVerilenCevap != null && kosulluSecenekIdler.contains(evvelkiBolumunSorunaVerilenCevap.anketSoruSecenekId) == false) {
                //     console.log("Bölüm " + bolumNo.toFixed() + " için 3. tür true oldu");
                //     return true;
                // }
            }

            return false;
        },
        BolumunSonSorusunuGetir: (bolumNo: number, tumSorular: AnketSoruModel[]) => {
            return tumSorular.where(e => e.bolumNo == bolumNo).orderBy(e => e.sira).first();
        },
        BolumunKosullariniGetir: (bolumNo: number, tumSorular: AnketSoruModel[]) => {
            const evvelkiBolumunSonSorusu = tumSorular.where(e => e.bolumNo === bolumNo).orderBy(e => e.sira).first()
            return evvelkiBolumunSonSorusu.kosulModelList;
        },

        KosulTutuyorMu: (kosul: AnketSoruKosulModel, kosulunSorusu: AnketSoruModel, verilenTumCevaplar: HesapAnketCevapModel[]) => {
            if (StringHelper.isEmpty(kosul.secenekModelId))
                return true;

            return verilenTumCevaplar.any(e => e.anketSoruModelId == kosulunSorusu.id && (StringHelper.isEmpty(kosul.secenekModelId) || e.anketSoruSecenekId == kosul.secenekModelId));
        },










        // SoruSorulacakMi: (soru: AnketSoruModel, cevapList: HesapAnketCevapModel[], anket: AnketModel, genelDurumHelper: GenelDurumHelper): boolean => {
        //     const kosulModel = soru.kosulModel;
        //     if (kosulModel === null)
        //         return true;

        //     const kosulSoru = anket.soruModelList.single(e => e.id === kosulModel.kosulSoruModelId);

        //     if (kosulModel.kosulTip === EnmKosulTip["Sorunun Dahil olması"])
        //         return BusinessHelper.Anket.SoruSorulacakMi(kosulSoru, cevapList, anket, genelDurumHelper);

        //     if (kosulModel.kosulTip === EnmKosulTip["Sorunun hariç olması"])
        //         return BusinessHelper.Anket.SoruSorulacakMi(kosulSoru, cevapList, anket, genelDurumHelper) === false;

        //     const kosulSoruyaVerilenCevaplar = cevapList.where(e => e.anketSoruModelId === kosulSoru.id);

        //     // koşul soruya cevap verilmediyse, bu soru henüz görünmesin
        //     if (kosulSoruyaVerilenCevaplar.length === 0)
        //         return false;

        //     if (kosulModel.kosulTip === EnmKosulTip["Seçili cevap"])
        //         return kosulSoruyaVerilenCevaplar.any(e => e.anketSoruSecenekId === kosulModel.kosulSecenekModelId);

        //     if (kosulModel.kosulTip === EnmKosulTip["Seçili dışında cevap"])
        //         return kosulSoruyaVerilenCevaplar.all(e => e.anketSoruSecenekId !== kosulModel.kosulSecenekModelId);

        //     throw new Error("İşlenmemiş koşul tipi: " + EnmKosulTip[kosulModel.kosulTip]);
        // },
        KisiyeUygunAnketleriGetir: (tumAnketler: AnketModel[], hesapBilgi: HesapBilgiModel, ignoreRol: boolean) => {
            let result = tumAnketler.where(e =>
                e.aktif &&
                e.tarihBaslangic <= DateTimeHelper.today() &&
                e.tarihBitis >= DateTimeHelper.today());

            if (ignoreRol === false)
                result = result.where(e => BusinessHelper.Genel.KadroVeSesGrupIdListHesabaUygunMu(hesapBilgi, e.kadroIdList, e.sesGrupIdList, e.ekHesapIdList));

            return result;
        },
        AnketeUygunKisiIdleriGetir: (anket: AnketModel, genelDurumHelper: GenelDurumHelper) => {
            const tumAktifHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif);

            return tumAktifHesaplar.where(e => BusinessHelper.Genel.KadroVeSesGrupIdListHesabaUygunMu(e, anket.kadroIdList, anket.sesGrupIdList, anket.ekHesapIdList)).select(e => e.id);
        },
    },
    Duyuru: {
        KisiyeUygunDuyurulariGetir: (duyuruList: DuyuruModel[], hesapBilgi: HesapBilgiModel, ignoreRol: boolean) => {
            const today = DateTimeHelper.today();

            let result = duyuruList.where(e =>
                e.tarihBaslangic <= today &&
                e.tarihBitis >= today);


            if (ignoreRol === false)
                result = result.where(e => BusinessHelper.Genel.KadroVeSesGrupIdListHesabaUygunMu(hesapBilgi, e.kadroIdList, e.sesGrupIdList, []));

            return result;
        },
    },
    SesGrubu: {
        AltSesGrubununListesiniGetir: (altSesGrupId: string, tumSesGruplari: SesGrupModel[]) => {
            return [
                altSesGrupId,
                BusinessHelper.SesGrubu.AltSesGrubununAnaSesGrupIdGetir(altSesGrupId, tumSesGruplari),
                BusinessHelper.SesGrubu.AltSesGrubununUstSesGrupIdGetir(altSesGrupId, tumSesGruplari)]
        },
        KadroyaGoreSesGruplariniGetir: (kadroId: string, geneldurumHeler: GenelDurumHelper) => (hesap: HesapBilgiModel) => {
            const kadrodakiAltSesGrubu = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === kadroId)?.altSesGrupModelId ?? null;
            if (kadrodakiAltSesGrubu === null)
                return hesap.sesGrupIdList;
            else
                return BusinessHelper.SesGrubu.AltSesGrubununListesiniGetir(kadrodakiAltSesGrubu, geneldurumHeler.SeciliKoroModel.tumSesGruplari);
        },
        AltSesGrubununUstSesGrupIdGetir: (altSesGrupId: string, tumSesGruplari: SesGrupModel[]) => {
            const altSesGrup = tumSesGruplari.single(e => e.id === altSesGrupId);
            return tumSesGruplari.where(e => e.sira < altSesGrup.sira && e.sesGrupTip === EnmSesGrupTip['Üst Ses Grubu']).orderBy(e => e.sira).last().id
        },
        AltSesGrubununAnaSesGrupIdGetir: (altSesGrupId: string, tumSesGruplari: SesGrupModel[]) => {
            const altSesGrup = tumSesGruplari.single(e => e.id === altSesGrupId);
            return tumSesGruplari.where(e => e.sira < altSesGrup.sira && e.sesGrupTip === EnmSesGrupTip['Ana Ses Grubu']).orderBy(e => e.sira).last().id
        },
        DiviziSesGrubununAltSesGrubunuGetir: (diviziSesGrupId: string, tumSesGruplari: SesGrupModel[]) => {
            const diviziSesGrup = tumSesGruplari.single(e => e.id === diviziSesGrupId);
            return tumSesGruplari.where(e => e.sira < diviziSesGrup.sira && e.sesGrupTip === EnmSesGrupTip['Alt Ses Grubu']).orderBy(e => e.sira).last();
        },
        DiviziSesGrubununAltSesGrupIdGetir: (diviziSesGrupId: string, tumSesGruplari: SesGrupModel[]) => {
            return BusinessHelper.SesGrubu.DiviziSesGrubununAltSesGrubunuGetir(diviziSesGrupId, tumSesGruplari).id;
        },
        HesabinAltSesGrubunuGetir: (hesabinSesGruplari: SesGrupModel[]) => {
            let sesGrubu = hesabinSesGruplari.singleOrDefault(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"]);
            if (!sesGrubu)
                sesGrubu = hesabinSesGruplari.singleOrDefault(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]);
            if (!sesGrubu)
                sesGrubu = hesabinSesGruplari.firstOrDefault();

            return sesGrubu;
        },

        HesabinAnaSesGrubunuGetir: (hesabinSesGruplari: SesGrupModel[]) => {
            let sesGrubu: SesGrupModel | null = hesabinSesGruplari.singleOrDefault(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"])
            if (!sesGrubu)
                sesGrubu = hesabinSesGruplari.firstOrDefault()

            return sesGrubu;
        },
        IdListtenIsimBirlestir: (tumSesGruplari: SesGrupModel[], sesGrupIdList: string[]): string => {

            const list = tumSesGruplari.where(e => sesGrupIdList.contains(e.id)).orderBy(e => e.sira).select(e => e.isim);
            return list.join(", ");
        },
        IdListtenSATBUret: (tumSesGruplari: SesGrupModel[], sesGrupIdList: string[]): string => {
            const list = tumSesGruplari.where(e => sesGrupIdList.contains(e.id));

            return list
                .where(e => e.sesGrupTip !== EnmSesGrupTip["Diğer"]).select(e => e.isim.substring(0, 1))
                .orderBy(e => e === "S" ? 0 : e === "A" ? 1 : e === "T" ? 2 : 3).join("")
        },
        SesGrupTipSortFunc: (tip: EnmSesGrupTip) => {
            if (tip === EnmSesGrupTip["Ana Ses Grubu"])
                return 1;
            else if (tip === EnmSesGrupTip["Alt Ses Grubu"])
                return 0;
            else if (tip === EnmSesGrupTip["Alt Divizi Ses Grubu"])
                return 2;
            else if (tip === EnmSesGrupTip["Üst Ses Grubu"])
                return 3;
            else
                return 4;
        }

    },
    Kadro: {
        GetKadroColor: (kadroId: string, sezonKadrolari: KadroModel[]) => {
            const kadro = sezonKadrolari.single(e => e.id === kadroId);

            const orderNo = sezonKadrolari.indexOf(kadro);

            ColorCalculationHelper
            if (orderNo < 0)
                return appColors.PRIMARY;

            return ColorCalculationHelper.ChartPalettes.colors_Bright[orderNo % (ColorCalculationHelper.ChartPalettes.colors_Bright.length)];
        },
        CalismaKadroyaDahilMi: (calisma: CalismaModel, kadroId: string) => {
            return calisma.kadroIdList.contains(kadroId);
        },
        HesapKadroyaDahilMi: (hesap: HesapBilgiModel, kadroId: string) => {
            return hesap.kadroVeSesGrubuBilgiModelList.any(e => e.kadroModelId === kadroId);
        },
        SortFunc: (kadro: KadroModel) => kadro.aktif ? kadro.isim : `_${kadro.isim}`,
        IdListtenIsimBirlestir: (tumKadrolar: KadroModel[], kadroIdList: string[], showInactive: "Full" | "None" | "AsPlus"): string => {
            const list = BusinessHelper.Kadro.IdListtenItemListGetir(tumKadrolar, kadroIdList);
            const normalList = list.where(e => e.aktif);
            const ekList = list.where(e => !e.aktif);

            if (ekList.length === 0)
                return normalList.select(e => e.isim).join(", ");
            else {
                if (showInactive === "AsPlus")
                    return `${normalList.select(e => e.isim).join(", ")} (+${ekList.length} kadro)`;
                else if (showInactive === "Full")
                    return list.select(e => e.isim).join(", ");
                else
                    return normalList.select(e => e.isim).join(", ");
            }
        },
        IdListtenItemListGetir: (tumKadrolar: KadroModel[], kadroIdList: string[]): KadroModel[] => {
            return tumKadrolar.where(e => kadroIdList.contains(e.id)).orderBy(BusinessHelper.Kadro.SortFunc);
        },
    },
    Rol: {
        IdListtenIsimBirlestir: (tumRoller: RolModel[], kadroIdList: string[]): string => {
            const list = tumRoller.where(e => kadroIdList.contains(e.id)).select(e => e.isim);
            return list.join(", ");
        },
    },
    Tarz: {
        RenkGetir: (tarzId: string, genelDurumHelper: GenelDurumHelper) => {
            const tarz = genelDurumHelper.SeciliKoroModel.tumTarzlar.single(e => e.id === tarzId);
            const index = genelDurumHelper.SeciliKoroModel.tumTarzlar.indexOf(tarz);
            return alpha(ColorCalculationHelper.ChartPalettes.colors_Bright[index], 0.5);
        },
        IdListtenIsimBirlestir: (tumTarzlar: TarzModel[], tarzIdList: string[]): string => {
            const list = tumTarzlar.where(e => tarzIdList.contains(e.id)).select(e => e.isim);
            return list.join(", ");
        },
    },
    Nota: {
        MinNota: 0,
        MinSari: 19,
        MaxSari: 44,
        maxNota: 71,
        MaxNota: 71,
        GetColorAtPoint: (value: number | null, cinsiyet: EnmCinsiyet) => {
            if (value === null || value === -1) return appColors.GRAY_LIGHT2;

            const valueToTest = (cinsiyet === EnmCinsiyet.Erkek ? value! : value! - 12);
            if (valueToTest < BusinessHelper.Nota.MinSari)
                return "rgba(20,255,20,1)";
            else if (valueToTest < BusinessHelper.Nota.MaxSari)
                return "rgba(255,255,20,1)";
            else
                return "rgba(255,20,20,1)";
        },
        GetNotaPercent: (nota: number) => (100 * (nota - BusinessHelper.Nota.MinNota) / (BusinessHelper.Nota.MaxNota - BusinessHelper.Nota.MinNota)).toFixed(0),
        KadinGradient: () => {
            const sariMinPercent = BusinessHelper.Nota.GetNotaPercent(BusinessHelper.Nota.MinSari + 12);
            const sariMaxPercent = BusinessHelper.Nota.GetNotaPercent(BusinessHelper.Nota.MaxSari + 12);
            return `linear-gradient(90deg, rgba(20,255,20,1) 0%, rgba(20,255,20,1) ${sariMinPercent}%, rgba(255,255,20,1) ${sariMinPercent}%, rgba(255,255,20,1) ${sariMaxPercent}%, rgba(255,20,20,1) ${sariMaxPercent}%, rgba(255,20,20,1) 100%)`;
        },
        ErkekGradient: () => {
            const sariMinPercent = BusinessHelper.Nota.GetNotaPercent(BusinessHelper.Nota.MinSari);
            const sariMaxPercent = BusinessHelper.Nota.GetNotaPercent(BusinessHelper.Nota.MaxSari);
            return `linear-gradient(90deg, rgba(20,255,20,1) 0%, rgba(20,255,20,1) ${sariMinPercent}%, rgba(255,255,20,1) ${sariMinPercent}%, rgba(255,255,20,1) ${sariMaxPercent}%, rgba(255,20,20,1) ${sariMaxPercent}%, rgba(255,20,20,1) 100%)`;
        },
        NotaOktavGetir: (notaIndex: number | null) => {
            if (notaIndex === null) return null;
            return Math.floor((notaIndex) / 12) + 1;
        },
        ZorlukAciklamaGetir: (notaIndex: number | null, erkekMi: boolean) => {
            if (notaIndex === null) return "-";

            const notaToTest = erkekMi ? notaIndex : notaIndex - 12;

            if (notaToTest < BusinessHelper.Nota.MinSari - 12)
                return "Aşırı Kalın";
            else if (notaToTest < BusinessHelper.Nota.MinSari)
                return "Kalın";
            else if (notaToTest < BusinessHelper.Nota.MaxSari)
                return "Normal";
            else if (notaToTest < BusinessHelper.Nota.MaxSari + 12)
                return "İnce";
            else
                return "Aşırı İnce";
        },
        InceKalinNotaIsimVeOktavGetir: (notaIndexKalin: number | null | undefined, notaIndexInce: number | null | undefined) => {
            if (notaIndexKalin === null || notaIndexKalin === undefined || notaIndexInce === null || notaIndexInce === undefined)
                return "-";
            if (notaIndexKalin === BusinessHelper.Nota.MinNota || notaIndexInce === BusinessHelper.Nota.MinNota)
                return "-";

            return `${BusinessHelper.Nota.NotaIsimGetir(notaIndexKalin)}${BusinessHelper.Nota.NotaOktavGetir(notaIndexKalin)}/${BusinessHelper.Nota.NotaIsimGetir(notaIndexInce)}${BusinessHelper.Nota.NotaOktavGetir(notaIndexInce)}`;
        },
        OktavSayiFarkGetir: (notaIndexKalin: number | null | undefined, notaIndexInce: number | null | undefined) => {
            if (notaIndexKalin === null || notaIndexKalin === undefined || notaIndexInce === null || notaIndexInce === undefined)
                return "?";
            if (notaIndexKalin === BusinessHelper.Nota.MinNota || notaIndexInce === BusinessHelper.Nota.MinNota)
                return "?";

            return ((notaIndexInce - notaIndexKalin) / 12).toFixed(1);
        },
        NotaIsimGetir: (notaIndex: number | null | undefined) => {
            if (notaIndex === null || notaIndex === undefined) return "-";

            const nota = notaIndex % 12;

            switch (nota) {
                case 0: return "Do";
                case 1: return "Do#";
                case 2: return "Re";
                case 3: return "Re#";
                case 4: return "Mi";
                case 5: return "Fa";
                case 6: return "Fa#";
                case 7: return "Sol";
                case 8: return "Sol#";
                case 9: return "La";
                case 10: return "La#";
                case 11: return "Si";
            }

            return "-";
        },
    },
    SarkiZorluk: {
        GetColorAtPoint: (value100Based: number | null) => {
            if (value100Based === null) return appColors.GRAY_LIGHT2;
            // 0 - yeşil (0,255,0)
            // 5 - sarı (255,255,0)
            // 10 - kırmızı (255,0,0)
            const diff1 = value100Based / 50;
            const diff2 = (100 - value100Based) / 50;
            if (value100Based <= 50)
                return `rgb(${(diff1 * 255).toFixed()},255,0)`;
            else
                return `rgb(255, ${(diff2 * 255).toFixed()},0)`;
        },
        GetColorAtPoint10Based: (value10Based: number | null | undefined) => {
            return BusinessHelper.SarkiZorluk.GetColorAtPoint((value10Based === null || value10Based === undefined) ? null : value10Based * 10);
        },
        GrandientColor: "linear-gradient(90deg, rgba(20,255,20,1) 0%, rgba(255,255,20,1) 50%, rgba(255,20,20,1) 100%)",
        Katsayilar: {
            eserUzunluk: 0.20,
            eserDil: 0.10,
            armoni: 0.30,
            melodi: 0.30,
            divizi: 0.10,

            sesSinir: 0.3,
            nuans: 0.35,
            duygu: 0.35,
            nefesTeknik: 0.0,
        },
        DiviziPuan: (divisiCount: number) => {
            if (divisiCount === 0) return undefined;
            switch (divisiCount) {
                case 1: return 0;
                case 2: return 2;
                case 3: return 4;
                case 4: return 5;
                case 5: return 5;
                case 6: return 6;
                case 7: return 7;
                case 8: return 8;
                default: return 9;
            }
        },
        DilPuan: (dil: string | null) => {
            if (StringHelper.isEmpty(dil)) return undefined;

            const lower = dil!.toLocaleLowerCase("tr-tr");
            switch (lower) {
                case "sözsüz":
                case "türkçe": return 1;
                case "ingilizce": return 3;
                case "latince": return 5;
                case "fransızca":
                case "almanca":
                case "gürcüce":
                case "italyanca":
                case "macarca":
                case "sırpça":
                case "isveççe":
                case "ispanyolca": return 7;
                default: return 9;
            }
        },
        UzunlukHesap: (olcuSayisi: string | null) => {
            if (olcuSayisi === null)
                return undefined;

            const myValue = Number.parseInt(olcuSayisi);
            return isNaN(myValue) ? undefined : ((myValue / (myValue + 30) * 9) + 1);
        },
        StatOlustur: (sarki: SarkiModel, zorluk: SarkiZorlukModel | null) => {
            const initialValues = {
                eserUzunluk: !zorluk ? undefined : BusinessHelper.SarkiZorluk.UzunlukHesap(sarki.olcuSayisi),
                eserDil: !zorluk ? undefined : BusinessHelper.SarkiZorluk.DilPuan(sarki.dil),
                divizi: !zorluk ? undefined : BusinessHelper.SarkiZorluk.DiviziPuan(sarki.sesGrupIdList.length),
                armoni: zorluk?.armoniTon ?? undefined,
                melodi: zorluk?.melodi ?? undefined,
                sesSinir: zorluk?.sesSiniri ?? undefined,
                nuans: zorluk?.nuanslar ?? undefined,
                duygu: zorluk?.duygu ?? undefined,
                nefesTeknik: zorluk?.nefesTeknik ?? undefined,
            };

            const kat = BusinessHelper.SarkiZorluk.Katsayilar;

            const ortalamaOgrenme =
                (
                    (initialValues.eserUzunluk ?? 0) * kat.eserUzunluk +
                    (initialValues.eserDil ?? 0) * kat.eserDil +
                    (initialValues.armoni ?? 0) * kat.armoni +
                    (initialValues.melodi ?? 0) * kat.melodi
                ) / (
                    (initialValues.eserUzunluk === undefined ? 0 : kat.eserUzunluk) +
                    (initialValues.eserDil === undefined ? 0 : kat.eserDil) +
                    (initialValues.armoni === undefined ? 0 : kat.armoni) +
                    (initialValues.melodi === undefined ? 0 : kat.melodi)
                );

            const ortalamaSoyleme =
                (
                    (initialValues.sesSinir ?? 0) * kat.sesSinir +
                    (initialValues.nuans ?? 0) * kat.nuans +
                    (initialValues.duygu ?? 0) * kat.duygu +
                    (initialValues.nefesTeknik ?? 0) * kat.nefesTeknik
                ) / (
                    (initialValues.sesSinir === undefined ? 0 : kat.sesSinir) +
                    (initialValues.nuans === undefined ? 0 : kat.nuans) +
                    (initialValues.duygu === undefined ? 0 : kat.duygu) +
                    (initialValues.nefesTeknik === undefined ? 0 : kat.nefesTeknik)
                );
            return {
                ortalamaOgrenme: Number.isNaN(ortalamaOgrenme) ? undefined : ortalamaOgrenme,
                ortalamaSoyleme: Number.isNaN(ortalamaSoyleme) ? undefined : ortalamaSoyleme,
                ...initialValues
            };
        }
    },
    SecmeBasvuru: {
        GetColorAtPoint: (value: number | null) => {
            if (value === null) return appColors.GRAY_LIGHT2;
            if (value <= 5)
                return "rgb(255,0,0)";

            const diff1 = (value - 5) / 2.5; // 5 ise G 0, 7.5 ise G 1
            const diff2 = (10 - value) / 2.5; // 7.5 ise R 1, 10 ise R 0
            if (value <= 7.5)
                return `rgb(255, ${(diff1 * 255).toFixed()},0)`;
            else
                return `rgb(${(diff2 * 255).toFixed()}, 255,0)`;
        },
        SecmePuanGrandientColor: "linear-gradient(90deg, rgba(255,20,20,1) 0%, rgba(255,20,20,1) 50%, rgba(255,255,20,1) 75%, rgba(20,255,20,1) 100%)",
        parseForCoksesliBasvuru: (content: string, sezonBasvurulari: SecmeBasvuruModel[], genelDurumHelper: GenelDurumHelper) => {
            const items = content.split("\n");

            if (items.length < 9)
                return [{ eklenmis: false, hata: "9 satır içeren başvuru kopyalamalısınız (ankaracoksesli basvuruları için)", model: null }];

            const koroTecrube = items.filter((e, i) => i >= 8).join(". ");
            const newRaw = [items[0], items[1], items[2], items[3], items[4], "", "", "", items[7], koroTecrube].join("\t");

            return [BusinessHelper.SecmeBasvuru.createBasvuruFromAnchorusText(newRaw, sezonBasvurulari, genelDurumHelper)]
        },

        parseForAnchorusBasvuru: (content: string, sezonBasvurulari: SecmeBasvuruModel[], genelDurumHelper: GenelDurumHelper) => {
            let newContent = "";
            let withinQuote = false;

            for (let i = 0; i < content.length; i++) {
                if (withinQuote) {
                    if (content[i] === "\"")
                        withinQuote = false;
                    else if (content[i] === "\n")
                        newContent = newContent.concat(" ");
                    else
                        newContent = newContent.concat(content[i])
                }
                else {
                    if (content[i] === "\"") {
                        if (i + 1 < content.length && content[i + 1] === "\"") {
                            newContent = newContent.concat("\"");
                            i++;
                        }
                        else
                            withinQuote = true;
                    }
                    else
                        newContent = newContent.concat(content[i])
                }
            }

            const items = newContent.split("\n");

            return items.select(rawText => BusinessHelper.SecmeBasvuru.createBasvuruFromAnchorusText(rawText, sezonBasvurulari, genelDurumHelper));
        },

        createBasvuruFromAnchorusText: (rawText: string, sezonBasvurulari: SecmeBasvuruModel[], genelDurumHelper: GenelDurumHelper) => {
            const items = rawText.split("\t").select(e => e.trim());

            const adlar = items[0].split(" ").select(e => e.trim());

            if (items.length !== 10)
                return { eklenmis: false, hata: "10 adet kolon kopyalamalısınız (Ad soyad'dan Koro TEcrübesine kadar", model: null }

            const telefon = !items[4].startsWith("0") ? items[4] : items[4].substring(1);

            if (sezonBasvurulari.any(e => e.eposta.toLocaleLowerCase() === items[3].toLocaleLowerCase()))
                return { eklenmis: true, hata: `${items[3]} kullanıcısının başvurusu zaten bulunmaktadır`, model: null }

            if (items[0].trim() === "")
                return { eklenmis: false, hata: "Ad alanı zorunludur", model: null }

            if (adlar.length === 1)
                return { eklenmis: false, hata: "Soyadı alanı zorunludur", model: null }

            if (items[1] !== "Kadın" && items[1] !== "Erkek")
                return { eklenmis: false, hata: "Kopyalanan 2. kolon sadece 'Kadın' veya 'Erkek' olabilir", model: null }

            if (isNaN(Number.parseInt(items[2])))
                return { eklenmis: false, hata: "Kopyalanan 3. kolon doğum yılı, geçerli bir yıl olmalıdır", model: null }

            if (items[3] === "")
                return { eklenmis: false, hata: "Kopyalanan 4. Espota alanı boş olmamalıdır", model: null }


            if (telefon.length !== 10)
                return { eklenmis: false, hata: `${items[3]} kullanıcısının telefon numarası 10 haneli değildir`, model: null }

            const result = new SecmeBasvuruModel();
            result.sezonModelId = genelDurumHelper.AktifSezon.id;

            result.ad = StringHelper.Turkish.ToPascalCase(adlar[0]);
            result.soyad = StringHelper.Turkish.ToPascalCase(adlar.filter((e, i) => i > 0).join(" "));
            result.cinsiyet = items[1] === "Kadın" ? EnmCinsiyet.Kadın : EnmCinsiyet.Erkek;
            result.dogumYili = Number.parseInt(items[2]);
            result.eposta = items[3].toLocaleLowerCase();

            result.telefon = telefon;
            result.meslek = items[5];
            result.egitimDurumu = items[6];
            result.yasadigiIl = items[7];
            result.bilinenSesGrubu = items[8];
            result.koroTecrubesi = items[9];

            result.resimIcerikId = null;
            result.secmeTarihi = null;
            result.sonucModel = null;
            result.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
            result.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
            result.olusturmaTarih = new Date();

            return { eklenmis: false, hata: "", model: result };
        },
    }
}

function addToArrayIfNotNull<T>(array: T[], item: T | null) {
    if (item !== null)
        array.push(item!);
}

