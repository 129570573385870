import CssType from "csstype";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PreviewIcon from '@mui/icons-material/Preview';
import SearchIcon from '@mui/icons-material/Search';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// import { createSvgIcon } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import MailIcon from '@mui/icons-material/Mail';
import PollIcon from '@mui/icons-material/Poll';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import GroupsIcon from '@mui/icons-material/Groups';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import StyleIcon from '@mui/icons-material/Style';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import LinkIcon from '@mui/icons-material/Link';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import TagIcon from '@mui/icons-material/Tag';
import BackspaceIcon from '@mui/icons-material/Backspace';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import RepeatIcon from '@mui/icons-material/Repeat';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SpeedIcon from '@mui/icons-material/Speed';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArticleIcon from '@mui/icons-material/Article';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import HelpIcon from '@mui/icons-material/Help';
import ErrorIcon from '@mui/icons-material/Error';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListIcon from '@mui/icons-material/List';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { createSvgIcon } from "@mui/material";

// import { SvgIconPropsSizeOverrides } from '@mui/material';
// import { OverridableStringUnion } from '@mui/types'

const EquilazerIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        // fill="none"
        // strokeWidth={1.5}
        stroke="currentColor"
    >
        <g>
            <path d="M115,130.5V6.2h-33.3v124.2c-20.8,7-35.8,26.6-35.8,49.7s15,42.8,35.8,49.7v275.7h33.3V229.9c20.8-7,35.8-26.6,35.8-49.7s-15-42.8-35.8-49.7ZM98.3,209.5c-16.2,0-29.3-13.1-29.3-29.3s13.1-29.3,29.3-29.3,29.3,13.1,29.3,29.3-13.1,29.3-29.3,29.3Z" />
            <path d="M273.7,303V6.2h-33.3v296.7c-20.8,7-35.8,26.6-35.8,49.7s15,42.8,35.8,49.7v103.2h33.3v-103.2c20.8-7,35.8-26.6,35.8-49.7s-15-42.8-35.8-49.7ZM257,382c-16.2,0-29.3-13.1-29.3-29.3s13.1-29.3,29.3-29.3,29.3,13.1,29.3,29.3-13.1,29.3-29.3,29.3Z" />
            <path d="M431,202.2V6.2h-33.3v196c-20.8,7-35.8,26.6-35.8,49.7s15,42.8,35.8,49.7v204h33.3v-204c20.8-7,35.8-26.6,35.8-49.7s-15-42.8-35.8-49.7ZM414.3,281.3c-16.2,0-29.3-13.1-29.3-29.3s13.1-29.3,29.3-29.3,29.3,13.1,29.3,29.3-13.1,29.3-29.3,29.3Z" />
        </g>
    </svg>, "Equilazer");

// type FontSizeType = OverridableStringUnion<'inherit' | 'large' | 'medium' | 'small', SvgIconPropsSizeOverrides>;

export const appIcons = {
    ScreenIcons: {
        anasayfa: <HomeIcon />,
        profil: <AccountCircleIcon />,
        takvim: <CalendarMonthIcon />,
        sarkilar: <MusicNoteIcon />,
        logout: <LogoutIcon />,

        yoklamaGirisi: <AccessTimeIcon />,
        kayitSarkiYorum: <MicIcon />,
        kullaniciKayit: <HeadsetMicIcon />,
        kuartet: <InterpreterModeIcon />,

        yoklamaCizelge: <Grid4x4Icon />,
        kayitCizelge: <Grid4x4Icon />,
        kuartetCizelge: <Grid4x4Icon />,
        konserCizelge: <Grid4x4Icon />,

        kayitStat: <BarChartIcon />,
        konserStat: <BarChartIcon />,
        sezonStat: <BarChartIcon />,

        rapor: <ArticleIcon />,
        meta: <ChromeReaderModeIcon />,
        duyuruYonetim: <MailIcon />,
        anketYonetim: <PollIcon />,

        sarkiYonetim: <MusicNoteIcon />,
        calismaYonetim: <CalendarMonthIcon />,
        kullaniciYonetim: <RecentActorsIcon />,

        kadroYonetim: <TagIcon />,
        tarzYonetim: <GroupsIcon />,
        sesGrupYonetim: <HeadphonesIcon />,
        konserYonetim: <TheaterComedyIcon />,

        koroAyarlari: <SettingsIcon />,
        rolYonetim: <StyleIcon />,
        koroYonetim: <AdminPanelSettingsIcon />,
        secmeBasvuru: <ContactMailIcon />,
        secme: <InterpreterModeIcon />,
        secmeSonuc: <ArticleIcon />,
    },
    CrudIcons: {
        kopyala: <ContentCopyIcon />,
        guncelle: <EditIcon />,
        sil: <DeleteIcon />,
        ekle: <AddCircleIcon />,
        ekleToplu: <LibraryAddIcon />,
        onayla: <CheckCircleIcon />,
        onayKaldir: <UnpublishedIcon />,
        arsivle: <ArchiveIcon />,
        ekleSade: <AddIcon />,
        checked: <CheckBoxIcon />,
        unchecked: <CheckBoxOutlineBlankIcon />,
    },
    Zorluk: {
        bilinmiyor_maxi: <HelpIcon style={{ fontSize: "60px" }} color="warning" />,
        uygun_maxi: <CheckCircleIcon style={{ fontSize: "60px" }} color="success" />,
        uygunDegil_maxi: <ErrorIcon style={{ fontSize: "60px" }} color="error" />,
        bilinmiyor_midi: <HelpIcon style={{ fontSize: "48px" }} color="warning" />,
        uygun_midi: <CheckCircleIcon style={{ fontSize: "48px" }} color="success" />,
        uygunDegil_midi: <ErrorIcon style={{ fontSize: "48px" }} color="error" />,
        bilinmiyor_mini: <HelpIcon fontSize="small" color="warning" />,
        uygun_mini: <CheckCircleIcon fontSize="small" color="success" />,
        uygunDegil_mini: <ErrorIcon fontSize="small" color="error" />,
        bilinmiyor: <HelpIcon color="warning" />,
        uygun: <CheckCircleIcon color="success" />,
        uygunDegil: <ErrorIcon color="error" />,
    },
    Popup: {
        tamam: <CheckCircleIcon />,
        iptal: <ArrowBackIcon />,
    },
    Sarki: {
        kayitAc: <MicIcon />,
        kayitKapat: <MicOffIcon />,
        link: <LinkIcon />,
        kayitYap: <MicIcon />,
        oynat: <PlayCircleIcon fontSize="large" />,
        oynat2: (fontsize?: CssType.Property.FontSize<number | string> | undefined) => <PlayCircleIcon sx={{ fontSize: fontsize }} />,
        miniTekNota: <MusicNoteIcon fontSize="small" />,
        equilazerMini: <EquilazerIcon fontSize="small" />,
        notaMini: <MusicNoteIcon fontSize="small" />,
        oynatMini: <PlayCircleIcon fontSize="small" />,
        youtubeMini: <YouTubeIcon color='error' fontSize="small" />,
        youtube: <YouTubeIcon />,
        pdfUltraLarge: <PictureAsPdfIcon sx={{ fontSize: "128px" }} />,
        pdf: <PictureAsPdfIcon />,
    },
    Genel: {
        onizleme: <PreviewIcon />,
        info: <InfoIcon />,
        fileUpload: <FileUploadOutlinedIcon />,
        backspace: <BackspaceIcon />,
        backspaceLight: <BackspaceIcon fontSize="small" color="disabled" />,
        search: <SearchIcon />,
        zoomIn: <ZoomInIcon />,
        zoomOut: <ZoomOutIcon />,
        zoomDefault: <ZoomOutMapIcon />,
        ileri: <ArrowForwardIcon />,
        geri: <ArrowBackIcon />,
        indir: <DownloadIcon />,
        indirOrtaBoy: <DownloadIcon fontSize="medium" style={{ fontSize: "28px" }} />,
        expandMore: <ExpandMoreIcon />,
        expandLess: <ExpandLessIcon />,
        refresh: <RefreshIcon />,
        yukari: <ArrowUpwardIcon fontSize="small" />,
        asagi: <ArrowDownwardIcon fontSize="small" />,
        duyuru: <AnnouncementIcon />,
        duyuruKirmizi: <AnnouncementIcon color="error" />,
        duyuruPrimary: <AnnouncementIcon color="primary" />,
        takvimPrimary: <CalendarMonthIcon color="primary" />,
        temizle: <ClearIcon />,
        katilim: <EventAvailableIcon />,
        secmeNotu: <TheaterComedyIcon />,
        whatsup: <WhatsAppIcon />,
        telefon: <LocalPhoneIcon />,
        soruList: <FormatListNumberedIcon />,
        secenekist: <ListIcon />,
        tasi: <DragHandleIcon />,
        anketBuyuk: <PollIcon fontSize="large" />,
    },
    Mixer: {
        play: <PlayCircleIcon fontSize="large" />,
        pause: <PauseCircleIcon fontSize="large" />,
        forward: <FastForwardIcon fontSize="large" />,
        backward: <FastRewindIcon fontSize="large" />,
        repeatOnLarge: <RepeatIcon color='primary' fontSize="large" />,
        repeatOffLarge: <RepeatIcon color='disabled' fontSize="large" />,
        trimOnLarge: <CompareArrowsIcon color='primary' fontSize="large" />,
        trimOffLarge: <CompareArrowsIcon color='disabled' fontSize="large" />,
        repeatOn: <RepeatIcon color='primary' />,
        repeatOff: <RepeatIcon color='disabled' />,
        trimOn: <CompareArrowsIcon color='primary' />,
        trimOff: <CompareArrowsIcon color='disabled' />,
        speedOn: <SpeedIcon color="primary" />,
        speedOff: <SpeedIcon color="disabled" />,
        speedOnLarge: <SpeedIcon color="primary" fontSize="large" />,
        speedOffLarge: <SpeedIcon color="disabled" fontSize="large" />,
    }

    // tazele: <SyncIcon />,    

    // cikis: <LogoutIcon />,
    // info: <InfoIcon />,
    // ileri: <ArrowForwardIcon />,

    // gitCompare: <BiGitCompare size={24} />,
    // gitBranch: <BiGitBranch size={24} />,
    // gitMerge: <BiGitMerge size={24} />,
    // gitPullRequest: <BiGitPullRequest size={24} />,
    // gitBackMerge: <BiGitCompare size={24} />,
    // gitFork: <BiGitRepoForked size={24} />,
    // gitReset: <BiReset size={24} />,

    //select: <ArrowForwardIcon />,
}